import styled from 'styled-components'

const Content = styled.div`
  /* UI Properties */
  background: var(--basic-white) 0% 0% no-repeat padding-box;
  border: 1px solid #ddd;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  opacity: 1;
  margin-bottom: 60px;
  z-index: 1;
  width: 100%;
`

const ContentCard = styled.div`
 
`
const Card = styled.button`
  max-width: 240px;
  padding: 12px;
  border: 1px solid #c8c8c8;
  border-radius: 12px;
  opacity: 1;
  margin-bottom: 24px;
  background-color: #fff;
  z-index: 1;
  cursor: pointer;

  &:hover {
    border: 1px solid #006fd6;
  }

`
const ContentInfo = styled.div`
  margin-top: 15px;

  @media (max-width: 750px) {
    margin-top: 15px;
    margin-left: 0;
  }
`
const CardImg = styled.div`
  padding: 20px;
  width: 100%;
  min-height: 150px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  opacity: 1;

`
const InfoDesc = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  float: right;
  width: 63px;
  height: 36px;
  /* UI Properties */
  background: #074591 0% 0% no-repeat padding-box;
  border-radius: 0px 12px 0px 0px;
  opacity: 1;
  color: #fff;
`
const Client = styled.div`
  width: 234px;
  height: 17px;
  /* UI Properties */
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #191c1f;
  opacity: 1;

  @media (max-width: 700px) {
    font-size: 12px;
  }
`
const Description = styled.p`
  
  /* UI Properties */
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #006fd6;
  opacity: 1;
  margin-top: 8px;

  overflow: hidden; // Removendo barra de rolagem
  text-overflow: ellipsis; // Adicionando "..." ao final
  display: -webkit-box;
  -webkit-line-clamp: 2; // Quantidade de linhas
  -webkit-box-orient: vertical;

`
const CashBack = styled.div`
  width: 127px;
  height: 10px;
  /* UI Properties */

  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
  @media (max-width: 700px) {
    font-size: 12px;
    width: 90%;
    margin-top: 0;
  }
`
const Pts = styled.div`
  width: 232px;
  max-height: 44px;
  /* UI Properties */
  text-align: left;
  font-size: 36px;
  font-weight: 900;
  letter-spacing: -2.4px;
  color: #404040;
  opacity: 1;
  margin: 9px 0;

`

const Icon = styled.img`
  margin-right: 8px;
`
const ContentPayment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Payment = styled.div`
  text-align: left;
  font-size: 14px;
  letter-spacing: -0.63px;
  color: #404040;
  opacity: 1;
`

const ContentTxt = styled.div`
  height: 22px;
  padding: 24px 0px 40px 10px;

  @media (max-width: 700px) {
    width: 100%;
    margin-bottom: 64px !important;
  }
`
const Text = styled.h2`
  text-align: left;
  font-size: 1.3em;
  letter-spacing: 0px;
  color: #41484f;
  opacity: 1;
`

const ContentBtnMkp = styled.div`
  padding: 20px 0;
`
const BtnMkp = styled.button`
  width: 100%;
  height: 41px;
  background: #006fd6 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #fff;
  border: none;
`

const ContentImg = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
`

const Img = styled.img`
  display: flex;
  min-width: 55%;
  max-width: 56%;
`

export const Styled = {
  Content,
  ContentCard,
  Card,
  CardImg,
  ContentInfo,
  InfoDesc,
  Client,
  Description,
  CashBack,
  Pts,
  Payment,
  ContentTxt,
  Text,
  ContentBtnMkp,
  BtnMkp,
  ContentImg,
  Img,
  Icon,
  ContentPayment,
}
