import React from 'react'

export const Coin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="coin"
          width="48"
          height="48"
          transform="translate(0 -0.018)"
          fill="none"
        />
      </clipPath>
    </defs>
    <g
      id="coin-2"
      data-name="coin"
      transform="translate(0 0.018)"
      clip-path="url(#clip-path)"
    >
      <rect
        id="coin-3"
        data-name="coin"
        width="48"
        height="48"
        transform="translate(0 -0.018)"
        fill="none"
      />
      <g id="Group" transform="translate(0 -0.001)">
        <path
          id="Vector"
          d="M24,0A24,24,0,1,0,48,24,24.071,24.071,0,0,0,24,0Zm0,44.8A20.8,20.8,0,1,1,44.8,24,20.827,20.827,0,0,1,24,44.8Z"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M1.238.34C.972.473.838.606.572.74A1.534,1.534,0,0,0,.438,3.006a1.448,1.448,0,0,0,1.2.533A1.773,1.773,0,0,0,2.7,3.14l.4-.4A1.815,1.815,0,0,0,3.5.473,1.8,1.8,0,0,0,1.238.34Z"
          transform="translate(12.362 9.527)"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M2.557.184A1.554,1.554,0,0,0,.424.85c-.133.267-.267.4-.267.667a1.606,1.606,0,0,0,.8,2.133c0,.133.267.133.533.133A1.809,1.809,0,0,0,2.957,2.85c.133-.133.133-.4.267-.533A1.722,1.722,0,0,0,2.557.184Z"
          transform="translate(8.11 14.883)"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M2.651.385A1.651,1.651,0,0,0,.385.518,1.651,1.651,0,0,0,.518,2.785c.133.133.4.267.533.4a1.44,1.44,0,0,0,.933.4,1.4,1.4,0,0,0,1.2-.667A1.664,1.664,0,0,0,2.918.651C3.051.651,2.785.518,2.651.385Z"
          transform="translate(11.882 34.549)"
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M1.487,3.354h.4c.133,0,.4-.133.533-.133A1.632,1.632,0,0,0,3.754,1.354,1.566,1.566,0,0,0,1.887.02,5.774,5.774,0,0,0,1.22.154,1.647,1.647,0,0,0,.02,2.02,1.454,1.454,0,0,0,1.487,3.354Z"
          transform="translate(18.913 6.646)"
        />
        <path
          id="Vector-6"
          data-name="Vector"
          d="M.367,2.9a1.64,1.64,0,0,0,1.467.933A1.2,1.2,0,0,0,2.5,3.7a1.606,1.606,0,0,0,.8-2.133c-.133-.267-.133-.4-.267-.667A1.733,1.733,0,0,0,.9.1,1.733,1.733,0,0,0,.1,2.233C.233,2.5.233,2.633.367,2.9Z"
          transform="translate(36.833 15.367)"
        />
        <path
          id="Vector-7"
          data-name="Vector"
          d="M3.2,2.133V1.6A1.54,1.54,0,0,0,1.6,0,1.54,1.54,0,0,0,0,1.6v.667a1.54,1.54,0,0,0,1.6,1.6A1.653,1.653,0,0,0,3.2,2.133Z"
          transform="translate(6.4 21.867)"
        />
        <path
          id="Vector-8"
          data-name="Vector"
          d="M3.09.957A1.611,1.611,0,0,0,.157,2.29c.133.133.133.4.267.533a1.64,1.64,0,0,0,1.467.933,1,1,0,0,0,.667-.133,1.733,1.733,0,0,0,.8-2.133C3.224,1.357,3.224,1.09,3.09.957Z"
          transform="translate(7.71 28.776)"
        />
        <path
          id="Vector-9"
          data-name="Vector"
          d="M2.554.154c-.267,0-.4-.133-.667-.133A1.573,1.573,0,0,0,.02,1.22a1.647,1.647,0,0,0,1.2,1.867c.133,0,.4.133.533.133h.4a1.868,1.868,0,0,0,1.6-1.2A1.676,1.676,0,0,0,2.554.154Z"
          transform="translate(25.98 6.78)"
        />
        <path
          id="Vector-10"
          data-name="Vector"
          d="M2.6.2C2.469.2,2.2.069,2.069.069a1.66,1.66,0,0,0-2,1.067,1.66,1.66,0,0,0,1.067,2c.267,0,.4.133.667.133h.4a1.868,1.868,0,0,0,1.6-1.2A1.5,1.5,0,0,0,2.6.2Z"
          transform="translate(18.197 37.797)"
        />
        <path
          id="Vector-11"
          data-name="Vector"
          d="M2.6.329A1.664,1.664,0,0,0,.329.6,1.664,1.664,0,0,0,.6,2.862l.4.4a1.514,1.514,0,0,0,1.067.4,1.448,1.448,0,0,0,1.2-.533A1.651,1.651,0,0,0,3.129.862C3,.6,2.729.462,2.6.329Z"
          transform="translate(32.338 9.938)"
        />
        <path
          id="Vector-12"
          data-name="Vector"
          d="M1.6,0A1.621,1.621,0,0,0,0,1.6v.533a1.513,1.513,0,0,0,1.467,1.6H1.6A1.6,1.6,0,0,0,3.2,2.267v-.8A1.513,1.513,0,0,0,1.6,0Z"
          transform="translate(38.4 22.4)"
        />
        <path
          id="Vector-13"
          data-name="Vector"
          d="M2.584.157A1.606,1.606,0,0,0,.45.957c-.133.133-.133.4-.267.533A1.554,1.554,0,0,0,.85,3.624a1.691,1.691,0,0,0,.8.133,1.842,1.842,0,0,0,1.467-.8c.133-.267.267-.4.267-.667A1.606,1.606,0,0,0,2.584.157Z"
          transform="translate(36.483 29.31)"
        />
        <path
          id="Vector-14"
          data-name="Vector"
          d="M1.084.32l-.4.4a1.668,1.668,0,0,0-.4,2.267,1.641,1.641,0,0,0,1.333.667,2.472,2.472,0,0,0,.933-.267c.133-.133.4-.267.533-.4A1.664,1.664,0,0,0,3.35.72,1.554,1.554,0,0,0,1.084.32Z"
          transform="translate(31.85 34.88)"
        />
        <path
          id="Vector-15"
          data-name="Vector"
          d="M1.887.02c-.133,0-.4.133-.533.133A1.566,1.566,0,0,0,.02,2.02a1.584,1.584,0,0,0,1.6,1.333h.267a5.775,5.775,0,0,0,.667-.133,1.647,1.647,0,0,0,1.2-1.867A1.566,1.566,0,0,0,1.887.02Z"
          transform="translate(25.313 37.98)"
        />
        <path
          id="Vector-16"
          data-name="Vector"
          d="M6,15.2a3.688,3.688,0,0,0,2.8-3.6c0-2.4-1.6-3.2-3.2-4l-.933-.4C3.2,6.533,3.2,6.4,3.2,6.133c0-.533.667-.667,1.2-.667,1.067,0,1.067.533,1.067.667a1.54,1.54,0,0,0,1.6,1.6,1.54,1.54,0,0,0,1.6-1.6A3.884,3.884,0,0,0,6,2.4V1.6A1.54,1.54,0,0,0,4.4,0,1.54,1.54,0,0,0,2.8,1.6v.8A3.73,3.73,0,0,0,0,6.133c0,2.4,1.6,3.2,3.2,4l.933.4C5.6,11.2,5.6,11.333,5.6,11.6c0,.4-.4.667-1.2.667S3.2,12,3.2,11.6A1.54,1.54,0,0,0,1.6,10,1.54,1.54,0,0,0,0,11.6a3.688,3.688,0,0,0,2.8,3.6v1.6a1.54,1.54,0,0,0,1.6,1.6A1.54,1.54,0,0,0,6,16.8Z"
          transform="translate(19.733 15.2)"
        />
      </g>
    </g>
  </svg>
)

export const Credit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49.141"
    height="34.848"
    viewBox="0 0 49.141 34.848"
  >
    <g id="Grupo_127" data-name="Grupo 127" transform="translate(0 -5.576)">
      <g id="Group" transform="translate(0 5.576)">
        <path
          id="Vector"
          d="M40.429,0H8.576A8.54,8.54,0,0,0,0,8.576v17.7a8.54,8.54,0,0,0,8.576,8.576H40.565a8.54,8.54,0,0,0,8.576-8.576V8.576A8.745,8.745,0,0,0,40.429,0ZM8.576,3.267H40.565a5.315,5.315,0,0,1,5.309,5.309v2.859H3.267V8.576A5.315,5.315,0,0,1,8.576,3.267ZM40.429,31.581H8.576a5.315,5.315,0,0,1-5.309-5.309V17.424H45.738v8.848A5.231,5.231,0,0,1,40.429,31.581Z"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M16.607,0H1.633A1.573,1.573,0,0,0,0,1.633,1.573,1.573,0,0,0,1.633,3.267H16.607a1.573,1.573,0,0,0,1.633-1.633A1.655,1.655,0,0,0,16.607,0Z"
          transform="translate(6.942 22.597)"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M8.848,0H1.633A1.573,1.573,0,0,0,0,1.633,1.573,1.573,0,0,0,1.633,3.267H8.848a1.573,1.573,0,0,0,1.633-1.633A1.573,1.573,0,0,0,8.848,0Z"
          transform="translate(28.586 22.597)"
        />
      </g>
    </g>
  </svg>
)

export const CashBack = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="coin"
          width="48"
          height="48"
          transform="translate(0 -0.018)"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Grupo_669" data-name="Grupo 669" transform="translate(-952 -1895)">
      <g
        id="coin-2"
        data-name="coin"
        transform="translate(952 1895.018)"
        clip-path="url(#clip-path)"
      >
        <rect
          id="coin-3"
          data-name="coin"
          width="48"
          height="48"
          transform="translate(0 -0.018)"
          fill="none"
        />
        <g id="Group" transform="translate(0 -0.001)">
          <path
            id="Vector"
            d="M24,0A24,24,0,1,0,48,24,24.071,24.071,0,0,0,24,0Zm0,44.8A20.8,20.8,0,1,1,44.8,24,20.827,20.827,0,0,1,24,44.8Z"
          />
        </g>
      </g>
      <path
        id="Caminho_632"
        data-name="Caminho 632"
        d="M333.368,892.3a3.017,3.017,0,0,0-2.5-.954l-6.342.281,3.4-5.36v-.056a3.685,3.685,0,0,0,.505-1.74,2.955,2.955,0,0,0-1.038-2.3,3.361,3.361,0,0,0-2.329-.926,2.877,2.877,0,0,0-2.722,1.8l-2.862,5.7-2.918-5.7a2.972,2.972,0,0,0-2.778-1.8,3.451,3.451,0,0,0-2.3.87,2.915,2.915,0,0,0-1.038,2.3,3.161,3.161,0,0,0,.589,1.852l3.4,5.36-6.342-.281a2.8,2.8,0,0,0-2.5.954,3.425,3.425,0,0,0-.842,2.3,3.546,3.546,0,0,0,.814,2.3,2.753,2.753,0,0,0,2.245.954h.253l6.342-.281-3.4,5.416a3.224,3.224,0,0,0-.617,1.8,2.883,2.883,0,0,0,1.094,2.3,3.633,3.633,0,0,0,2.329.87,2.868,2.868,0,0,0,2.722-1.712l2.946-5.7,2.862,5.612a2.9,2.9,0,0,0,2.722,1.8,3.361,3.361,0,0,0,2.329-.926,3.108,3.108,0,0,0,1.038-2.329,3.477,3.477,0,0,0-.533-1.8l-3.4-5.36,6.342.281h.253a2.849,2.849,0,0,0,2.273-.954,3.478,3.478,0,0,0,0-4.6m-13.862,5.865-3.059-1.768V892.8l3.059-1.712,3,1.712v3.564l-3,1.768Zm4.125-14.479a1.532,1.532,0,0,1,1.459-1.01,1.841,1.841,0,0,1,1.375.561,1.692,1.692,0,0,1,.589,1.291,2.316,2.316,0,0,1-.337,1.066l-3.732,5.893-2.554-1.459,3.2-6.314Zm-11.786.758a1.515,1.515,0,0,1,.561-1.235,1.974,1.974,0,0,1,1.375-.533,1.613,1.613,0,0,1,1.543,1.01l3.255,6.314-2.61,1.459-3.788-5.949a1.831,1.831,0,0,1-.365-1.094m-3.788,12.066a1.554,1.554,0,0,1-1.347-.449,2.007,2.007,0,0,1-.477-1.375,2.03,2.03,0,0,1,.505-1.375,1.479,1.479,0,0,1,1.179-.477h.168l7.015.337v3.031l-7.015.309Zm7.3,9.148a1.459,1.459,0,0,1-1.459.926,2.111,2.111,0,0,1-1.431-.533,1.541,1.541,0,0,1-.589-1.235,1.719,1.719,0,0,1,.365-1.01l3.76-5.977,2.61,1.515-3.255,6.342Zm11.729-.9a1.692,1.692,0,0,1-.589,1.291,1.954,1.954,0,0,1-1.375.561,1.507,1.507,0,0,1-1.459-1.01l-3.2-6.258,2.554-1.515,3.732,5.893a1.943,1.943,0,0,1,.309,1.066M332.3,896a1.718,1.718,0,0,1-1.4.477l-7.015-.309v-3.031l7.015-.337a1.753,1.753,0,0,1,1.4.477,2.124,2.124,0,0,1,0,2.75"
        transform="translate(656.754 1024.26)"
        stroke="#000"
        stroke-width="1"
      />
    </g>
  </svg>
)

export const Boy = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="boy"
          width="24"
          height="24"
          fill="none"
        />
      </clipPath>
    </defs>
    <g
      id="boy-2"
      data-name="boy"
      clip-path="url(#clip-path)"
    >
      <rect
        id="boy-3"
        data-name="boy"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="Vector"
        d="M15.933,17.391h-1L14,15.858a7.1,7.1,0,0,0,2.933-4.667,1.612,1.612,0,0,0,.8-1.6V7.658a1.9,1.9,0,0,0-.8-1.4V5.125a2.854,2.854,0,0,0-.267-3.8C15.2-.209,12.133-.609,9.533,1.191c-3.4.067-4.933,1.2-4.933,3.667V6.391a1.856,1.856,0,0,0-.8,1.4V9.725a1.768,1.768,0,0,0,.533,1.467,7.378,7.378,0,0,0,2.933,4.8l-1,1.467H5.333C2.067,17.458,0,19.258,0,22.191v1.067a.8.8,0,0,0,.8.733.811.811,0,0,0,.8-.8V22.125c0-2.6,2-3.133,3.733-3.133H6.8a.821.821,0,0,0,.667-.333L8.8,16.791a4.706,4.706,0,0,0,1.867.4,5.8,5.8,0,0,0,2.067-.467l1.2,1.933a.733.733,0,0,0,.667.4h1.467c.933,0,4,.2,4,3.133v1.067a.8.8,0,0,0,1.6,0V22.191C21.6,19.258,19.4,17.391,15.933,17.391ZM6,10.591a.78.78,0,0,0-.533-.733V7.791a1.052,1.052,0,0,1,.4-.333.733.733,0,0,0,.4-.667V4.858c0-.933,0-2.067,3.6-2.067a.964.964,0,0,0,.467-.133,4.172,4.172,0,0,1,5.267-.2,1.368,1.368,0,0,1,0,1.933.605.605,0,0,0-.2.467V6.791a.733.733,0,0,0,.4.667,1.824,1.824,0,0,1,.333.267V9.791a.852.852,0,0,0-.733.8c0,2.2-2.667,4.933-4.8,4.933C8.467,15.591,6,12.858,6,10.591Z"
        transform="translate(1.2 0.009)"
        fill="#fff"
      />
    </g>
  </svg>
)
