import React, { useState, useEffect, useRef } from 'react'
import axios from 'shared/http'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setModalShowRedux } from 'store/modal/actions'

import { Styled } from './LoginBox.styled'
import { EyeIcon, LoadIcon } from 'components/Icons/Icons'
import QueroMeCadastrar from './QueroMeCadastrar'
import FooterLogin from './FooterLogin'
import pushDataLayerGTM from 'util/pushDataLayerGTM'
import Recaptcha from 'react-recaptcha'

const Conteudo = ({
  showModal,
  redirectoCatalog,
  target,
  errorValidationMessage,
  getUrl,
}) => {
  const [state, setState] = useState({
    login: '',
    senha: '',
    msg: '',
    msgType: '',
    inputType: 'password',
    loadingLogin: false,
    ip: '',
  })
  const [isBlocked, setIsBlocked] = useState(false)
  const [recaptchaIsValid, setRecaptchaIsValid] = useState(true)
  const [documentsFill, setDocumentsFill] = useState(true)
  const captchaRef = useRef(null)

  useEffect(() => {
    verifyFillDocuments()
    if (!state.ip) {
      getUserIp()
    }

    if (errorValidationMessage) {
      const erro = errorValidationMessage
      setState({
        ...state,
        loadingLogin: false,
        msg: `Código: ${erro}`,
        msgType: 'erro',
      })
    }
  }, [])

  useEffect(() => {
    verifyFillDocuments()
  }, [state.login, state.senha, recaptchaIsValid])

  function getUserIp() {
    fetch('https://api.ipify.org?format=json')
      .then((response) => {
        return response.json()
      })
      .then((res) => {
        setState({
          ...state,
          ip: res.ip,
        })
      })
      .catch((err) => console.error('Erro ao recuperar o IP', err))
  }

  function cleanupSession() {
    sessionStorage.removeItem('auth-token')
    localStorage.removeItem('auth-token')
    localStorage.removeItem('_grecaptcha')
  }

  function envia(event) {
    event.preventDefault()
    setState({
      ...state,
      loadingLogin: true,
      msg: '',
      msgType: '',
    })
    if (state.login && state.senha && recaptchaIsValid) {
      let documentNumber = state.login.replace(/[^\d]+/g, '')
      let externalId = localStorage.getItem(JSON.stringify(documentNumber))
      const data = {
        documentNumber,
        password: state.senha,
        externalId: parseInt(externalId),
        payload: document.getElementById('user_prefs').value, //value of user_prefs
        hdm: document.getElementById('user_prefs2').value, //value of user_prefs2
      }

      const options = {
        headers: { 'X-Forwarded-For': state.ip },
      }

      let url = '/authentication/v1/login'
      axios
        .post(url, data, options)
        .then((response) => {
          const category = 'login'
          const action = 'callback:login'
          const label = 'sucesso'
          pushDataLayerGTM(category, action, label)

          cleanupSession()
          sessionStorage.setItem('auth-token', JSON.stringify(response))
          localStorage.setItem('auth-token', JSON.stringify(response))
          localStorage.setItem('auth', JSON.stringify(response.externalId))

          if (response.isTermAccept) {
            setState({
              ...state,
              loadingLogin: false,
              msg: '',
              msgType: '',
            })
            if (
              window.location.pathname.indexOf('/detalhe-da-oferta') == 0 ||
              window.location.pathname.indexOf('/localiza') == 0
            ) {
              showModal({
                show: false,
                content: <div></div>,
                title: null,
                width: '1000px',
              })
              window.location.reload()
            } else {
              checkRedirectToCatalog('_blank')
            }
          } else {
            window.location = '/onboarding'
          }
        })
        .catch((error) => {
          let erro = ''
          if (error?.errors?.length > 0) {
            if (error?.errors[0].origin == 'BCP') {
              erro = `Não foi possivel realizar o Login. Código ${error.errors[0].message}.`
            } else if (
              error?.errors[0].origin == 'Login' &&
              error?.errors[0].message == 'Erro ao realizar o login'
            ) {
              erro = `Bloqueamos sua conta temporariamente por motivos de segurança.`
            } else if (
              error?.errors[0].origin == 'Login' &&
              error?.errors[0].message != 'Login ou senha inválidos'
            ) {
              erro = `Usuário ou senha inválido. Para receber uma nova senha acesse a opção 'esqueci minha senha'.`
            } else {
              erro = `Usuário ou senha inválido. Para receber uma nova senha acesse a opção 'esqueci minha senha'.`
            }
          } else if (error?.errors?.DocumentNumber.length) {
            erro = `${error.errors.DocumentNumber[0]}`
          } else {
            erro = `Tivemos problemas em processar a sua solictação. Tente novamente em instantes. Se preferir, entre em contato pelo`
          }

          const category = 'login'
          const action = 'callback:login'
          const label = `erro:${replaceSpecialChars(erro).toLowerCase()}`
          pushDataLayerGTM(category, action, label)
          setState({
            ...state,
            loadingLogin: false,
            msg: erro,
            msgType: 'erro',
          })
        })
    } else {
      let mensagem = ''
      if (state.login == '') {
        mensagem = 'Informe um CPF ou CNPJ.'
      } else if (state.senha == '') {
        mensagem = 'Informe sua senha.'
      } else if (!recaptchaIsValid) {
        mensagem = ' Por favor, validar o Recaptcha.'
      }

      const category = 'login'
      const action = 'callback:login'
      const label = `erro:${replaceSpecialChars(mensagem).toLowerCase()}`
      pushDataLayerGTM(category, action, label)

      setState({
        ...state,
        loadingLogin: false,
        msg: mensagem,
        msgType: 'erro',
      })
    }
  }

  function onRecaptchaExpired() {
    setRecaptchaIsValid(false)
    captchaRef.current.reset()
    cleanupSession()
  }

  function checkRedirectToCatalog() {
    if (redirectoCatalog) {
      const isOpen = window.open(redirectoCatalog, '_blank')
      if (!isOpen) {
        showModal({
          show: true,
          showCloseButton: false,
          content: (
            <Styled.Wrap>
              <Styled.Form style={{ paddingBottom: '0.025rem' }}>
                <Styled.FormGroup>
                  {
                    <Styled.Button
                      type="button"
                      value=""
                      disabled={false}
                      onClick={() => {
                        setState({ ...state, loadingLogin: true })
                        window.location = '/home'
                      }}
                    >
                      Ir para página inicial
                      <Styled.Load active={state.loadingLogin}>
                        <LoadIcon />
                      </Styled.Load>
                    </Styled.Button>
                  }
                </Styled.FormGroup>
              </Styled.Form>
              <Styled.Footer></Styled.Footer>
            </Styled.Wrap>
          ),
          title:
            'Por favor, desabilite o boqueio de Pop-up para ser redirecionado',
          width: '754px',
        })
      } else window.location.reload()
    } else if (getUrl) {
      if (typeof getUrl === 'number') {
        const url = `/catalog/v1/vendor/${getUrl}`
        axios
          .get(url)
          .then((response) => {
            window.open(response.uri, '_blank')
            window.location.reload()

          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        axios
          .get(getUrl)
          .then((response) => {
            window.open(response.uri, '_blank')
            window.location.reload()
          })
          .catch((error) => {
            console.log(error)
            showModal({
              show: false,
              showCloseButton: false,
            })
            window.location.reload()
          })
      }
    } else {
      window.location = '/home'
    }
  }

  function replaceSpecialChars(str) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove acentos
      .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
      .replace(/\-\-+/g, '-') // Substitui multiplos hífens por um único hífen
      .replace(/(^-+|-+$)/, '') // Remove hífens extras do final ou do inicio da string
  }

  function togglePasswordView() {
    setState({
      ...state,
      inputType: state.inputType === 'password' ? 'text' : 'password',
    })
  }

  function verifyCallback(response) {
    if (response) {
      setRecaptchaIsValid(true)
      verifyFillDocuments()
    }
  }
  function onChangeRecaptcha() {
    verifyFillDocuments()
  }

  function onRecaptchaExpired() {
    setRecaptchaIsValid(false)
    captchaRef.current.reset()
    cleanupSession()
  }
  function verifyFillDocuments() {
    if (state.login && state.senha && recaptchaIsValid) {
      setDocumentsFill(false)
    }
  }
  function onChagePassword(event) {
    setState({ ...state, senha: event.target.value })
    verifyFillDocuments()
  }

  function handle(event) {
    let entrada = event.target.value.replace(/[^\d]+/g, '')
    let cpf = ''
    let cnpj = ''

    if (entrada.toString().length === 11) {
      cpf = entrada.replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})/, '$1.$2.$3-$4')
    }
    if (entrada.toString().length === 14) {
      cnpj = entrada.replace(
        /(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})/,
        '$1.$2.$3/$4-$5'
      )
    }

    const login = cpf ? cpf : cnpj ? cnpj : entrada
    setState({ ...state, login })
    verifyFillDocuments()
  }

  return (
    <Styled.Wrap>
      <Styled.Form onSubmit={envia.bind(this)} autocomplete="current-password">
        <Styled.FormGroup>
          <Styled.Label>CPF / CNPJ</Styled.Label>
          <Styled.Input
            type="text"
            maxLength="18"
            placeholder="Login"
            name="login"
            autoComplete="off"
            value={state.login}
            onBlur={verifyFillDocuments}
            onChange={(event) => {
              handle(event)
            }}
          />
        </Styled.FormGroup>

        <Styled.FormGroup>
          <Styled.InputPassword
            type={state.inputType}
            placeholder="Digite sua senha:"
            name="senha"
            value={state.senha}
            onChange={(event) => {
              onChagePassword(event)
            }}
          />
          <Styled.ShowPassword onClick={togglePasswordView}>
            <EyeIcon />
          </Styled.ShowPassword>

          <Link
            to={{ pathname: 'https://portoseguro.com.br/pdc/login' }}
            target="_blank"
          >
            <label
              style={{
                color: 'white',
                fontSize: '10px',
                textDecoration: 'underline',
              }}
            >
              Esqueci a senha
            </label>
          </Link>
        </Styled.FormGroup>

        <Styled.FormGroup hidden>
          <Styled.Input
            type="text"
            id="user_prefs"
            placeholder="user_prefs"
            name="payloadArea"
            readonly
          />
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.WrapRecaptcha styles>
            {
              <Recaptcha
                expiredCallback={onRecaptchaExpired}
                ref={captchaRef}
                sitekey={process.env.REACT_APP_SITE_KEY}
                verifyCallback={verifyCallback}
                onChange={onChangeRecaptcha}
                onloadCallback={() => console.log('ready')}
                hl={'pt-BR'}
                onErrored={(e) => console.log('error ====> ', e)}
                size="normal"
              />
            }
          </Styled.WrapRecaptcha>
        </Styled.FormGroup>
        <Styled.FormGroup>
          {
            <Styled.Button
              type="submit"
              value="login"
              disabled={documentsFill}
              onClick={() => window.fielddesc.collect('user_prefs')}
            >
              Fazer Login
              <Styled.Load active={state.loadingLogin}>
                <LoadIcon />
              </Styled.Load>
            </Styled.Button>
          }
        </Styled.FormGroup>

        <Styled.Msg active={state.msg !== ''} type={state.msgType}>
          {state.msg}
          <div className="row">
            <label>Se preferir entre em contato pelo nosso</label>
            <Link
              to={{ pathname: 'http://porto.vc/chatcadastro' }}
              target="_blank"
            >
              <label
                style={{
                  color: 'white',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  marginLeft: '5px',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                chat.
              </label>
            </Link>
          </div>
          <div class="row">
            {isBlocked ? (
              <label>Se preferir entre em contato pelo nosso chat </label>
            ) : (
              <></>
            )}
            {isBlocked ? (
              <Link
                style={{ textDecoration: 'underline' }}
                to={{
                  pathname:
                    'https://prime.altubots.com/chats/portofalecom/d68d3e4bae53e4001eed6a34bae171e4/index.html',
                }}
                target="_blank"
              >
                <label
                  style={{
                    color: 'white',
                    fontSize: '15px',
                    textDecoration: 'underline',
                  }}
                >
                  clicando aqui
                </label>
              </Link>
            ) : (
              ''
            )}
            {isBlocked ? <label>.</label> : <></>}
          </div>
        </Styled.Msg>

        <Styled.TextSignUp>
          Se você é Corretor, faça seu acesso diretamente pelo COL.
          <div style={{ marginTop: '2px' }}>
            O PortoPlus usa as MESMAS CREDENCIAIS DE ACESSO DA ÁREA DO CLIENTE.
            Se você já tem cadastro, basta usar a mesma senha. Se ainda não tem,
            faça seu cadastro <QueroMeCadastrar />.
          </div>
          <div style={{ marginTop: '2px' }}>
            IMPORTANTE: NÃO USE A SENHA ANTIGA DO CLUBE PORTO.
          </div>
        </Styled.TextSignUp>
      </Styled.Form>

      <FooterLogin />
    </Styled.Wrap>
  )
}

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(Conteudo)
