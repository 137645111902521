import React from 'react'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import verifyIsLoginActive from 'util/verifyIsLoginActive'
import { setModalShowRedux } from 'store/modal/actions'
import { Styled } from './OfferCardCashBack.styled'
import LinkToShopping from 'components/LinkToShopping'
import openExternalLink from 'util/LinkToShowCase'
import pushDataLayerGTM from 'util/pushDataLayerGTM'
import LoginBox from 'containers/LoginBox/LoginBox'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const OfferCardCashBack = ({ showModal, list, ...props }) => {
  return (
    <>
      <LinkToShopping source={'card'}>
        <Styled.Wrap>
          <Styled.Image>
            <div>
              <img src={list.logoUri} alt="" />
            </div>
          </Styled.Image>
          <label>Até {list.cashback}% de volta</label>
        </Styled.Wrap>
      </LinkToShopping>
    </>
  )
}

const OfferCard = ({ showModal, list, ...props }) => {
  history =  useHistory()
  const openModal = () =>{
    history.push('/Login')
    // showModal({
    //   show: true,
    //   content: <LoginBox />,
    //   title: 'Preencha o CPF/CNPJ, a senha e faça a validação de segurança para realizar o login.',
    //   width: '554px',
    // })

  }

  const sendEventGTM = (valueEvent) => {
    let category = 'home'
    let action = 'clique:resgatar-meus-pontos'
    let label = valueEvent
    pushDataLayerGTM(category, action, label)
  }

  function checkUrl() {
    let isLoginActive = verifyIsLoginActive()
    if (typeof isLoginActive == 'undefined') {
      sendEventGTM(`login`)
      openModal()
    } else {
      if (Boolean(isLoginActive)) {
        const autoRedirect = localStorage.getItem('auto-redirect')
        if (autoRedirect) {
          const urlRedirect = Buffer.from(autoRedirect, 'base64').toString('utf8')
          const estado = localStorage.getItem('estado')
          if (estado != 'aberto')
            openExternalLink(urlRedirect)
        }

      } else {
        sendEventGTM(`login`)
        openModal()
      }
    }
  }

  return (

    <div>

      <Link onClick={checkUrl}>

        <OfferCardCashBack list={list} />

      </Link>

    </div>

  )

}



const dispatch = (dispatch) =>

  bindActionCreators({ showModal: setModalShowRedux }, dispatch)



export default connect(null, dispatch)(OfferCard)
