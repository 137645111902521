import { useState, useRef, useEffect } from 'react'
import TabContent from './tabContent'
import { Styled } from './Conteudo.styled'
import axios from 'shared/http'
import { LoadIcon } from 'components/Icons/Icons'
import {
  HouseWindow,
  ShoppingBag,
  Smartphone,
  Culture,
  Gastronomy,
  Plane,
  WeightGym,
  CarDoorWindow,
  Time,
  Star,
  Carnew,
  BlackFriday,
  PortoOfertas,
} from 'components/Icons/Icons'
import verifyIsLoginActive from 'util/verifyIsLoginActive'
import pushDataLayerGTM from 'util/pushDataLayerGTM'
import VitrinePortoPlus from './VitrinePortoPlus/VitrinePortoPlus'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const useMountEffect = (fun) => useEffect(fun, [])

const Conteudo = () => {
  const [active, setActive] = useState(0)
  const [campaigns, setCampaigns] = useState({})

  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()
  useMountEffect(executeScroll)

  const sendEventGTM = (valueEvent) => {
    let category = 'home'
    let action = 'clique:menu-opcoes'
    let label = `${valueEvent}:todos`
    pushDataLayerGTM(category, action, label)
  }

  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0)
    if (index !== active) {
      sendEventGTM(e.target.name)
      setActive(index)
    }
  }
  useEffect(() => {
    getCampaigns()
  }, [])

  const [state, setState] = useState({
    listCategory: [],
    msg: '',
    msgType: '',
    loadingVitrine: false,
  })

  function getCampaigns() {
    const usuario = verifyIsLoginActive()
    if (Boolean(usuario)) {
      setState({
        ...state,
        loadingVitrine: true,
      })

      axios
        .get('/participants/v1/campaigns')
        .then((response) => {
          setCampaigns(response)
          getCategory(response.length > 0)
        })
        .catch((error) => {
          console.log('Erro: ', error)
          getCategory()
        })
    } else {
      getCategory()
    }
  }
  function getCategory(hasCampaigns) {
    setState({
      ...state,
      loadingVitrine: true,
    })

    axios
      .get('api/Lists/subcategory')
      .then((response) => {
        let home = {
          id: '',
          name: 'Home',
          subCategory: [],
        }
        let newListCategory = []
        response.map((item) => {
          if (item.id == 13) {
            home.id = item.id
            home.name = 'Home'
            home.subCategory = item.subCategory

            newListCategory.unshift(home)
          } else {
            newListCategory.push(item)
          }
        })

        if (hasCampaigns) {
          let campanhas = {
            id: 99,
            name: 'Especial só para mim',
            subCategory: [],
          }
          newListCategory.unshift(campanhas)
        }

        if (
          window.location.pathname.indexOf(
            '/comprar-com-desconto/categoria-'
          ) >= 0
        ) {
          let category = window.location.pathname.replace(
            '/comprar-com-desconto/categoria-',
            ''
          )
          let getCategoryIndice = newListCategory.filter((item, i) => {
            item.indice = i
            return item.id == category
          })
          if (getCategoryIndice.length > 0) {
            setActive(getCategoryIndice[0].indice)
            executeScroll()
          }
        }

        setState({
          ...state,
          listCategory: newListCategory,
          loadingVitrine: false,
        })
      })
      .catch((error) => {
        console.log('Erro: ', error)
        setState({
          ...state,
          listCategory: [],
          msg: 'Erro ao recuperar as Categorias',
          msgType: 'erro',
          loadingVitrine: false,
        })
      })
  }

  function renderSwitch(param, isActive) {
    switch (param) {
      case 99:
        // Campanhas
        return <Star color={isActive ? '#192534' : '#192534'} />
      case 13:
        // Home
        return <HouseWindow color={isActive ? '#192534' : '#192534'} />
      case 1:
        // Compras
        return <ShoppingBag color={isActive ? '#192534' : '#192534'} />
      case 2:
        // Conveniência
        return <Smartphone color={isActive ? '#192534' : '#192534'} />
      case 3:
        // Cultura e Educação
        return <Culture color={isActive ? '#192534' : '#192534'} />
      case 4:
        // Gastronomia
        return <Gastronomy color={isActive ? '#192534' : '#192534'} />
      case 5:
        // Lazer e Turismo
        return <Plane color={isActive ? '##192534' : '#192534'} />
      case 6:
        // Saúde e Bem estar
        return <WeightGym color={isActive ? '#192534' : '#192534'} />
      case 7:
        // Serviços Automotivos
        return <CarDoorWindow color={isActive ? '#192534' : '#192534'} />
      case 8:
        // Oferta Relâmpago
        return <Time color={isActive ? '#192534' : '#192534'} />
      case 9:
        // Só pra você
        return <Carnew color={isActive ? '#192534' : '#192534'} />
      case 14:
        // Black Friday
        return <BlackFriday color={isActive ? '#192534' : '#192534'} />
      case 16:
        // Black Friday
        return <PortoOfertas color={isActive ? '#192534' : '#192534'} />
      default:
        return ''
    }
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Styled.Wrap ref={myRef}>
              <Styled.Load active={state.loadingVitrine}>
                <LoadIcon />
              </Styled.Load>

              <Styled.Msg active={state.msg !== ''} type={state.msgType}>
                {state.msg}
              </Styled.Msg>

              {!state.loadingVitrine && (
                <Styled.ContentGroup>
                  <Styled.InfoTxt>
                    <strong>
                      Quem é Porto, economiza e tem vantagens exclusivas no dia
                      a dia.
                    </strong>
                  </Styled.InfoTxt>

                  <Styled.WrapVitrine>
                    <Styled.Tabs>
                      {state.listCategory.map((item, i) => (
                        <Styled.TabCategoryContent>
                          <Styled.Tab
                            onClick={handleClick}
                            active={active === i}
                            id={i}
                            name={item.name}
                          >
                            <Styled.TabContentIcon>
                              {renderSwitch(item.id, active === i)}
                            </Styled.TabContentIcon>
                            {item.name}
                          </Styled.Tab>
                        </Styled.TabCategoryContent>
                      ))}
                    </Styled.Tabs>
                  </Styled.WrapVitrine>
                  <>
                    {state.listCategory.map((item, i) => (
                      <Styled.Content active={active === i}>
                        <TabContent content={item} campaigns={campaigns} />
                      </Styled.Content>
                    ))}
                  </>
                </Styled.ContentGroup>
              )}
            </Styled.Wrap>
            <Styled.VitrinePortoPlus>
              <VitrinePortoPlus />
            </Styled.VitrinePortoPlus>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Conteudo
