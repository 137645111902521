import styled from 'styled-components'
import { respondToMin } from 'shared/theme'
import { respondTo } from 'shared/theme'
import { Link } from 'react-router-dom'

const Wrap = styled.div`
  padding: 15px 0;
  position: relative;
  z-index:2;
  background-color: #FFF;

  ${respondToMin.desktopSmall`
    padding: 0;
  `}

  ${respondTo.mobile`
    padding: 15px 20px;
  `}
`

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MobileMenuButton = styled.div`
  display: block;
  margin-right: 3%;

  ${respondToMin.desktopSmall`
    display: none;
  `}
`

const Logo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 3px 0;
  ${respondToMin.mobile`
    margin-right: 20px;
  `}
`

const NavItems = styled.div`
  display: none;
  padding: 28px 0;

  ${respondToMin.desktopSmall`
    margin-right: 0px;
    display: block;
  `}
`

const Search = styled.div`
  margin-right: 32px;
`

const Profile = styled.div`
  align-items: center;
  display: flex;

  ${({ status }) =>
    status &&
    `
      div {
        display: none;
      }
  `}

  ${respondToMin.desktopSmall`
    ${({ status }) =>
      status &&
      `
      div {
        display: flex;
      }
    `}
  `}
`

const LogoWrap = styled.div`
  
`

const ProfileMobile = styled.div`
  ${respondToMin.desktopSmall`
    display: none;
    margin: 0px;
  `}
`
const MenuNav = styled.div`
  margin-left: auto;
  margin-right: auto;
  height: 55px;
  /* UI Properties */
  background: #f6f6f6 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: row;
  padding-left: 10px;

  @media (max-width: 1500px) {
    max-width: 100%;
  }
  @media (max-width: 600px) {
    display: none;
    max-width: 1200px;
  }
`

const ButtonLink = styled.button`
  padding: 16px 12px;
  border: none;
  background: none;
  text-align: left;
  color: #191c1f;
  font-size: 16px;
  cursor: pointer;

  &:hover{
    color: #009AF8;
  }

  &.active {
    padding: 12px 12px;
    display: flex;
    align-items: center;
    margin-top: 14px;
    border: 2px solid #2C70CF;
    border-radius:20px;
    height: 10px;
    color: #000;
    background-color: #fff;
    strong {
      margin-left: 5px !important;
      font-size: 18px;
    }
  }
`
export const Styled = {
  Wrap,
  Nav,
  NavItems,
  Logo,
  Search,
  Profile,
  ProfileMobile,
  MobileMenuButton,
  LogoWrap,
  MenuNav,
  ButtonLink,
}
