import React from 'react'
import { Styled } from './LoginBox.styled'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import { connect } from 'react-redux'
import LoginBox from 'containers/LoginBox/LoginBox'
import { LogoIcon } from 'components/Icons/Icons'
import pushDataLayerGTM from 'util/pushDataLayerGTM'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const QueroMeCadastrar = ({ showModal }) => {
    history = useHistory()
  const RenderSignUp = () => (
    <Styled.ModalWrap>
      <Styled.TitleIframe>
        <LogoIcon />
        <Styled.LinkVoltar onClick={handleShowLoginForm}>
          <span></span>
        </Styled.LinkVoltar>
      </Styled.TitleIframe>
      <Styled.BodyIframe style={{ background: '#fff' }}>
        <iframe
          title="Esqueci a senha"
          src="https://cliente.portoseguro.com.br/portaldecliente/CriarContaPorto"
          style={{
            width: '100%',
            height: '730px',
            marginTop: '-140px',
            overflow: 'hidden',
          }}
        />
      </Styled.BodyIframe>
    </Styled.ModalWrap>
  )

  const handleForgotPassword = () => {
    let category = 'login'
    let action = 'clique:link'
    let label = 'quero-me-cadastrar'
    pushDataLayerGTM(category, action, label)

    const isOpen = window.open(
      'https://portoseguro.com.br/pdc/cadastro',
      '_blank'
    )
  }

  const handleShowLoginForm = () =>{
    history.push('/Login' ,{url: url})
    // showModal({
    //   show: true,
    //   content: <LoginBox />,
    //   title:
    //     'Preencha o CPF/CNPJ, a senha e faça a validação de segurança para realizar o login.',
    //   width: '554px',
    // })
  }

  return (
    <Styled.LinkForgot onClick={handleForgotPassword}>
      clicando aqui
    </Styled.LinkForgot>
  )
}

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(QueroMeCadastrar)
