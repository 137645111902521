import styled from 'styled-components'
import { respondToMin } from 'shared/theme'
import { UI } from 'components/UI/UI.styled'

const Container = styled(UI.Container)`
  background: #dbf1ff;
  margin-top:40px;
  margin-bottom:90px;

  @media (max-width: 750px) {
    width:100%;
  }
`

const CarouselWrap = styled.div`
`

const ItemWrap = styled.div`
  width: 165px;
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d1dbdd;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  background: #fff;

  img {
    width: 60%;
  }
`
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;
`

const Title = styled(UI.h2)`
  margin-top: 38px;
  margin-bottom: 15px;
  text-align: left;
  font-size: 26px;
  letter-spacing: 0px;
  color: #41484f;
  opacity: 1;
  font-weight: bold;

  @media (max-width: 750px) {
    margin-top: 8px;
    margin-bottom: 15px;
    margin-left: 10px;
  }
`

const DeskLink = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme: { colors } }) => colors.primary};
  display: none;
  align-items: center;

  span {
    padding-right: 8px;
  }

  ${respondToMin.tablet`
    ${({ showLink }) =>
      showLink &&
      `
        cursor: pointer;
        display: flex;
    `}
  `}
`

const Load = styled.div`
  display: none;
  padding-left: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  svg {
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(360deg);
      }
      100% {
        -webkit-transform: rotate(0deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(360deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }

  ${({ active }) =>
    active &&
    `
    display: flex;
  `}
`

const MsgPartners = styled.div`
  color: #fff;
  padding: 10px 20px;
  margin-bottom: 50px;
  display: none;

  ${({ active }) =>
    active &&
    `
      display:block;
    `}
  ${({ type }) =>
    type === 'erro' &&
    `
      background: #f59e9e;
    `}
    ${({ type }) =>
    type === 'sucesso' &&
    `
      background: #DBF1FF;
      color: #737373;
    `}
`

const verMais = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  font-family: Soleil;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #009af8;
  border: 1px solid #009af8;
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  cursor: pointer;

  ${({ showLink }) =>
    showLink &&
    `
        cursor: pointer;
        display: flex;
    `}

  ${respondToMin.tablet`
      display: none;

      ${({ showLink }) =>
        showLink &&
        `
          display: none;
      `}
    `}
`

export const Styled = {
  Container,
  ItemWrap,
  TitleWrap,
  Title,
  DeskLink,
  CarouselWrap,
  Load,
  MsgPartners,
  verMais,
}
