import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from 'store'
import 'bootstrap/dist/css/bootstrap.min.css';
import { HelmetProvider } from 'react-helmet-async'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Routes from './routes'
import GlobalStyle from 'components/UI/Global.styled'
import tapume from 'tapume.png'


ReactDOM.hydrate(
  <Provider store={store}>
    <GlobalStyle />
    <HelmetProvider>
      <App>
        {/* <img style={{margin: '0 auto'}} src={tapume} alt="Logo"  width={'100%'}/> */}
        <Routes />
      </App>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
