import React from 'react'
import { Styled } from './OfferCard.styled'
import { CircleDolarIcon } from 'components/Icons/Icons'
import { Link, useHistory} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import { connect } from 'react-redux'
import LoginBox from 'containers/LoginBox/LoginBox'
import openExternalLink from 'util/LinkToShowCase'
import pushDataLayerGTM from 'util/pushDataLayerGTM'

const Conteudo = ({ list, ...props }) => {
  return (
    <Styled.Wrap>
      <Styled.Image>
        <img src={list.imageUrl} alt="" />
      </Styled.Image>

      <Styled.Description>
        <Styled.headerDescription>
          <Styled.Title>{list.name}</Styled.Title>
          <Styled.Store>{list.vendorName}</Styled.Store>
        </Styled.headerDescription>

        <Styled.PointsInfo>
          {list.regularPrice !== list.price && (
            <Styled.Discount>
              De <span>{list.regularPrice.toLocaleString('pt-BR')}</span> pts
              por
            </Styled.Discount>
          )}
          <Styled.Points>
            {list.price.toLocaleString('pt-BR')} pts
          </Styled.Points>
          <Styled.Info>
            <span>Complete com seu cartão</span> <CircleDolarIcon />
          </Styled.Info>
        </Styled.PointsInfo>
      </Styled.Description>
    </Styled.Wrap>
  )
}

const OfferCard = ({ showModal, list, ...props }) => {
  const history = useHistory()
  const openModal = () => {
    history.push('/Login')
   // window.location.href = '/Login'
    // showModal({
    //   show: true,
    //   content: <LoginBox redirectoCatalog={list.redirectoCatalog} />,
    //   title:
    //     'Preencha o CPF/CNPJ, a senha e faça a validação de segurança para realizar o login.',
    //   width: '554px',
    // })
  }

  const sendEventGTM = (valueEvent) => {
    let category = 'home'
    let action = 'clique:resgatar-meus-pontos'
    let label = valueEvent
    pushDataLayerGTM(category, action, label)
  }

  function checkUrl() {
    if (list.url === '/login') {
      sendEventGTM(`login`)
      openModal()
    } else {
      sendEventGTM(`${list.name}:${list.price}-pontos`)
      openExternalLink(list.url)
    }
  }

  return (
    <>
      <Link onClick={checkUrl}>
        <Conteudo list={list} />
      </Link>
    </>
  )
}

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(OfferCard)
