import styled from 'styled-components'

const Container = styled.div`
  padding: 0 12px;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  margin-top: -250px;
  position: relative;
  background: #fff;
  margin-bottom: 60px;
  z-index:1;

  /* &:hover{
  border: 1px solid #006FD6 ;
 } */
 
  @media (max-width: 700px) {
    flex-direction: column;
    margin: 0 10px;
    margin-top: -250px;
  }
`

const ContentCard = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const Card = styled.button`
  width: 23%;
  min-height: 240px;
  min-width: 220px;
  border: 1px solid #e0e0e0;
  border-radius: 23px;
  opacity: 1;
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;

  &:hover{
  border: 1px solid #006FD6 ;
 }

  @media (max-width: 700px) {
    width: 92%;
  }
`
const ContentLogo = styled.div`
  width: 86%;
  min-height: 152px;
  margin: 16px;
  background: #EBF1EC;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`
const ImgLogo = styled.img`
  width: 65%;

  @media (max-width: 700px) {
    width: 45%;
  }
`
const TextInfo = styled.p`
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  font-weight:600 !important;
  color:#41484F;
  font-size: 20px;
`
const Discount = styled.span`
  text-align: left;
  font-size: 20px;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #006fd6;
`

const Title = styled.h2`
  margin: 20px 20px;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #41484f;
  opacity: 1;
`
const ContentBtn = styled.div`
  width: 100%;
  margin: 20px;
`
const Button = styled.button`
  width: 97%;
  border: 1px solid #009af8;
  background: none;
  border-radius: 4px;
  height: 48px;
  color: #009af8;
  font-size: 20px;
  cursor: pointer;

  :hover{
    background: #006fd6;
    color: #fff;
    transition: 0.3s;
  }
`

const TextDesc = styled.span`

text-align: left;
font-size: 10px;
letter-spacing: 0px;
color: #41484F;
opacity: 1;
`
export const Styled = {
  Container,
  Card,
  ImgLogo,
  TextInfo,
  Discount,
  ContentLogo,
  Title,
  ContentCard,
  ContentBtn,
  Button,
  TextDesc
}
