import React, { Component, useState } from 'react'
import { Styled } from './NavbarProfile.styled'

import {
  ProfileIcon,
  ArrowLeftMenu,
  ViewMenuIcon,
  LoadIcon,
  Exit,
} from 'components/Icons/Icons'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import { connect } from 'react-redux'
import pushDataLayerGTM from 'util/pushDataLayerGTM'

const NavbarProfilePrivateBar = (props) => {
  const [state, setState] = React.useState({
    infoUsuario: props.infoUsuario,
    isVisible: false,
    loadInfo: props.loadInfo,
  })

  React.useEffect(() => {
    setState({
      ...state,
      infoUsuario: props.infoUsuario,
      loadInfo: props.loadInfo,
    })
  }, [props])

  const toggleClass = () => {
    const isVisible = state.isVisible
    setState({ ...state, isVisible: !isVisible })
  }

  const sendEventGTM = (valueEvent) => {
    localStorage.removeItem('estado')
    let category = 'geral'
    let action = 'clique:header'
    let label = valueEvent
    pushDataLayerGTM(category, action, label)
  }

  return (
    <Styled.Wrap>
      <Styled.LoadInfoUser active={state.loadInfo}>
        <Styled.ModalLoad>
          <h2>Estamos carregando sua experiência</h2>
          <LoadIcon />
        </Styled.ModalLoad>
      </Styled.LoadInfoUser>
      <ul>
        <li>
          <Styled.boxUsuario>
            <span className={`profileIcon ${props.type}`}>
              <ProfileIcon />
            </span>
            <Styled.infoUsuario>
              <Styled.nomeUsuario className={`nomeUsuario ${props.type}`}>
                Olá, <span>{state.infoUsuario.firstName}</span>
              </Styled.nomeUsuario>
              <Styled.pontosUsuario className={props.type}>
                <Styled.boxPontos onClick={toggleClass}>
                  Clique para ver o saldo
                  <Styled.togglePontos>
                    <ViewMenuIcon />
                  </Styled.togglePontos>
                </Styled.boxPontos>
                <Styled.detalhePontos active={state.isVisible}>
                  <Styled.linhaPontos>
                    <span>Disponível</span>
                    <Styled.Pontos>
                      {state?.infoUsuario?.avaliableBalance
                        ? state?.infoUsuario?.avaliableBalance.toLocaleString(
                            'pt-BR'
                          )
                        : 0}{' '}
                      pts
                    </Styled.Pontos>
                  </Styled.linhaPontos>
                  <Styled.linhaPontos>
                    <span>Bloqueado</span>
                    <Styled.Pontos>
                      {state?.infoUsuario?.cardLockBalance
                        ? state?.infoUsuario?.cardLockBalance.toLocaleString(
                            'pt-BR'
                          )
                        : 0}{' '}
                      pts
                    </Styled.Pontos>
                  </Styled.linhaPontos>
                  <Styled.linhaPontos>
                    <span>Total</span>
                    <Styled.Pontos>
                      {state?.infoUsuario?.totalBalance
                        ? state?.infoUsuario?.totalBalance.toLocaleString(
                            'pt-BR'
                          )
                        : 0}{' '}
                      pts
                    </Styled.Pontos>
                  </Styled.linhaPontos>
                </Styled.detalhePontos>
              </Styled.pontosUsuario>
            </Styled.infoUsuario>
          </Styled.boxUsuario>
        </li>
        <li>
          <Styled.Submenu className="submenu logout">
            <ul>
              <li>
                <Link to={'/logout'} onClick={() => sendEventGTM('sair')}>
                  <Exit />
                  <span>Sair</span>
                </Link>
              </li>
            </ul>
          </Styled.Submenu>

          <Styled.IcoArrow className="icoArrow">
            <ArrowLeftMenu />
          </Styled.IcoArrow>
        </li>
      </ul>
    </Styled.Wrap>
  )
}

const mapDispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, mapDispatch)(NavbarProfilePrivateBar)
