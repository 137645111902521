import styled from 'styled-components'

export const Wrap = styled('div')((props) => ({
  background: 'rgba(0, 0, 0, 0.8)',
  position: 'fixed',
  zIndex: 10,
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  display: props.show ? 'block' : 'none',
}))
