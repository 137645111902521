import React from 'react'
import { Styled } from './Vitrine.styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import LoginBox from 'containers/LoginBox/LoginBox'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const VitrineMkp = ({ Categoria, color, txt, txtColor, showModal }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1224 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1224, min: 499 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 499, min: 0 },
      items: 1,
    },
  }

  const authToken = sessionStorage.getItem('auth-token') || null

  const handleIsLogin = (url, login) => {
    if (url && !login) {
      return (window.location.href = url)
    }

    if (url != undefined && login) {
      if (authToken != null) {
        window.open(url, '_blank')
      } else {
        history.push('/Login', { url: url })
        // showModal({
        //   show: true,
        //   content: <LoginBox />,
        //   title:
        //     'Preencha o CPF/CNPJ, a senha e faça a validação de segurança para realizar o login.',
        //   width: '600px',
        // })
      }
    } else {
      history.push('/Login', { url: url })
      // showModal({
      //   show: true,
      //   content: <LoginBox />,
      //   title:
      //     'Preencha o CPF/CNPJ, a senha e faça a validação de segurança para realizar o login.',
      //   width: '600px',
      // })
    }
  }
  return (
    <Styled.VitrineContent colorBg={color}>
      <Container>
        <Row>
          <Col>
            <Styled.VitrineTxtMkp txtColor={txtColor}>
              {txt}
            </Styled.VitrineTxtMkp>

            <Carousel
              responsive={responsive}
              infinite={true}
              removeArrowOnDeviceType={['desktop', 'mobile']}
            >
                {Categoria.map((itens) => {
                  return (
                    <Col>
                      <Styled.CardVitrine className='m-2'
                        onClick={() => handleIsLogin(itens.url, itens.login)}
                      >
                        <Styled.CardImgProd>
                          <Styled.ImgContentAux className='p-3'>
                            <Styled.ImgMKP
                              src={itens.img}
                            />
                          </Styled.ImgContentAux>
                        </Styled.CardImgProd>
                        {itens.desc ? (
                          <Styled.Info>{itens.txtInfo}</Styled.Info>
                        ) : (
                          <Styled.TxtInfo>{itens.txtInfo}</Styled.TxtInfo>
                        )}
                      </Styled.CardVitrine>
                    </Col>
                  )
                })}
            </Carousel>
            <Styled.TxtInfoVitrine>
              *Consulte sempre as condições na página do detalhamento do
              benefício.
            </Styled.TxtInfoVitrine>
          </Col>
        </Row>
      </Container>
    </Styled.VitrineContent>
  )
}
const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(VitrineMkp)
