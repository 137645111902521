import styled from 'styled-components'
import { Styled as NavItemsStyled } from 'components/NavItems/NavItems.styled'

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  overflow: auto;
  flex-direction: column;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  background: #FFF;

  ${NavItemsStyled.Wrap} {
    ul {
      flex-direction: column;
      li {
        padding: 10px 32px;
        width: 100%;
        /* border-bottom: 1px solid #00469b; */

        &.portoPlus {
          order: 3;
          /* border-top: 0px !important; */

          & + li {
            /* border-top: 1px solid #00469b; */
          }
        }

        &:first-child {
          /* border-top: 1px solid #00469b; */
        }

        a {
          color: #000;
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
`

const Pad = styled.div`
  padding: 40px 20px 10px 20px;
`

const CloseMenu = styled.div`
  padding: 20px 0px 0px 30px;
  cursor: pointer;
  display: inline-block;
`

export const Styled = {
  Wrap,
  Pad,
  CloseMenu,
}
