import { action } from 'typesafe-actions'
import { ModalTypes } from './constants'

export const setModalShowRedux = ({
  show,
  content,
  title,
  width,
  showCloseButton,
  type,
  onClose,
}) =>
  action(ModalTypes.SHOW, {
    show,
    content,
    title,
    width,
    showCloseButton,
    type,
    onClose,
  })
