import React from 'react'
import { ProfileIcon, Male } from 'components/Icons/Icons'
import { Styled } from './NavbarProfile.styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import LoginBox from 'containers/LoginBox/LoginBox'
import { useHistory } from 'react-router-dom'

import pushDataLayerGTM from 'util/pushDataLayerGTM'

const NavbarProfile = ({ showModal, ...props }) => {
  const history = useHistory()
  const handleShowLoginForm = () => {
    localStorage.setItem('estado','aberto')
    let category = 'geral'
    let action = 'clique:header'
    let label = 'faca-seu-login'
    pushDataLayerGTM(category, action, label)

    history.push('/Login')
    // showModal({
    //   show: true,
    //   content: <LoginBox />,
    //   title: 'Preencha o CPF/CNPJ, a senha e faça a validação de segurança para realizar o login.',
    //   width: '554px',
    // })
  }

  return (
    <Styled.Wrap onClick={handleShowLoginForm}>
      <Male color={props.color} />
      <Styled.Label mobile={props.mobile}>Entrar</Styled.Label>
    </Styled.Wrap>
  )
}

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(NavbarProfile)
