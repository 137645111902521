import styled from 'styled-components'
import { respondToMin } from 'shared/theme'
import { Styled as NavItemsStyled } from 'components/NavItems/NavItems.styled'
import { UI } from 'components/UI/UI.styled'
import { Link } from 'react-router-dom'

const Relative = styled(UI.Relative)`
    background: #1F1F1F;
    margin-top:80px;
    padding: 20px 40px;
    @media (max-width: 750px) {
      padding: 20px 40px;
    } 

  ${respondToMin.desktopSmall`
    border-top: 1px solid #ededed;
    background: #1F1F1F;
  `}
`

const Wrap = styled.div`
  padding: 32px 0px 12px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #1F1F1F !important;

  ${respondToMin.desktopSmall`
    padding: 64px 0 32px;
    flex-direction: row;
  `}

  ${NavItemsStyled.Wrap} {
    display: none;

    ${respondToMin.desktopSmall`
      display: block;
    `}

    ul {
      flex-direction: column;
      li {
        margin-bottom: 15px;
        a {
          color: #FFF;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
        }
      }
    }
  }
`

const FooterItem = styled.div`
  margin-bottom: 15px;
  color: #fff;
`

const FooterMap = styled.div``

const Logo = styled.div``

const FooterColumn = styled.div``
const FooterColumn2 = styled.div`
@media (max-width: 750px) {
  margin-left: 20px;  
}
`
const FooterColumnImg = styled.div`
@media (max-width: 750px) {
  margin-left: 30px;  
}
`

const FooterLink = styled.span`
  color: #FFF;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  margin-left: 15px;
  > span {
    margin-right: 7px;
  }
`

const FooterLinkInfo = styled.span`
  font-weight: normal;
  line-height: 25px;
  color: #FFF;
  padding-left: 41px;
`

const FooterLogo = styled.div`
  margin-top: 20px;
  text-align: center;
  margin-right:60px;

  ${respondToMin.desktopSmall`
    margin-top: 0;
    text-align: left;
  `}
`

const OuterFooter = styled.div`
  border-top: 1px solid #ededed;
  padding: 25px 0;

  @media (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    margin-right: 25px;
    font-size: 13px;
    display: inline-block;
    white-space: nowrap;
    color: #FFF;
  }
`

const ScrollUp = styled.div`
  position: fixed;
  bottom: 10px;
  right: 20px;
  margin: 0px;
  cursor: pointer;
  display: none;

  ${respondToMin.desktopSmall`
    display:block;
    right: 50px;
  `}
`
const Lista = styled.li`
  a {
    
    color: #fff !important;
  }
`
export const Styled = {
  Relative,
  Wrap,
  FooterItem,
  FooterMap,
  Logo,
  FooterColumn,
  FooterLink,
  FooterLinkInfo,
  FooterLogo,
  OuterFooter,
  ScrollUp,
  Lista,
  FooterColumn2,
  FooterColumnImg
}
