import React from 'react'
import OfferCard from 'components/OfferCard/OfferCard'
import { Styled } from '../Conteudo.styled'
import pushDataLayerGTM from 'util/pushDataLayerGTM'
import SemImagem from '../../../assets/images/LogoPortoNew/SemImg.png'
import { useCount } from '../../../util/context/Count'
// import { useCount } from '../../util/context/Count'

const VitrineLista = ({ list, ...props }) => {
  const { setCount } = useCount()
  const replaceSpecialChars = (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove acentos
      .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
      .replace(/\-\-+/g, '-') // Substitui multiplos hífens por um único hífen
      .replace(/(^-+|-+$)/, '') // Remove hífens extras do final ou do inicio da string
  }

  const sendEventGTM = (valueEvent) => {
    setCount(0)
    let category = 'home'
    let action = 'clique:comprar-com-desconto'
    let label = `${valueEvent}`
    pushDataLayerGTM(category, action, label)
  }

  return (
    <>
      <Styled.WrapImageVitrine>
        {list.map((item, i) => (
          <>
            {item.mainImage !== null && (
              <Styled.ItemImageVitrine>
                <Styled.ImageVitrine
                  to={
                    // item.title == 'Localiza'
                    item.id == process.env.REACT_APP_ID_LOCALIZA
                      ? '/localiza'
                      : `/detalhe-da-oferta/${replaceSpecialChars(
                        item.title
                      ).toLowerCase()}?id=${item.id}${props.categoryId
                        ? `&categoria=${props.categoryId}`
                        : ``
                      }${props.campaignsId
                        ? `&campaignId=${props.campaignsId}`
                        : ``
                      }`
                  }
                  onClick={() =>
                    sendEventGTM(replaceSpecialChars(item.title).toLowerCase())
                  }
                >
                  <Styled.ImgLogoContent>
                    <Styled.ImgLogo
                      width={'200px'}
                      height={'300px'}
                      src={item.mainImage }
                      //src={(item.urlLogo === null || item.urlLogo === undefined) ? item.mainImage : item.urlLogo}
                      key={`lista-de-ofertas-item${i}`}
                    />
                    {/* <Styled.ImgLogo
                      src={(item.mainImage === null || item.mainImage === undefined) ? SemImagem : item.mainImage}
                      key={`lista-de-ofertas-item${i}`}
                    /> */}
                  </Styled.ImgLogoContent>
                  <Styled.ImageVitrineDescription>
                    {/* {item.description} */}
                    {item.title}
                  </Styled.ImageVitrineDescription>
                </Styled.ImageVitrine>
              </Styled.ItemImageVitrine>
            )}
          </>
        ))}
      </Styled.WrapImageVitrine>
    </>
  )
}

export default VitrineLista
