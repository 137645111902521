import styled from 'styled-components'

const Content = styled.div`
  margin-bottom: 100px;

  @media (max-width: 750px) {
    margin: 0 5px;
    padding: 0 5px;
  }
`

const txtInfo = styled.h2`
  text-align: left;
  font-size: 1.3em;
  font-weight: medium;
  letter-spacing: 0px;
  color: #41484f;
  opacity: 1;
  margin-bottom: 20px;
  padding: 0 20px;

  @media (max-width: 750px) {
    font-size: 22px;
  }
`

const CardGroup = styled.div`
  display: flex;
  width:100%;
  
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
const Card = styled.div`
  width: 100%;
  height: 112px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 20px 0 0;

 
  @media (max-width: 1200px) {
    justify-content: flex-start;
    margin-bottom: 14px;
    width: 100%;
  }
`
const Icons = styled.div`
  padding: 14px;
`
const ContentTxt = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled.p`
  text-align: left;
  font-size: 1.1em;
  font-weight: bold;
  letter-spacing: 0px;
  color: #009af8;
  opacity: 1;
`
const SubTitle = styled.span`
  text-align: left !important;
  font-size: 14px;
  letter-spacing: -0.63px;
  color: #404040;
  opacity: 1;
`
export const Styled = {
  Content,
  txtInfo,
  CardGroup,
  Card,
  Icons,
  SubTitle,
  Title,
  ContentTxt,
}
