import React, { useState } from 'react'
import { ReactComponent as ArrowIcon } from './arrow.svg'
import {
  Wrapper,
  Title,
  Arrow,
  Details,
  AccordionBarHelped,
  AccordionIcons,
} from './styles'
import IconLike from 'assets/images/icon-like.png'
import IconDeslike from 'assets/images/icon-deslike.png'
import pushDataLayerGTM from 'util/pushDataLayerGTM'

export default ({ title, description, showHelped, isOpen }) => {
  const [isVisible, toggle] = useState(isOpen)

  const sendEventGTM = (valueEvent) => {
    let page = replaceSpecialChars(window.location.pathname).toLowerCase()

    let pergunta = title?.props?.children
      ? replaceSpecialChars(title.props.children).toLowerCase()
      : replaceSpecialChars(title).toLowerCase()
    let acaoSessao = ''
    let acaoPergunta = valueEvent ? 'abriu' : 'fechou'

    if (
      page == 'faq-cartao' ||
      page == 'faq-corretor' ||
      page == 'faq-descontos'
    ) {
      acaoSessao = 'clique:perguntas'
    } else {
      acaoSessao = `interacao:${page}`
    }

    let category = page
    let action = acaoSessao
    let label = `${acaoPergunta}:${pergunta}`
    pushDataLayerGTM(category, action, label)
  }

  function replaceSpecialChars(str) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove acentos
      .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
      .replace(/\-\-+/g, '-') // Substitui multiplos hífens por um único hífen
      .replace(/(^-+|-+$)/, '') // Remove hífens extras do final ou do inicio da string
  }

  return (
    <Wrapper>
      <Title
        onClick={() => {
          toggle(!isVisible)
          sendEventGTM(!isVisible)
        }}
        active={isVisible}
      >
        <h4>{title}</h4>
        <Arrow as={ArrowIcon} active={isVisible} alt="Arrow" />
      </Title>

      <Details active={isVisible}>
        {description}

        <AccordionBarHelped showHelped={showHelped}>
          Essa resposta te ajudou?
          <AccordionIcons>
            <img src={IconLike} />
          </AccordionIcons>
          <AccordionIcons>
            <img src={IconDeslike} />
          </AccordionIcons>
        </AccordionBarHelped>
      </Details>
    </Wrapper>
  )
}
