import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { respondToMin } from 'shared/theme'

const Form = styled.form`
  background: #555861;
  padding: 20px;
`

const Wrap = styled.div`
  font-family: 'Open Sans';
`

const WrapRecaptcha = styled.div`
  font-family: 'Open Sans';
  transform: scale(0.89);
  transform-origin: 0 0;
  -webkit-transform: scale(0.89);
  -webkit-transform-origin: 0 0;
`

const Label = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: white;
`

const Input = styled.input`
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ffffff;
  background: transparent;
  color: white;
  padding: 8px 0;

  &:focus {
    outline: none;
  }

  ::placeholder {
    background: transparent;
    color: #b5b5b5;
    opacity: 1;
    outline: none;
  }

  :-ms-input-placeholder {
    background: transparent;
    color: #b5b5b5;
    outline: none;
  }

  ::-ms-input-placeholder {
    background: transparent;
    color: #b5b5b5;
    outline: none;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    outline: none;
    border-bottom: 1px solid #b5b5b5;
    -webkit-text-fill-color: #b5b5b5;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`

const InputPassword = styled.input`
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ffffff;
  background: transparent;
  color: white;
  padding: 8px 0;

  &:focus {
    outline: none;
  }

  ::placeholder {
    background: transparent;
    color: #b5b5b5;
    opacity: 1;
    outline: none;
  }

  :-ms-input-placeholder {
    background: transparent;
    color: #b5b5b5;
    outline: none;
  }

  ::-ms-input-placeholder {
    background: transparent;
    color: #b5b5b5;
    outline: none;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    outline: none;
    border-bottom: 1px solid #b5b5b5;
    -webkit-text-fill-color: #b5b5b5;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`

const FormGroup = styled.div`
  margin-bottom: 25px;
  position: relative;
`

const FooterTitle = styled.div`
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 15px;
  color: #737373;
`

const FooterSubTitle = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
`

const Button = styled.button`
  width: 100%;
  border: 0;
  color: white;
  background: #009af8;
  font-size: 12px;
  line-height: 16px;
  padding: 10px;
  text-transform: uppercase;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  &:disabled {
    background: #cacaca;
  }
`

const Footer = styled.div`
  text-align: center;
  padding: 30px;
  background: white;
  border-radius: 0 0 8px 8px;
`

const ShowPassword = styled.p`
  border: 0;
  background: none;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-100%);
`

const LinkForgot = styled.div`
  cursor: pointer;
  color: #009af8;
  text-decoration: underline;
  border: 0;
  background: transparent;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  margin-top: 3px;
`

const LinkSecondary = styled(Link)`
  color: #009af8;
  text-decoration: underline;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
`

const ModalWrap = styled.div`
  display: none;
  background: white;
  border-radius: 8px;
  overflow: auto;
  position: relative;
  ${respondToMin.mobile`
    display: block;
  `}
`

const ModalWrapMobile = styled.div`
  width: 100%;
  height: auto;
  display: block;
  align-items: self-start;
  padding: 20px;
  ${respondToMin.mobile`
    display: none
  `}
`

const BodyIframeMobile = styled.div`
  .regulamentoMobile {
    display: block;
    width: 100%;
    height: calc(100vh - 350px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px;
    margin: 20px 0px;
    ${respondToMin.mobile`
        display: none;
    `}
  }
`

const FooterIframeMobile = styled.div`
  padding: 0px;
`
const Load = styled.div`
  display: none;
  padding-left: 20px;

  svg {
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(360deg);
      }
      100% {
        -webkit-transform: rotate(0deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(360deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }

  ${({ active }) =>
    active &&
    `
    display:block;

    svg {
      path {
        fill: #FFF;
      }
    }
  `}
`

const Msg = styled.div`
  color: #fff;
  padding: 10px 20px;
  display: none;

  ${({ active }) =>
    active &&
    `
      display:block;
      margin-bottom: 20px;
    `}
  ${({ type }) =>
    type === 'erro' &&
    `
      background: #f59e9e;
    `}
    ${({ type }) =>
    type === 'sucesso' &&
    `
      background: #DBF1FF;
      color: #737373;
    `}
`

const TitleIframe = styled.div`
  position: absolute;
  width: 100%;
  background: #fff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BodyIframe = styled.div`
  position: static;
  background: #555861;
  max-height: 565px;
  overflow: hidden;
`

const LinkVoltar = styled(Link)`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 30px;
  height: 30px;
  text-decoration: none;
  color: #a2a2a2;

  span {
    height: 1px;
    background-color: #a2a2a2;
    border-radius: 1px;
    transition: background-color ease 0.5s;
    z-index: 1;
    top: 15px;
    display: block;
    position: relative;

    &:before {
      height: 1px;
      background-color: #a2a2a2;
      border-radius: 1px;
      transition: background-color ease 0.5s;
      transform: rotate(-40deg);
      top: -4px;
      position: absolute;
      content: '';
      width: 13px;
      left: -1px;
    }

    &:after {
      height: 1px;
      background-color: #a2a2a2;
      border-radius: 1px;
      transition: background-color ease 0.5s;
      transform: rotate(40deg);
      top: 4px;
      position: absolute;
      content: '';
      width: 13px;
      left: -1px;
    }
  }
`

const BodyPDFIframe = styled.div`
  position: static;
  background: transparent;
  overflow: hidden;
  width: 100%;
  margin: 80px auto 0 auto;

  h2 {
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 20px;
    margin: 12px;
  }

  .regulamentoMobile {
    display: block;
    width: 100%;
    height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 40px 20px;
    margin: 20px 0px;
    ${respondToMin.desktopSmall`
        display: none;
    `}
  }

  iframe {
    display: none;
    ${respondToMin.desktopSmall`
      display: block;
    `}
  }
`

const FooterIframe = styled.div`
  padding: 20px;
`

const BoxCheck = styled.div`
  margin-bottom: 15px;

  .container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover input ~ .checkmark {
      background-color: #ccc;
    }

    input {
      opacity: 0;
      cursor: pointer;
      position: absolute;
      left: 0;
      z-index: 1;

      &:checked ~ .checkmark {
        background-color: #2196f3;
        &:after {
          display: block;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: #eee;
      border: 1px solid #0070d7;
      box-sizing: border-box;
      border-radius: 2px;

      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 4px;
        top: 1px;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
`

const LinkFechar = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`

const WrapRegulamento = styled.div`
  h3,
  h4 {
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 20px;
    &.disc {
      list-style: disc;
      padding-left: 20px;
    }
    &.roman {
      list-style: lower-roman;
      padding-left: 20px;
    }
    &.alpha {
      list-style: lower-alpha;
      padding-left: 20px;
    }
  }

  li {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
    line-height: 125%;
  }
`

const TextSignUp = styled.div`
  color: #fff;
  line-height: 150%;
  font-size: 12px;
`

export const Styled = {
  Label,
  Input,
  Form,
  Footer,
  Wrap,
  FormGroup,
  Button,
  FooterTitle,
  FooterSubTitle,
  InputPassword,
  LinkForgot,
  ShowPassword,
  LinkSecondary,
  ModalWrap,
  ModalWrapMobile,
  BodyIframeMobile,
  FooterIframeMobile,
  Load,
  Msg,
  TitleIframe,
  BodyIframe,
  LinkVoltar,
  BodyPDFIframe,
  FooterIframe,
  BoxCheck,
  WrapRegulamento,
  LinkFechar,
  TextSignUp,
  WrapRecaptcha,
}
