import styled from 'styled-components'
import { respondToMin } from 'shared/theme'

const Wrap = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const verMais = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  font-family: Soleil;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #009af8;
  border: 1px solid #009af8;
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  cursor: pointer;

  ${({ showResults }) =>
    showResults &&
    `
        display: flex;
    `}
`

const vitrineDesk = styled.div`
  display: none;
  ${respondToMin.tablet`
    display: block;
  `}
`
const vitrineMobile = styled.div`
  display: block;
  padding: 0px 20px;
  ${respondToMin.tablet`
    display: none;
  `}
`

const Load = styled.div`
  display: none;
  padding-left: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  svg {
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(360deg);
      }
      100% {
        -webkit-transform: rotate(0deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(360deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }

  ${({ active }) =>
    active &&
    `
    display: flex;
  `}
`

const itemsWrap = styled.div`
  img {
    max-width: 100%;
  }
`
const Msg = styled.div`
  color: #fff;
  padding: 10px 20px;
  margin-bottom: 50px;
  display: none;

  ${({ active }) =>
    active &&
    `
      display:block;
    `}
  ${({ type }) =>
    type === 'erro' &&
    `
      background: #f59e9e;
    `}
    ${({ type }) =>
    type === 'sucesso' &&
    `
      background: #DBF1FF;
      color: #737373;
    `}
`
const CarouselWrap = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`

export const Styled = {
  Wrap,
  verMais,
  vitrineDesk,
  vitrineMobile,
  Load,
  Msg,
  CarouselWrap,
  itemsWrap,
}
