import styled from 'styled-components'
import { respondToMin } from 'shared/theme'

const Wrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right:25px;

  ul {
    display: flex;
    color: initial;
    width: '100%';

    li {
      position: relative;
      display: flex;
      align-items: center;
      width: '100%';

      .profileIcon {
        svg {
          path {
            fill: #a8deff;
          }
        }

        &.menubar {
          svg {
            path {
              fill: #009af8;
            }
          }
        }

        ${respondToMin.desktopSmall`
          svg {
            path{
              fill: #009AF8;
            }
          }
        `}
      }

      .submenu {
        display: none !important;
      }
      .logout {
        right: 0;
        width: 200px;
      }

      .icoArrow {
        display: none;

        svg {
          width: 10px;
          height: 10px;
          transform: rotate(90deg);

          path {
            fill: #000;
          }
        }
      }

      ${respondToMin.desktopSmall`
        .icoArrow{
            display: block !important;
        }
        &:hover{
          .submenu {
            display: block !important;
          }
          .icoArrow{
            display: block !important;
            svg{
              transform: rotate(270deg);
              
              path {
                fill: #009AF8;
              }
            }
          }
        }
      `}
    }
  }
`

const IcoArrow = styled.span`
  display: none;

  svg {
    width: 10px;
    height: 10px;
    transform: rotate(90deg);

    path {
      fill: #000;
    }
  }

  &:hover {
    svg {
      transform: rotate(270deg);

      path {
        fill: #009af8;
      }
    }
  }

  ${respondToMin.desktopSmall`
    display: block;
  `}
`

const Label = styled.span`
  color: #000;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 22px;
  margin-left: 15px;
  display: ${(props) => (props.mobile ? 'inline-block' : 'none')};

  ${respondToMin.desktopSmall`
    display: block;
  `}
`

const boxUsuarioMobileBar = styled.div`
  background: #dbf1ff;
  width: 100%;
  z-index: 1;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${respondToMin.desktopSmall`
    display: none !important;
  `}
`

const Submenu = styled.div`
  display: none;
  position: absolute;
  top: 35px;
  padding-top: 35px;
  z-index: 1;
  width: 100%;

  ul {
    flex-direction: column;
    padding: 0px;
    background: #ffffff;
    border: 1px solid #d1dbdd;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 4px 4px;

    li {
      white-space: nowrap !important;
      margin: 0px;

      &:hover {
        color: #0070d7;
      }

      a {
        padding: 15px;
        font-size: 14px;
        width: 100%;
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }
      }
    }
  }
`

const boxUsuario = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: '100%';
`

const infoUsuario = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 15px 0px 10px;
  min-width: 200px;
  min-height: 60px;
  flex: 2;
  position: relative;

  .nomeUsuario {
    padding: 10px 20px 60px 20px;
    min-width: 250px;
    color: #000;

    &.menubar {
      color: #192534;
      span {
        color: #0070d7;
      }
    }

    ${respondToMin.desktopSmall`
      color: #192534;
      span{
        color: #0070D7;
      }
      svg {
        path{
          fill: #009AF8;
        }
      }
    `}
  }
`

const pontosUsuario = styled.div`
  background: #dbf1ff;
  border-radius: 16px;
  font-family: "PortoRoobert";
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  padding: 0px;
  font-weight: 700;
  border: 1px solid #dbf1ff;
  position: absolute;
  top: 35px;
  z-index: 1;
  min-width: 250px;
  display: block !important;

  &.menubar {
    background: #ffffff;
  }

  ${respondToMin.desktopSmall`
    padding: 5px 10px;
  `}

  span {
    color: #0070d7;
    font-weight: 600;
    padding-right: 5px;
  }

  ${({ active }) =>
    active &&
    `
    display:block !important;
  `}
`
const boxPontos = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${respondToMin.desktopSmall`
    padding: 5px 10px;
  `}
`
const detalhePontos = styled.div`
  padding: 0px 20px 10px 20px;
  display: none !important;

  ${respondToMin.desktopSmall`
    padding: 0px 10px 10px 10px;
  `}

  ${({ active }) =>
    active &&
    `
      display:block !important;
  `}
`

const linhaPontos = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  color: #0070d7;
  font-weight: 600;
`
const Pontos = styled.div`
  padding-right: 5px;
  color: #737373;
`

const nomeUsuario = styled.div`
  font-family: "PortoRoobert";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  padding: 0px 0px 0px 10px;

  ${respondToMin.desktopSmall`
    padding: 5px 10px;
  `}

  span {
    color: #0070d7;
    font-weight: 700;
    flex: 2;
    padding-left: 5px;
  }
`

const togglePontos = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 10px;

  ${respondToMin.desktopSmall`
    padding: 0px;
  `}
`

const LoadInfoUser = styled.span`
  display: none;
  padding-left: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background: #f00;

  svg {
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(360deg);
      }
      100% {
        -webkit-transform: rotate(0deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(360deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }
  ${({ active }) =>
    active &&
    `
    display: flex;
  `}
`

const ModalLoad = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  color: #fff;
  background: #000;
  opacity: 0.8;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 20px;
  }
`

export const Styled = {
  Wrap,
  Label,
  Submenu,
  boxUsuario,
  boxUsuarioMobileBar,
  infoUsuario,
  pontosUsuario,
  boxPontos,
  detalhePontos,
  linhaPontos,
  Pontos,
  nomeUsuario,
  togglePontos,
  IcoArrow,
  LoadInfoUser,
  ModalLoad,
}
