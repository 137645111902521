import styled from 'styled-components'
import { respondToMin } from 'shared/theme'
import { UI } from 'components/UI/UI.styled'

const Wrap = styled(UI.Container)`
  margin: 20px auto;
  padding: 0px;
  ${respondToMin.tablet`
    padding: 0px 40px;
  `}
`

export const Styled = {
  Wrap,
}
