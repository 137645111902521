import React, { useState, useEffect } from 'react'
import jwt from 'jwt-decode'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { setModalShowRedux } from 'store/modal/actions'
import openExternalLink from 'util/LinkToShowCase'
import LoginBox from 'containers/LoginBox/LoginBox'
import axios from 'shared/http'
import verifyIsLoginActive from 'util/verifyIsLoginActive'
import pushDataLayerGTM from 'util/pushDataLayerGTM'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const LinkToShopping = ({ showModal, ...props }) => {
  const history = useHistory()
  const [state, setState] = useState({
    isLoggedIn: localStorage.getItem('auth-token'),
    linkShopping: '',
  })
  const [isOpen, setIsOpen] = useState(false)
  const sendEventGTM = (valueEvent) => {
    let category = 'home'
    let action = 'clique:resgatar-meus-pontos'
    let label = valueEvent
    pushDataLayerGTM(category, action, label)
  }

  const openModal = () =>{
    const url = '/shopping/v1/redirect'
    history.push('/Login' ,{url: url})
  }
    

  useEffect(() => {
    const isLoginActive = verifyIsLoginActive()
    if (typeof isLoginActive != 'undefined')
      generateAutoRedirectToShoppingHub(isOpen)
  }, [isOpen])

  const generateAutoRedirectToShoppingHub = (isOpenWindow) => {
    if (!localStorage.getItem('auto-redirect')) {
      const url = '/shopping/v1/redirect'
      const authToken = JSON.parse(localStorage.getItem('auth-token'))
      const user = jwt(authToken.accessToken)

      const data = {
        participant: user.marketplaceParticipantId,
        participantDocument: user.participantDocument,
      }

      axios
        .post(url, data)
        .then((response) => {
          setState({ ...state, linkShopping: response })
          localStorage.setItem('auto-redirect', Buffer.from(response).toString('base64'))
          let open = localStorage.getItem("wasOpen")
          if (open == null || open == false) {
            openExternalLink(response)
            setIsOpen(true)
            localStorage.setItem('wasOpen', true)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  function verTodos(e) {
    e.preventDefault()
    const isLoginActive = verifyIsLoginActive()
    if (typeof isLoginActive != 'undefined') {
      const autoRedirect = localStorage.getItem('auto-redirect')
      if (autoRedirect) {
        const urlRedirect = Buffer.from(autoRedirect, 'base64').toString('utf8')
        openExternalLink(urlRedirect)
      } else {
        generateAutoRedirectToShoppingHub(true)
      }
    } else {
      sendEventGTM(`login`)
      openModal()
    }
  }

  return <Link onClick={verTodos}>{props.children}</Link>
}

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(LinkToShopping)
