import React, { useEffect } from 'react'
import * as SModal from './Modal.styled'
import { setShow } from 'store/backdrop/actions'
import { setModalShowRedux } from 'store/modal/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { ModalCloseIcon } from 'components/Icons/Icons'

const Modal = ({
  setModalShow,
  setModalShowRedux,
  show,
  modalShow,
  backdropShow,
  showBackdrop,
  children,
  width,
  title,
  showCloseButton = true,
  type,
}) => {
  const onEsc = (e) => {
    if (e.keyCode === 27) {
      setModalShow ? setModalShow(false) : setModalShowRedux(false)
    }
  }

  useEffect(() => {
    showBackdrop(show)

    if (show) window.document.body.style.overflow = 'hidden'
    if (!show) window.document.body.style.overflow = 'auto'
    // eslint-disable-next-line
  }, [show])

  useEffect(() => {
    if (!backdropShow) {
      setModalShow ? setModalShow(false) : setModalShowRedux(false)
      window.document.body.style.overflow = 'auto'
    }
    // eslint-disable-next-line
  }, [backdropShow])

  useEffect(() => {
    document.addEventListener('keydown', onEsc, false)

    return () => document.removeEventListener('keydown', onEsc, false)
    // eslint-disable-next-line
  }, [])

  return show || modalShow ? (
    <SModal.Wrap width={width}>
      {title !== null && (
        <SModal.Title>
          <SModal.Button
            clear
            color="primary"
            onClick={() =>
              setModalShow ? setModalShow(false) : setModalShowRedux(false)
            }
          >
            {showCloseButton && <ModalCloseIcon />}
          </SModal.Button>
          <React.Fragment>
            <SModal.TitleType type={type}>{title}</SModal.TitleType>
          </React.Fragment>
        </SModal.Title>
      )}
      <React.Fragment>{children}</React.Fragment>
    </SModal.Wrap>
  ) : null
}

const mapProps = (state) => ({
  backdropShow: state.backdrop.show,
  modalShow: state.modal.show,
  showCloseButton: state.modal.showCloseButton,
})

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      setModalShowRedux: setModalShowRedux,
      showBackdrop: setShow,
    },
    dispatch
  )

Modal.propTypes = {
  show: PropTypes.bool,
  setModalShow: PropTypes.func,
  children: PropTypes.node,
}

export default connect(mapProps, mapDispatch)(Modal)
