import styled, { createGlobalStyle } from 'styled-components'
import { respondToMin } from 'shared/theme'

const style = createGlobalStyle`
  .slick-list {
   
  }

  .slick-slide {
    padding: 0 20px;
  }

  .slide-pag {
    display: flex !important;
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);

    span {
      cursor: pointer;
      width: 10px;
      height: 10px;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid ${(props) => props.theme.colors.primary};
      border-radius: 100%;
      display: inline-block;
    }

    li {
      margin-right: 10px;
      &.slick-active {
        span {
          background: ${(props) => props.theme.colors.primary};
        }
      }
    }
  }

  .slide-pag-comp {
    display: flex !important;
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);

    span {
      cursor: pointer;
      width: 10px;
      height: 10px;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid ${(props) => props.theme.colors.primary};
      border-radius: 100%;
      display: inline-block;
    }

    li {
      margin-right: 10px;
      &.slick-active {
        span {
          background: ${(props) => props.theme.colors.primary};
        }
      }
    }
  }
`

const Wrap = styled.div`
  margin-bottom: ${({ type }) => (type === 'component' ? '80px' : '0')};

  .slick-slide {
    padding: ${(props) => (props.padding ? props.padding : '0 20px')};
  }
`

const Image = styled.div`
  background: url(${(props) => props.image}) no-repeat;
  background-position: 70% center;
  background-size: ${(props) => (props.bgSize ? props.bgSize : 'cover')};
  min-height: ${(props) => props.minHeight || '400px'};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};

  ${respondToMin.tablet`
    border-radius: 4px;
  `}
`

const Arrow = styled.span`
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 -50px;

  ${({ direction }) => {
    if (direction === 'left') return 'left: 0;'
    if (direction === 'right') return 'right: 0;'
  }}

  @media (max-width: 1200px) {
    display: none;
  }
`

export const Styled = {
  Wrap,
  Arrow,
  Image,
}

export default style
