import styled from 'styled-components'

const VitrineContent = styled.div`
  background: ${(props) => props?.colorBg};
  opacity: 1;
  width: 100%;
  min-height: 424px;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: -3px !important;
`

const VitrineTxt = styled.h1`
  width: 100%;
  padding: 0 8px;
  font-size: 26px;
  /* UI Properties */
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  color: ${(props) => (props.txtColor ? props.txtColor : '#000')};
  margin-bottom: 30px;

  @media (min-width: 1500px) {
    padding-top: 50px;
  }
  @media (max-width: 1499px) {
    padding-top: 50px;
  }
`
const Number = styled.p`
  font-size: 2.3em;
  font-weight: bold;
  color: #0046c0;
`
const Pontos = styled.p`
  font-size: 1em;
  font-weight: 600;
  color: #0046c0;
`

const TxtInfo = styled.h2`
  padding: 8px 25px;
  text-align: left;
  font-size: 1em !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #41484f;
  opacity: 1;
`
const TxtDisc = styled.h2`
  padding: 8px 25px;
  font-size: 1em !important;
  text-align: left;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #41484f;
  opacity: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ContentImg = styled.div`
  min-height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ContentTxt = styled.div`
  min-height: 125px;
`

export const Styled = {
  VitrineContent,
  VitrineTxt,
  TxtInfo,
  TxtDisc,
  ContentImg,
  ContentTxt,
  Number,
  Pontos,
}
