import React, { useContext } from 'react'
import { useState } from 'react'

export const AuthContext = React.createContext({})

export const AuthProvider = (props) => {
  const [cpf, setCpf] = useState(undefined)
  const [emailFa, setEmailsfa] = useState('')
  const [cellFa, setCellFa] = useState('')
  const [infoPart, setInfoPart] = useState()
  return (
    <AuthContext.Provider
      value={{
        cpf,
        setCpf,
        emailFa,
        setEmailsfa,
        cellFa,
        setCellFa,
        infoPart,
        setInfoPart,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
