import React, { useState } from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'

import { Styled } from '../Conteudo.styled'
import VitrineLista from '../VitrineLista'

const CampaignLista = ({ campaigns, ...props }) => {
  const [active, setActive] = useState(0)
  const handleClick = (i) => {
    const index = i
    if (index !== active) {
      setActive(index)
    }
  }

  const replaceSpecialChars = (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove acentos
      .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
      .replace(/\-\-+/g, '-') // Substitui multiplos hífens por um único hífen
      .replace(/(^-+|-+$)/, '') // Remove hífens extras do final ou do inicio da string
  }
  return (
    <>
      <Styled.WrapImageVitrine>
        <Styled.CampaignsListContainer>
          <Styled.WrapVitrine>
            <Styled.Tabs>
              {campaigns.map((item, i) => (
                <Styled.TabCategoryContent>
                  <Styled.ButtonImg
                    onClick={() => handleClick(i)}
                    active={active === i}
                    id={i}
                  >
                    <img src={item.bannerUrl} />
                  </Styled.ButtonImg>
                </Styled.TabCategoryContent>
              ))}
            </Styled.Tabs>
          </Styled.WrapVitrine>
          <>
            {campaigns.map((item, i) => (
              <Styled.Content active={active === i}>
                <Styled.CampaignsDetail>
                  <Styled.CampaignsTitle>
                    {item.name} {item.id != 27 ? ` - Válida até ${' '}
                    ${moment(item.end).locale('pt-br').format('L')}` : '' }
                  </Styled.CampaignsTitle>
                  <p>{item.description}</p>
                </Styled.CampaignsDetail>
                <VitrineLista list={item.rewards} campaignsId={item.id} />
              </Styled.Content>
            ))}
          </>
        </Styled.CampaignsListContainer>
      </Styled.WrapImageVitrine>
    </>
  )
}

export default CampaignLista
