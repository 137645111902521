import React, { Component } from 'react'
import { Styled } from './VitrinePortoPlus.styled'

import { Coin, Credit, CashBack } from 'components/NewIcons/NewIcons'

let List = [
  {
    icons: <Coin />,
    title: 'Compre com dinheiro',
    subTile: 'Pague com cartão ou Pix.',
  },
  {
    icons: <Credit />,
    title: 'Parcele em 12x sem juros',
    subTile: 'Com qualquer cartão de crédito.',
  },
  {
    icons: <CashBack />,
    title: 'Receba o Cashback',
    subTile: 'Viram pontos no PortoPlus.',
  },
]

const VitrinePortoPlus = () => {
  return (
    <Styled.Content>
      <Styled.txtInfo>
        É muito fácil ganhar pontos no PortoPlus ao fazer suas compras.
      </Styled.txtInfo>
      <Styled.CardGroup>
        {List.map((itens, i) => {
          return (
            <Styled.Card>
              <Styled.Icons>
                {itens.icons}
              </Styled.Icons>
              <Styled.ContentTxt>
                <Styled.Title>{itens.title}</Styled.Title>
                <Styled.SubTitle>{itens.subTile}</Styled.SubTitle>
              </Styled.ContentTxt>
            </Styled.Card>
          )
        })}
      </Styled.CardGroup>
    </Styled.Content>
  )
}

export default VitrinePortoPlus
