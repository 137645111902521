import styled from 'styled-components'
import { respondTo } from 'shared/theme'

export const TabItemWrap = styled.div`
  display: flex;
  padding: 0 10px; 
  margin-bottom:20px; 
  @media (max-width: 720px) {
    flex-direction: column
  }
`

export const TabItem = styled.button`
  height: 96px;
  margin: 8px;
  border: 1px solid #006fd6;
  border-radius: 16px;
  background: #006fd6;
  z-index: 1;

  &:hover {
    background: #fff;
    transition: 0.3s;
  }
  ${({
    active,
    theme,
    disabled,
    colorBorderDefaut,
    colorBorderActive,
    colorBorderDisabled,
  }) =>
    active
      ? colorBorderActive || '#FFF'
      : disabled
      ? colorBorderDisabled || '#FFF'
      : colorBorderDefaut || '#ddd'};

`
export const TabItemSec = styled.button`
  margin: 0 12px;
  border: none;
  background: #fff;
  z-index: 2;
  padding-bottom: 10px;

  :hover {
    background: #fff;
    transition: 2s;
  }

  &.active {
    border-bottom: 2px solid #006fd6;
  }
`

export const Tabs = styled.div`
  display: flex;

  ${respondTo.mobile`
    width: 100%;
    margin: 20px 0;
  `}
`
export const TabsTxt = styled.div`
  display: flex;
  margin-left: 12px;

  ${respondTo.mobile`
    width: 100%;
    margin: 20px 0;
  `}

  @media (max-width: 1400px) {
    width: 92%;
    margin: 12px;
    display: block;
    min-width: unset;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 19px;
  }
`

export const TabBadge = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  background: ${({ theme }) => theme.colors.secondary};
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  padding: 1px 6px;
  border-radius: 16px;
`

export const InfoText = styled.h1`
  margin: 20px;
  font-size: 1.5em;
  color: #41484f;
`
export const ImgIcons = styled.div`
  padding: 20px;
`

export const TxtBtn = styled.p`
  color: #fff;
  font-size: 1.1em;
  padding: 20px;
  text-align: left;

  @media  (max-width: 1400px){
    font-size: 1.1em !important;
  }
`
export const none = styled
export const BtnContent = styled.div`
  display: flex;
  flex-direction: row;

  &:hover {
    background: #fff;
    transition: 2s;

    p {
      color: #006fd6;
    }

    path {
      color: #006fd6 !important;
      fill: #006fd6 !important;
      stroke: #006fd6 !important;
    }
  }
`
