import React, { createContext, useState, useContext, useEffect } from 'react'

import { TrocarPontos, CashBack, Desconto} from 'components/Icons/Icons'
import Vitrine from 'containers/Vitrine/Conteudo/Conteudo'
import VitrineGroup from 'components/VitrineCategoria/VitrineGruop'
import VitrineCashBack from '../../containers/VitrineCashback'
import VitrineOfertas from '../../containers/VitrineOfertas'
import ImgPontos from '../../assets/images/ico/Icons-Button/Caminho-630.png'
import ImgDolar from '../../assets/images/ico/Icons-Button/Grupo-112.png'
import ImgPorcent from '../../assets/images/ico/Icons-Button/Grupo-190.png'

const CountContext = createContext()

export default function CountProvider({ children }) {
  const [count, setCount] = useState(0)
  const [active, setActive] = useState(0)
  const [bannerAux, setBannerAux] = useState(false)

  useEffect(() => {
    if (window.location.pathname.indexOf('/comprar-com-desconto/') >= 0) {
      setCount(2)
      let physics = [
        {
          component: <VitrineGroup />,
          active: false,
        },
        {
          component: <Vitrine />,
          name: <span>Troque<strong> pontos</strong> por produtos e serviços</span>,
          active: false,
          icon: <TrocarPontos/>,
        },
        {
          component: <VitrineOfertas />,
          name: <span>Compre produtos e serviços com <strong> descontos</strong></span> ,
          active: true,
          icon: <CashBack/>,
        },
        {
          component: <VitrineCashBack />,
          name: <span>Compre produtos e ganhe <strong> cashback em pontos</strong></span>,
          active: false,
          icon: <Desconto/>,
        },
      ]
      setTabList(physics)
    }
  },[count])

  let physics = [
    {
      component: <VitrineGroup />,
      active: true,
    },
    {
      component: <Vitrine />,
      name: <span>Troque<strong> pontos</strong> por produtos e serviços</span>,
      active: false,
      icon: <TrocarPontos/>,
    },
    {
      component: <VitrineOfertas />,
      name: <span>Compre produtos e serviços com <strong> descontos</strong></span> ,
      active: false,
      icon: <CashBack/>,
    },
    {
      component: <VitrineCashBack />,
      name: <span>Compre produtos e ganhe <strong> cashback em pontos</strong></span>,
      active: false,
      icon: <Desconto/>,
    },
  ]
  const [tabList, setTabList] = useState(physics)

  return (
    <CountContext.Provider
      value={{
        count,
        setCount,
        tabList,
        setTabList,
        setActive,
        bannerAux,
        setBannerAux
      }}
    >
      {children}
    </CountContext.Provider>
  )
}

export function useCount() {
  const context = useContext(CountContext)
  const { count, setCount, tabList, setTabList, setActive, bannerAux, setBannerAux } = context
  return { count, setCount, tabList, setTabList, setActive, bannerAux, setBannerAux }
}
