import styled from 'styled-components'

const Content = styled.div`
  margin-top: 60px;

  .card-hegth {
    height: 240px;
  }
  img {
    max-width: 100% !important;
  }
  @media (min-width: 1440px) {
    .card-hegth {
      height: 280px;
    }
  }

  p {
    font-size: 16px;
  }

  h1 {
    font-weight: bold;
  }
  .txt-link {
    color: #0046c0;
    text-transform: uppercase;
  }

  .poiter {
    cursor: pointer;
    :hover {
      border: 1px solid #0046c0;
    }
  }
`
const txtH2 = styled.h2`
  text-align: left;
  font-weight: 600 !important;
  font-size: 26px;
  letter-spacing: 0px;
  color: #41484f;
  opacity: 1;
  margin-bottom: 36px;
`
const ContentCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`
const Card = styled.button`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: 49%;
  min-height: 216px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  border-radius: 23px;
  opacity: 1;
  margin-bottom: 24px;

  &:hover {
    border: 1px solid #006fd6;
  }

  @media (max-width: 850px) {
    width: 100%;
  }
`

const ContentInfo = styled.div`
  margin-left: 40px;
  width: 100%;
`
const TxtInfo = styled.div`
  max-width: 220px;
  text-align: left;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #41484f;
  opacity: 1;
`
const BtnInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  height: 41px;
  /* UI Properties */
  background: #006fd6 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #fff;
  margin-top: 20px;
`
const ContentImg = styled.div``
const Img = styled.img`
  width: 90%;
`

export const Styled = {
  Content,
  txtH2,
  Card,
  ContentCard,
  ContentInfo,
  TxtInfo,
  BtnInfo,
  ContentImg,
  Img,
}
