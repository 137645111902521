import React from 'react'
import Slider from 'react-slick'
import Style, { Styled } from './Carousel.styled'
import { ArrowIcon } from 'components/Icons/Icons'

const NextArrow = ({ onClick }) => (
  <Styled.Arrow direction="right" onClick={onClick}>
    <ArrowIcon direction="right" />
  </Styled.Arrow>
)

const PrevArrow = ({ onClick }) => (
  <Styled.Arrow direction="left" onClick={onClick}>
    <ArrowIcon direction="left" />
  </Styled.Arrow>
)

const Carousel = ({
  items = [],
  type,
  displayQtd,
  displayQtdTablet,
  displayQtdMobile,
  showArrow,
  slidesPerRow,
  rows,
  minHeight,
  centerMode,
  marginBottom,
  bgSize,
  padding
}) => {
  const responsiveSettings = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: type === 'component' ? displayQtdTablet : 1,
      },
    },
    {
      breakpoint: 720,
      settings: {
        slidesToShow: type === 'component' ? displayQtdTablet : 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: type === 'component' ? displayQtdMobile : 1,
        centerMode: type === 'component' ? true : false,
      },
    },
  ]

  const responsiveSettingsMultpleRows = [
    {
      breakpoint: 1024,
      settings: {
        slidesPerRow: 1,
        slidesToShow: 3,
        slidesToScroll: 3,
        rows: 3,
      },
    },
    {
      breakpoint: 720,
      settings: {
        slidesPerRow: 1,
        slidesToShow: 2,
        slidesToScroll: 2,
        rows: 3,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesPerRow: 1,
        slidesToShow: 2,
        slidesToScroll: 2,
        rows: 2,
      },
    },
  ]

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: displayQtd || 1,
    slidesToScroll: displayQtd || 1,
    slidesPerRow: slidesPerRow || 1,
    rows: rows || 1,
    dotsClass: type === 'component' ? 'slide-pag-comp' : 'slide-pag',
    customPaging: (e) => <span />,
    arrows: showArrow,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: slidesPerRow
      ? responsiveSettingsMultpleRows
      : responsiveSettings,
  }

  return (
    <Styled.Wrap type={type} padding={padding}>
      <Style />
      <Slider {...settings}>
        {items.map((item, i) => {
          if (type === 'component') {
            return <div key={i}>{item.component}</div>
          } else {
            return (
              <Styled.Image
                key={i}
                image={item.image}
                minHeight={minHeight}
                marginBottom={marginBottom}
                bgSize={bgSize}
              />
              
            )
          }
        })}
      </Slider>
    </Styled.Wrap>
  )
}

export default Carousel
