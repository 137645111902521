import moment from 'moment'
import 'moment/locale/pt-br'

const cleanupSession = () => {
  sessionStorage.removeItem('auth-token')
  localStorage.removeItem('auth-token')
  localStorage.removeItem('auto-redirect')
}

const isLoginActive = () => {
  const isLoggedIn = localStorage.getItem('auth-token') || null

  if (isLoggedIn && isLoggedIn !== 'null') {
    const usuario = JSON.parse(isLoggedIn)
    const moment = require('moment-timezone')

    let localTime = moment().locale('pt-br').tz('America/Sao_Paulo').format()
    let tokenIsActive =
      moment(usuario.expiresIn).locale('pt-br').format() > localTime
    let showModalUntil = moment(usuario.expiresIn)
      .add(2, 'hours')
      .locale('pt-br')
      .format()

    let showModalExpire = localTime < showModalUntil

    if (tokenIsActive) {
      return usuario
    } else if (showModalExpire) {
      cleanupSession()
      window.open('/?tokenExpirate', '_self')
    } else {
      cleanupSession()
      window.open('/', '_self')
    }
  }
}

export default isLoginActive
