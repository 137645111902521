import React, { useState, useEffect } from 'react'
import VitrineLista from '../VitrineLista'
import CampanhaLista from '../CampanhaLista'
import { Styled } from '../Conteudo.styled'
import axios from 'shared/http'
import { LoadIcon } from 'components/Icons/Icons'
import localizaVitrine from 'assets/images/localiza/PORTO_PLUS_PROTECAO_PARCIAL.png'
import pushDataLayerGTM from 'util/pushDataLayerGTM'
import SemImagem from '../../../assets/images/LogoPortoNew/SemImg.png'

const TabContent = (props) => {
  useEffect(() => {
    if (props.content.id != 99) {
      getRewards('')
      getCity()
    }
  }, [])

  const [listRewards, setListRewards] = React.useState([])
  const [vitrine, setVitrine] = React.useState([])
  const [stateCityList, setStateCityList] = React.useState([])
  const [stateCitySelect, setStateCitySelect] = React.useState('')
  const [categorySelect, setCategorySelect] = React.useState([])

  const [state, setState] = React.useState({
    msg: '',
    msgType: '',
    loadingRewards: false,
    MostrarVerMais: false,
  })

  // function getRewards(parm) {
  // setState({
  //   ...state,
  //   loadingRewards: true,
  // })

  // if (parm === '') {
  //   parm = `
  //     ?CategoryId=${props.content.id}
  //     ${categorySelect.length > 0 ? '&SubCategoryId=' + categorySelect : ''}
  //     ${
  //       stateCitySelect != ''
  //         ? '&StateCity=' + encodeURIComponent(stateCitySelect)
  //         : ''
  //     }
  //   `
  // }

  // let url = `/api/Lists/rewards${parm}`
  // url = url.replace(/ /g, '')
  // axios
  //   .get(url)
  //   .then((response) => {
  //     if (response.length == 0) {
  //       setState({ ...state, MostrarVerMais: false })
  //       setState({ ...state, loadingRewards: false })
  //       setListRewards([])
  //       setVitrine([])
  //     } else {
  //       let newList = response
  //       if (
  //         props.content.id == 7 ||
  //         props.content.id == 9 ||
  //         props.content.id == 13
  //       ) {
  //         let localiza = {
  //           id: 0,
  //           title: 'Localiza',
  //           description: 'Localiza',
  //           createdAt: '',
  //           images: '',
  //           mainImage: localizaVitrine,
  //           regulations: '',
  //           urlLogo: SemImagem,
  //         }
  //         newList.unshift(localiza)
  //       }

  //       verMais(newList)
  //     }
  function getRewards(parm) {
    setState({
      ...state,
      loadingRewards: true,
    })

    if (parm === '') {
      parm = `
        ?CategoryId=${props.content.id}
        ${categorySelect.length > 0 ? '&SubCategoryId=' + categorySelect : ''}
        ${stateCitySelect != ''
          ? '&StateCity=' + encodeURIComponent(stateCitySelect)
          : ''
        }
      `
    }

    let url = `/api/Lists/rewards${parm}`
    url = url.replace(/ /g, '')
    axios
      .get(url)
      .then((response) => {
        if (response.length == 0) {
          setState({ ...state, MostrarVerMais: false })
          setState({ ...state, loadingRewards: false })
          setListRewards([])
          setVitrine([])
        }
        else {
          let newList = response
          verMais(newList)
        }
      })
      .catch((error) => {
        console.log('Erro: ', error)
        setState({ ...state, MostrarVerMais: false })
        setState({ ...state, loadingRewards: false })
        setListRewards([])
        setVitrine([])
      })
  }
  function getCity() {
    let parm = `
      ?CategoryId=${props.content.id}
      ${categorySelect.length > 0 ? '&SubCategoryId=' + categorySelect : ''}
    `

    let url = `/api/Lists/city${parm}`
    url = url.replace(/ /g, '')
    axios
      .get(url)
      .then((response) => {
        setStateCityList(response)
      })
      .catch((error) => {
        console.log('Erro: ', error)
      })
  }

  function verMais(response) {
    let currentList = []
    if (response.length) {
      currentList = response
    } else {
      currentList = listRewards
    }

    const categoryId = props.content.id
    let quantidadeItens = ''
    if (categoryId === 13) {
      quantidadeItens = vitrine.length <= 12 ? vitrine.length + 12 : 24
    } else {
      quantidadeItens = vitrine.length + 12
    }
    let NewVitrine = []

    currentList.map((item, i) => {
      if (i <= quantidadeItens - 1) {
        NewVitrine.push(item)
      }
    })

    let MostrarVerMais = false

    if (categoryId === 13) {
      MostrarVerMais =
        quantidadeItens >= currentList.length
          ? false
          : quantidadeItens >= 24
            ? false
            : true
    } else {
      MostrarVerMais = quantidadeItens >= currentList.length ? false : true
    }

    setState({ ...state, loadingRewards: false, MostrarVerMais })
    setListRewards(currentList)

    if (NewVitrine.length > 1) {
// alterar lista da categoria colocando a movida em primeiro lugar
      let current = NewVitrine.filter(x => x.id == process.env.REACT_APP_ID_MOVIDA)
      if (current.length > 0) {
        let index = NewVitrine.indexOf(current[0])
        let vitrineAux = NewVitrine.splice(index, 1)
        NewVitrine.unshift(vitrineAux[0])
      }
    }


    setVitrine(NewVitrine)
  }

  const sendEventGTM = (valueEvent) => {
    let category = 'home'
    let action = ''
    let label = ``

    if (valueEvent == 'ver-mais') {
      action = 'clique:comprar-com-desconto'
      label = 'ver-mais'
    } else {
      let id = valueEvent ? valueEvent : 0
      let subcategoryName = props.content.subCategory.filter((item) => {
        return item.id == id
      })
      let cat = props.content.name
      let sub = valueEvent ? subcategoryName[0].name : 'todos'

      let category = 'home'
      let action = 'clique:menu-opcoes'
      let label = `${cat}:${sub}`
    }
    pushDataLayerGTM(category, action, label)
  }

  function setFilterCategory(id) {
    sendEventGTM(id)
    let categoryList = []
    if (id == '') {
      setCategorySelect(categoryList)
    } else if (categorySelect.indexOf(id) == -1) {
      categoryList.push(id)
      categoryList = categoryList.concat(categorySelect)
      categoryList = [...new Set(categoryList)]
      setCategorySelect(categoryList)
    } else {
      categoryList = categorySelect
      categoryList.splice(categorySelect.indexOf(id), 1)
      setCategorySelect(categoryList)
    }

    const parm = `
      ?CategoryId=${props.content.id}
      ${categoryList.length > 0 ? '&SubCategoryId=' + categoryList : ''}
      ${stateCitySelect != ''
        ? '&StateCity=' + encodeURIComponent(stateCitySelect)
        : ''
      }
    `
    getRewards(parm)
  }

  function handleChangeCity(event) {
    setStateCitySelect(event.target.value)
    const parm = `
      ?CategoryId=${props.content.id}
      ${categorySelect.length > 0 ? '&SubCategoryId=' + categorySelect : ''}
      &StateCity=${encodeURIComponent(event.target.value)}
    `
    getRewards(parm)
  }

  return (
    <>
      <Styled.hr hidden={props.content.subCategory.length > 1 ? false : true} ></Styled.hr>
      {props.content.id !== 99 && (
        <>
          <Styled.WrapFilter>
            <Styled.filter>
              {props.content.subCategory.length > 1 && (
                <ul>
                  <Styled.li
                    status={categorySelect.length == 0}
                    onClick={(e) => setFilterCategory('')}
                  >
                    Todos
                  </Styled.li>
                  {props.content.subCategory.map((item, i) => (
                    <Styled.li
                      status={categorySelect.indexOf(item.id) >= 0}
                      onClick={(e) => setFilterCategory(item.id)}
                    >
                      {item.name}
                    </Styled.li>
                  ))}
                </ul>
              )}
            </Styled.filter>

            {stateCityList.length > 0 && (
              <Styled.WrapCity>
                <form autocomplete="off">
                  <Styled.inputItem
                    type="search"
                    list="data"
                    onBlur={(e) => handleChangeCity(e)}
                    placeholder="Selecione uma cidade"
                    autocomplete="false"
                  />
                  <datalist id="data">
                    {stateCityList.map((item, i) => (
                      <option key={`city-${i}`} value={item} />
                    ))}
                  </datalist>
                </form>
              </Styled.WrapCity>
            )}
          </Styled.WrapFilter>
        </>
      )}

      <Styled.Load active={state.loadingRewards}>
        <LoadIcon />
      </Styled.Load>

      <Styled.Msg active={state.msg !== ''} type={state.msgType}>
        {state.msg}
      </Styled.Msg>

      {!state.loadingRewards && (
        <>
          {props.content.id == 99 ? (
            <CampanhaLista campaigns={props.campaigns} />
          ) : (
            <VitrineLista list={vitrine} categoryId={props.content.id} />
          )}

          <Styled.verMais
            onClick={() => {
              verMais('')
              sendEventGTM('ver-mais')
            }}
            showResults={state.MostrarVerMais}
          >
            Ver Mais
          </Styled.verMais>
        </>
      )}
    </>
  )
}

export default TabContent
