import React, { useState, useEffect, useRef, useHistory } from 'react'
import { Styled } from './NewVitrine.styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import LoginBox from 'containers/LoginBox/LoginBox'
import LinkToShopping from 'components/LinkToShopping'

const useMountEffect = (fun) => useEffect(fun, [])

const NewVitrine = ({ list, showModal }) => {
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()
  useMountEffect(executeScroll)
  const url = 'https://portoplus.webpremios.digital/stores/vouchers'
  const [array, setArray] = useState([])
  const [count, setCount] = useState(12)
  useEffect(() => {
    let arrayList = []
    if (list?.length > 1) {
      list.map((itens) => {
        if (arrayList.length === count) {
          return setArray(arrayList)
        }
        arrayList.push(itens)
      })
    }
    // setArray(arrayList)
  }, [list])

  const VerMais = () => {
    let contador = count + 12
    if (contador > list.length) {
      document.getElementById('btnVerMais').hidden = true
    }

    let arrayList = []
    if (list?.length > 1) {
      list.map((itens) => {
        if (arrayList.length === contador) {
          return setArray(arrayList)
        }
        arrayList.push(itens)
      })
    }
    setCount(contador)
  }
  return (
    <>
      <Styled.Container ref={myRef}>
        <div>
          <Styled.Title>
            <strong>
              Compre nos nossos parceiros e ganhe cashback em pontos no
              PortoPlus.{' '}
            </strong>
          </Styled.Title>
        </div>
        <LinkToShopping>
          <Styled.ContentCard>
            {array.map((itens, i) => {
              return (
                <Styled.Card key={i}>
                  <Styled.ContentLogo>
                    <Styled.ImgLogo src={itens.logoUri} alt="Logo" />
                  </Styled.ContentLogo>
                  <Styled.TextInfo>
                    Até <Styled.Discount>{itens.cashback}%</Styled.Discount> de
                    volta *
                  </Styled.TextInfo>
                </Styled.Card>
              )
            })}
          </Styled.ContentCard>
        </LinkToShopping>
        <Styled.ContentBtn>
          <Styled.Button id="btnVerMais" onClick={() => VerMais()}>
            Ver Mais
          </Styled.Button>
        </Styled.ContentBtn>
        <Styled.TextDesc>
          * Consulte sempre as condições na página do detalhamento do benefício.
        </Styled.TextDesc>
      </Styled.Container>
    </>
  )
}

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(NewVitrine)
