import { BackDropTypes } from './constants'

const INITIAL_STATE = {
  show: false,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BackDropTypes.SET_SHOW:
      return { show: action.payload.show }
    default:
      return state
  }
}

export default reducer
