import styled from 'styled-components'
import { UI } from 'components/UI/UI.styled'
import { respondToMin } from 'shared/theme'
import arrowDown from 'assets/images/ico/select_icon_down.png'
import arrowDownActive from 'assets/images/ico/select_icon_down_active.png'
import { Link } from 'react-router-dom'

const Extrato = styled.div`
  position: relative;
  padding-bottom: 50px;
`
const Wrap = styled.div`
  display: block;
  padding: 0px 0px;
 

  @media (max-width: 750px) {
    padding: 0px -40px !important;
    margin: 0px -25px;
  }
`

const Conteudo = styled.div`
  width: 100%;
  ${respondToMin.tablet`
    width: calc(75% - 30px);
  `}
`
const h1 = styled(UI.h1)`
  font-family: Nunito !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 32px !important;
  line-height: 44px !important;
  letter-spacing: -0.02em !important;
  color: #000000 !important;
  padding: 0px 20px;
  ${respondToMin.tablet`
    padding: 0px;
  `}
`
const verMais = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  font-family: Soleil;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #009af8;
  border: 1px solid #009af8;
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  width: 96%;
  margin: 20px 10px;
  cursor: pointer;

  ${({ showResults }) =>
    showResults &&
    `
        display: flex;
    `}

  &:hover {
    background: #006fd6;
    color: #fff;
    transition: 0.3s;
  }
`

const WrapCity = styled.div`
  width: 100%;
  padding: 0px;
  ${respondToMin.tablet`
    width: auto;
    min-width: 200px;
    margin-left: 20px;
  `}
`

const Label = styled.div`
  margin: 0px 0px 10px 0px;
  font-weight: bold;
`

const selectItem = styled.select`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;

  text-indent: 0.01px;
  text-overflow: '';
  select::-ms-expand {
    display: none;
  }

  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  outline: 0;
  background: url(${arrowDown}) calc(100% - 10px) / auto no-repeat;
  padding: 0px 20px 0px 15px;
  height: 35px;
  font-family: Soleil;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #41494f;
  width: 100%;

  &.active {
    color: #ec008c;
    background: url(${arrowDownActive}) calc(100% - 10px) / auto no-repeat
      #f7fafb;
  }

  &:focus,
  &:active,
  option,
  option:focus,
  option:hover {
    outline: none;
    background: url(${arrowDownActive}) calc(100% - 10px) / auto no-repeat
      #f7fafb;
  }

  ${respondToMin.tablet`
        
        background: url(${arrowDown}) calc(100% - 10px) / auto no-repeat;
        padding: 0px 40px 0px 15px;
        &.active{
            background: url(${arrowDownActive}) calc(100% - 10px) / auto no-repeat #F7FAFB;
        }

    `}
`

const inputItem = styled.input`
  ::-webkit-calendar-picker-indicator {
    display: none;
  }

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;

  text-indent: 0.01px;
  text-overflow: '';
  select::-ms-expand {
    display: none;
  }

  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  outline: 0;
  background: url(${arrowDown}) calc(100% - 10px) / auto no-repeat;
  padding: 0px 20px 0px 15px;
  height: 35px;
  font-family: Soleil;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #41494f;
  width: 100%;

  &:hover {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;

    &:before,
    &:after {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      border: none !important;
      content: '';
    }
  }

  &.active {
    color: #ec008c;
    background: url(${arrowDownActive}) calc(100% - 10px) / auto no-repeat
      #f7fafb;
  }

  &:focus,
  &:active,
  option,
  option:focus,
  option:hover {
    outline: none;
    background: url(${arrowDownActive}) calc(100% - 10px) / auto no-repeat
      #f7fafb;
  }

  ${respondToMin.tablet`
        
        background: url(${arrowDown}) calc(100% - 10px) / auto no-repeat;
        padding: 0px 40px 0px 15px;
        &.active{
            background: url(${arrowDownActive}) calc(100% - 10px) / auto no-repeat #F7FAFB;
        }

    `}
`

const WrapVitrine = styled.div`
  display: flex;

  @media (max-width: 750px) {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 10px;
    flex-direction: column;
  }
`

const Tabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: max-content;
  min-width: 100%;

  @media (max-width: 750px) {
    flex-wrap: no-wrap;
    border-bottom: 2px solid rgba(181, 181, 181, 0.4);
  }
`

const TabCategoryContent = styled.div`
  position: relative;
  margin-right: 10px;
`
const IconCategory = styled.div`
  position: absolute;
  top: 10px;
  left: calc(50% - 7px);
  z-index: 2;

  svg {
    max-width: 24px;
    max-height: 24px;
    z-index: 9999999 !important;
  }
  svg g path {
    fill: blue !important;
  }
`

const Tab = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  outline: none;
  margin-right: 0px;
  letter-spacing: -0.025em;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 15px;
  line-height: 33px;
  color: #41484f;

  background: ${(props) => (props.active ? '#DBF1FF' : '#fff')} !important;
  border: ${(props) =>
    props.active ? '1px solid #D1DBDD' : '1px solid #D1DBDD'};
  border-radius: 10px !important;
  padding: 15px;
  margin-bottom: 12px;

  @media (max-width: 750px) {
    padding: 10px;
    height: 40px;
  }

  ${respondToMin.tablet`
    max-height:48px;
    background: ${(props) =>
      props.active ? '#fff' : '#EC008C'}; transparent !important;
    border: ${(props) =>
      props.active ? '1px solid #DBF1FF' : '1px solid #D1DBDD'};
    bottom: -2px;
  `}

  &:hover {
    color: #009af8;
    transition: 0.2s;
  }
`
const ButtonImg = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  outline: none;
  margin-right: 0px;
  letter-spacing: -0.025em;
  font-style: normal;
  font-size: 16px;
  line-height: 33px;
  font-weight: bold;
  color: #41484f;

  background: ${(props) => (props.active ? '#DBF1FF' : '#fff')} !important;
  border: ${(props) =>
    props.active ? '1px solid #D1DBDD' : '1px solid #D1DBDD'};
  border-radius: 10px !important;
  padding: 15px;
  margin-bottom: 12px;

  @media (max-width: 750px) {
    padding: 10px;
    height: 40px;
  }

  ${respondToMin.tablet`
    background: ${(props) =>
      props.active ? '#fff' : '#EC008C'}; transparent !important;
    border: ${(props) =>
      props.active ? '1px solid #DBF1FF' : '1px solid #D1DBDD'};
    bottom: -2px;
  `}
`

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
`

const TabContentIcon = styled.span`
  z-index: 0;
  top: 0px;
  left: calc(50% - 10px);
  display: flex;
  width: 20px;
  margin-right: 12px;
`

const Content = styled.div`
  ${(props) => (props.active ? 'display:block;' : 'display:none;')}
  width: 100%;
`

const WrapFilter = styled.div`
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;

  ${respondToMin.tablet`
    overflow-x: auto;
    overflow-y: hidden;
    flex-direction: row;
    justify-content: space-between;
  `}
`

const filter = styled.div`
  margin-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  ul {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap !important;
    margin: 0px;

    @media (max-width: 750px) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  @media (max-width: 750px) {
    overflow: auto;
  }
`

const li = styled.li`
  min-height: 40px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.025em;
  padding: 5px 25px;
  color: #41484f;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 750px) {
    min-width: 95%;
  }
  ${({ status }) =>
    status &&
    `
        color: #192534;
        background: #DBF1FF;
        border: 1px solid #DBF1FF;
    `}

  &:hover {
    color: #009af8;
    transition: 0.3s;
  }
`

const Load = styled.div`
  display: none;
  padding-left: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  svg {
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(360deg);
      }
      100% {
        -webkit-transform: rotate(0deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(360deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }

  ${({ active }) =>
    active &&
    `
    display: flex;
  `}
`

const Msg = styled.div`
  color: #fff;
  padding: 10px 20px;
  margin-bottom: 50px;
  display: none;

  ${({ active }) =>
    active &&
    `
      display:block;
    `}
  ${({ type }) =>
    type === 'erro' &&
    `
      background: #f59e9e;
    `}
    ${({ type }) =>
    type === 'sucesso' &&
    `
      background: #DBF1FF;
      color: #737373;
    `}
`

const WrapImageVitrine = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const ItemImageVitrine = styled.div`
  padding: 20px;
  width: 100%;
  border: 1px solid #d1dbdd;
  margin: 8px;
  border-radius: 9px;
  &:hover{
  border: 1px solid #006FD6 ;
 }
  ${respondToMin.tablet`
    padding: 10px;
    width: 23%;
  `}
`

const ImageVitrine = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  p {
    color: black;
  }
  ${respondToMin.tablet`
    padding: 5px;
  `}
`

const ImgLogoContent = styled.div`
  border-radius: 9px;
  /* max-height: 152px;
  min-height: 151px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #CBF1EC;
  height:300px;
`
const ImgLogo = styled.img`
  width: 100% !important;
  border: 1px solid #CBF1EC;
  border-radius: 10px;
`

const ImageVitrineDescription = styled.p`
  padding-top: 20px;
  text-align: left;
  overflow: hidden; // Removendo barra de rolagem
  text-overflow: ellipsis; // Adicionando "..." ao final
  display: -webkit-box;
  -webkit-line-clamp: 3; // Quantidade de linhas
  -webkit-box-orient: vertical;
`

const CampaignsDetail = styled.div`
  padding: 30px 0px;
`
const CampaignsTitle = styled.h2`
  margin-bottom: 10px;
  font-size: 18px;
`
const CampaignsListContainer = styled.div`
  padding: 0px 20px;
  width: 100%;
`
const ContentGroup = styled.div`
  padding: 24px;
  position: relative;
  background: #fff;
  margin-top: -250px;
  border-radius: 16px;
  border: 1px solid #ddd;
  z-index: 1;
  margin-bottom:80px;
`

const InfoTxt = styled.h2`
  margin-bottom: 20px;

  text-align: left;
  font-size: 1.3em;
  letter-spacing: 0px;
  color: #41484f;
  opacity: 1;
`
const hr = styled.div`
  border-bottom: 2px solid #006fd6;
  margin: 20px 0;
`

const VitrinePortoPlus = styled.div``

export const Styled = {
  Extrato,
  Wrap,
  Conteudo,
  h1,
  verMais,
  WrapCity,
  Label,
  selectItem,
  inputItem,
  WrapVitrine,
  Tabs,
  TabCategoryContent,
  IconCategory,
  Tab,
  TabContent,
  TabContentIcon,
  Content,
  WrapFilter,
  filter,
  li,
  Load,
  Msg,
  WrapImageVitrine,
  ItemImageVitrine,
  ImageVitrine,
  ImageVitrineDescription,
  CampaignsDetail,
  CampaignsTitle,
  CampaignsListContainer,
  ContentGroup,
  InfoTxt,
  VitrinePortoPlus,
  ImgLogo,
  ButtonImg,
  hr,
  ImgLogoContent,
}
