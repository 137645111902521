import { applyMiddleware, createStore, compose } from 'redux'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()
const middlewares = [routerMiddleware(history)]

const sagaMiddleware = createSagaMiddleware()

middlewares.push(sagaMiddleware)

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

const composer = compose(applyMiddleware(...middlewares))
const store = createStore(rootReducer(history), composer)

sagaMiddleware.run(rootSaga)

export default store
