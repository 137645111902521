import React, { useEffect } from 'react'
import * as SBackDrop from './BackDrop.styled'
import { bindActionCreators } from 'redux'
import * as BackDropActions from 'store/backdrop/actions'
import { connect } from 'react-redux'

const BackDrop = ({ show, setShow, router }) => {
  useEffect(() => {
    setShow(false)
    window.document.body.style.overflow = 'auto'
    // eslint-disable-next-line
  }, [router.location.pathname])

  return <SBackDrop.Wrap show={show} onClick={() => setShow(false)} />
}

const mapState = (state) => ({
  show: state.backdrop.show,
  router: state.router,
})

const mapDispatch = (dispatch) => bindActionCreators(BackDropActions, dispatch)

export default connect(mapState, mapDispatch)(BackDrop)
