import styled from 'styled-components'

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 16px;
  min-width: 206px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  margin-top:40px;
  margin-bottom:40px;
  .desc {
    max-height: 0;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
  }

  &:hover {
    border: 1px solid blue;
    .desc {
      max-height: 300px;
      transition: max-height 0.5s ease-in;
    }
  }
`

const Content = styled.div`
  background-color: #009AF8;
  padding-left: 60px;
  padding-right: 60px;


  @media (max-width: 1400px) {
    padding-left: 10px;
    padding-right: 10px;
    
  }
  @media (max-width: 720px) {
    padding-left: 10px;
    padding-right: 10px;
    
  }
`

const ImageContent = styled.div`
  width: 100%;
`
const Image = styled.img`
  border-radius: 10px;
  width: 100%;
`
const TxtContent = styled.div``
const Txt = styled.h2`
  font-size: 1.1em;
  line-height: 3em;
  padding: 0 20px ;
  font-weight:700;
  color: #404040;
`

const Description = styled.div`
  padding: 10px 20px;
  font-size: 0.8rem;
  line-height: 1.1rem;
  font-weight: 400 ;
  color: rgb(64, 64, 64);
`

const ContentInfo = styled.div`
  background-color: #f6f6f6;
  border-radius: 5px;
  margin: 10px;
`
const Info = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 10px;
  font-size: 0.8rem;
  font-weight: 700;
  color: #404040;
  min-height: 54px;
`

const Icon = styled.img`
  padding-left: 7px;
  margin-right: 8px;
`

const H2 = styled.h2`
  font-size: 1.6rem;
  color:#FFF;
  padding: 30px 0 0 0;
  font-weight:500;
  cursor: pointer;
`

const txtInfo = styled.div`
  font-size: 10px;
  color: #FFF;
  margin-top: -40px;
`
export const Styled = {
  Card,
  Content,
  Image,
  ImageContent,
  TxtContent,
  Txt,
  Description,
  ContentInfo,
  Info,
  Icon,
  H2,
  txtInfo
}
