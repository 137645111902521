import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #fff;
  overflow: hidden;

  &:first-child div:first-child {
    //border-top: 1px solid #8b8b8b;
  }
  &:last-child {
    margin-bottom: unset;
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
  background: transparent;
  -webkit-transition: background 0.5s 0s ease;
  -moz-transition: background 0.5s 0s ease;
  -o-transition: background 0.5s 0s ease;
  transition: background 0.5s 0s ease;

  h4 {
    margin: 0;
    color: #000;
    font-weight: 400 !important;
    line-height: 120%;
    max-width: calc(100% - 25px);
  }

  ${({ active }) =>
    active &&
    `
    border-top: 1px solid #009AF8 !important;    
    background: #F7FAFB;
    padding: 15px 15px 25px 15px;

    h4 {
      color: #009AF8;
    }
  `}
`

export const Arrow = styled.img`
  transition: 0.3s;
  transform: rotate(180deg);

  ${({ active }) =>
    active &&
    `
    transform: rotate(360deg);

    path {
      fill: #009AF8;
    }
  `}
`

export const Details = styled.div`
  max-height: 0;
  transition-duration: 0.5s;
  overflow: hidden;
  text-align: left;
  background: transparent;
  -webkit-transition: background 0.5s 0s ease;
  -moz-transition: background 0.5s 0s ease;
  -o-transition: background 0.5s 0s ease;
  transition: background 0.5s 0s ease;
  border-bottom: 1px solid #8b8b8b;

  p {
    padding: 0px 15px 15px 15px;
    color: #1c1c1c;
    font-size: 15px;
    line-height: 24px;
  }

  ${({ active }) =>
    active &&
    `
    max-height: 450px;
    overflow-x: hidden;
    overflow-y: auto;
    border-bottom: 1px solid #009AF8 !important;
    background: #F7FAFB;
  `}
`

export const AccordionBarHelped = styled.div`
  display: none;
  align-items: center;
  padding: 0 25px ;
  background-color: #D1DBDD;
  width:100%;
  height: 68px;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #000000;

  ${({ showHelped }) =>
    showHelped &&
    `
    display: flex;
  `}
`

export const AccordionIcons = styled.div`
  padding: 0 0 0 20px;
  border: none !important;
`
