import React, { useState } from 'react'
import { Styled } from './Vitrine.styled'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import LoginBox from 'containers/LoginBox/LoginBox'
import openExternalLink from 'util/LinkToShowCase'
import axios from 'shared/http'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import pushDataLayerGTM from 'util/pushDataLayerGTM'

const Vitrine = ({ Categoria, color, txt, txtColor, showModal }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1224 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1224, min: 499 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 499, min: 0 },
      items: 1,
    },
  }

  const history = useHistory()

  const sendEventGTM = (valueEvent) => {
    let category = 'geral'
    let action = 'clique:menu'
    let label = valueEvent
    pushDataLayerGTM(category, action, label)
  }
  const getUrl = (id, urlId) => {
    if (id) {
      const url = `/catalog/v1/vendor/${id}`
      axios
        .get(url)
        .then((response) => {
          return window.open(response.uri, '_blank')
        })
        .catch((error) => {
          console.log(error)
        })
    }
    if (urlId)
      axios
        .get(urlId)
        .then((response) => {
          return window.open(response.uri, '_blank')
        })
        .catch((error) => {
          console.log(error)
        })
  }

  const authToken = localStorage.getItem('auth-token') || null
  const handleIsLogin = async (url, login, id) => {
    if (url && !login) {
      return window.open(url, '_blank')
    }

    if ((url != undefined || id != undefined) && login) {
      if (authToken != null) {
        getUrl(id, url)
      } else {
        history.push('/Login', { id: id, url: url })
      }
    } else {
      history.push('/Login')
    }
  }

  return (
    <Styled.VitrineContent colorBg={color}>
      <Container>
        <Row>
          <Col>
            <Styled.VitrineTxt txtColor={txtColor}>{txt}</Styled.VitrineTxt>
            <Carousel
              responsive={responsive}
              infinite={true}
              removeArrowOnDeviceType={['desktop', 'mobile']}
            >
              {Categoria.map((itens, index) => {
                return (
                  <Card className="m-2 mb-5 card-border">
                    <Card
                      onClick={() => handleIsLogin(itens.url, itens.login, itens?.id)}
                      className="m-3 d-flex justify-content-center align-items-center card-border-int"
                      style={{
                        minHeight: '187px',
                        maxHeight: '188px',
                        background: `${itens.color}`,
                      }}
                    >
                      <Card.Img
                        className="img-vitrine hegth-img"
                        variant="top"
                        src={itens.img}
                      />
                    </Card>
                      <Card.Title className='mb-4'>
                        {itens.desc ? (
                          <Styled.TxtDisc>{itens.txtInfo}</Styled.TxtDisc>
                        ) : (
                          <Styled.TxtInfo>{itens.txtInfo}</Styled.TxtInfo>
                        )}
                      </Card.Title>
                  </Card>
                  // <Col key={index + 10}>
                  //   <Styled.CardVitrine
                  //     onClick={() =>
                  //       handleIsLogin(itens.url, itens.login, itens?.id)
                  //     }
                  //   >
                  //     <Styled.CardImg color={itens.color}>
                  //       <Styled.ImgContent>
                  //         <Styled.Img src={itens.img} />
                  //       </Styled.ImgContent>
                  //     </Styled.CardImg>
                  //     {itens.desc ? (
                  //       <Styled.TxtDisc>{itens.txtInfo}</Styled.TxtDisc>
                  //     ) : (
                  //       <Styled.TxtInfo>{itens.txtInfo}</Styled.TxtInfo>
                  //     )}
                  //   </Styled.CardVitrine>
                  // </Col>
                )
              })}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </Styled.VitrineContent>
  )
}

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(Vitrine)
