import { css } from 'styled-components'
import { action } from 'typesafe-actions'

const breakpoints = {
  desktop: 1200,
  desktopSmall: 1024,
  tablet: 768,
  mobile: 500,
}

const toRGB = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16))

const theme = {
  colors: {
    text: '#1C1C1C',
    textLight: '#728B9C',
    textMuted: '#657881',
    paragraph: '#484848',
    paragraphLight: '#8B8B8B',
    primary: '#009AF8',
    secondary: '#EC008C',
    darkBlue: '#074692',
    darkBlueSecondary: '#00469B',
    white: 'white',
    footer: '#192534',
    footerText: '#737373',
    border: '#e5e5e5',
    mobileMenu: '#0070D7',
  },
  currentFooterBackground: '#ffffff',
  fonts: {
    light: 300,
    medium: 500,
    bold: 700,
    main: 'Gotham Rounded',
    alt: 'Gotham Rounded',
  },
  radius: '25px',
  toRGB: toRGB,
  breakpoints: {
    ...breakpoints,
  },
}

export const respondTo = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (max-width: ${breakpoints[label]}px) {
        ${css(...args)};
      }
    `
    return accumulator
  },
  {}
)

export const respondToMin = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (min-width: ${breakpoints[label]}px) {
        ${css(...args)};
      }
    `
    return accumulator
  },
  {}
)

export const updateTheme = (options) => action('@theme/UPDATE', { options })

export const themeReducer = (state = theme, action) => {
  switch (action.type) {
    case '@theme/UPDATE':
      return {
        ...state,
        ...action.payload.options,
      }
    default:
      return state
  }
}

export const fonts = {
  primart: 'Soleil',
  secondary: 'Nunito',
}

export const BreakPoints = {
  desktop: '1200px',
  desktopSmall: '1024px',
  tablet: '768px',
  mobile: '500px',
}

export default theme
