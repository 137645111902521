import styled from 'styled-components'
import { respondToMin } from 'shared/theme'
import { Link } from 'react-router-dom'

const Wrap = styled.div`
  flex: 2;
  position: relative;
  padding-bottom: 100px;
  background: #fff !important;

  ${respondToMin.desktopSmall`
    padding-bottom: 0px;
  `}
`

const primeiroNivel = styled.ul`
  display: flex;
  color: initial;
`

const itemPrimeiroNivel = styled.li`
  margin-right: 10px;
  position: relative;
  padding: 0px !important;
  background: #fff !important;
  &:last-child {
    margin: 0;
  }

  ${respondToMin.desktopSmall`
    &:hover {
      color: #0070D7;
      background: transparent !important;

      .iconeSeta {
        transform: rotate(180deg);
        padding: 0px 10px 0px 0px;
        
        svg path {
          fill: #009AF8;
        }
      }
      .submenu {
        display: block;
      }
    }
  `}
`

const itemSegundoNivel = styled.li`
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  white-space: nowrap !important;
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
  color: #000;

  ${respondToMin.desktopSmall`
    margin: 0px 0px 20px 0px !important;
  `}

  &:last-child {
    margin: 0 !important;
  }

  span {
    font-size: 14px;
  }
`
const subMenuMobile = styled.div`
  display: none;

  @media (max-width: 440px) {
    display: initial;
  }
`
const linkMenu = styled(Link)`
  font-weight: 600;
  font-size: 1em;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: 100%;

  ${respondToMin.desktopSmall`
    padding: 0px;
    width: auto;
    background: transparent !important;
  `}

  ${({ ativo }) =>
    ativo &&
    `
    background: rgba(35,35,35,0.1);
  `}
`

const wrapLink = styled.div`
  a {
    font-weight: 600;
    font-size: 1em;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    width: 100%;

    ${respondToMin.desktopSmall`
      padding: 0px;
      width: auto;
      background: transparent !important;
    `}

    ${({ ativo }) =>
      ativo &&
      `
      background: rgba(35,35,35,0.1);
    `}
  }
`

const iconeSeta = styled.span`
  padding: 0px 0px 0px 10px;
  svg {
    display: none;

    ${respondToMin.desktopSmall`
      width: 10px;
      height: 10px;
      transform: rotate(90deg);
      display: block;
      path {
        fill: #1F1F1F;
      }
    `}
  }
  img {
    display: block;
    max-width: 10px;
    transform: rotate(90deg);

    ${respondToMin.desktopSmall`
      display: none;
    `}
  }

  ${({ ativo }) =>
    ativo &&
    `
    img {
      transform: rotate(270deg) !important;
    }
  `}
`

const iconeMenu = styled.span`
  padding: 0px 5px 0px 0px;
  svg path {
    fill: #dbf1ff !important;
  }

  ${respondToMin.desktopSmall`
    svg path {
      fill: #009AF8 !important;
    }
  `}
`

const Submenu = styled.div`
  display: none;
  padding: 10px 0px;
  z-index: 1;

  ${({ ativo }) =>
    ativo &&
    `
    display:block;
  `}

  ${respondToMin.desktopSmall`
    position: absolute;
    top: 15px;
    z-index: 1;
  `}

  ul {
    ${respondToMin.desktopSmall`
      flex-direction: column;
      padding: 15px;
      background: #FFFFFF;
      border: 1px solid #D1DBDD;
      box-sizing: border-box;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 0px 0px 4px 4px;
    `}
  }
`

const Sair = styled.div`
  display: block;
  position: absolute;
  bottom: 50px;
  left: 20px;

  a {
    color: #dbf1ff !important;
    font-weight: 600;
    line-height: 25px;
    font-size: 14px !important;
    width: 100%;
    display: flex;
    align-items: center;

    span {
      padding: 0px 0px 0px 10px !important;
    }

    svg {
      transform: rotate(0deg) !important;

      path {
        fill: #000 !important;
      }
    }
  }

  ${respondToMin.desktopSmall`
    display: none;
  `}
`

const icoCheckDesk = styled.img`
  display: block !important;

  ${respondToMin.desktopSmall`
    display: none !important;
  `}
`
const icoCheckMobile = styled.img`
  display: none !important;

  ${respondToMin.desktopSmall`
    display: block !important;
  `}
`
const subMenu = styled.div`
  background: #ddd;
`

const ButtonLink = styled.button`
  padding: 16px 16px;
  border: none;
  background: none;
  text-align: left;
  color: #191c1f;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
    
  &.active {
    padding: 10px 8px;
    display: flex;
    align-items: center;
    margin-top: 14px;
    border-bottom: 2px solid #006fd6;
    height: 10px;
    background: #fff;
    color: #006fd6;
    margin-left: 8px;
    font-size: 18px;
  }

  @media (max-width: 750px) {
    font-size: 18px;    
  }
  `

const MenuMobile = styled.div`
  display: none;

  @media (max-width: 750px) {
    border-top: 2px solid red;
    display: initial;
  }
  `
const itensMenuMobile = styled.div`
  button {
    margin-left: 6px;
    font-size: 18px;
  }
`
const Exit = styled.span`
  color: #000;
  font-size: 24px;
  font-weight: 600;
`
export const Styled = {
  Wrap,
  primeiroNivel,
  itemPrimeiroNivel,
  itemSegundoNivel,
  wrapLink,
  linkMenu,
  iconeSeta,
  iconeMenu,
  Submenu,
  Sair,
  icoCheckDesk,
  icoCheckMobile,
  subMenu,
  subMenuMobile,
  ButtonLink,
  Exit,
  MenuMobile,
  itensMenuMobile,
}
