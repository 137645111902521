import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Styled } from './Vitrine.styled'
import Vitrine from './Vitrine'
import { ConnectedRouter } from 'connected-react-router'

import { history } from 'store'
import axios from 'shared/http'
import pushDataLayerGTM from 'util/pushDataLayerGTM'

import VitrineMkp from './VitrineMkp'
import CarouselServicoPorto from 'components/CarouselServicoPorto/CarouselServicoPorto'

import { ServicoPortocarousel, TroquePontos, OfertaViagem } from './VitrineArray'

import VitrineViagem from './VitrineViagem'

const VitrineGroup = () => {
  const [ganheMais, setGanharMais] = useState([])
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem('auth-token')
  )
  // const [desconto, setDesconto] = useState([])
  const [desconto1, setDesconto1] = useState([])
  const sendEventGTM = (valueEvent) => {
    let category = 'geral'
    let action = 'clique:menu'
    let label = valueEvent
    pushDataLayerGTM(category, action, label)
  }
  useEffect(() => {
    getShoppingShowCase()
    // getShoppingDesconto()
    getCampaigns()
  }, [])
  const arrayDesc = []

  const getShoppingShowCase = async () => {
    const status = isLoggedIn ? 'logado' : 'deslogado'
    const url = `/stores/v1/${status}`
    let array = []
    let contador = []
    await axios.get(url).then((response) => {
      for (var i = 0; i < response.length; i++) {
        let valor = Math.floor(Math.random() * response.length)
          if (!contador.includes(valor)) {
            array.push({
              img: response[valor].logoUrl,
              txtInfo: response[valor]?.name,
              color: '#FFF',
              login: true,
              desc: true,
              id: response[valor].id
            })
            contador.push(valor)
          }
          if (array.length === 4) {
            return setGanharMais(array)
          }
      }
    })
  }

  const replaceSpecialChars = (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove acentos
      .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
      .replace(/\-\-+/g, '-') // Substitui multiplos hífens por um único hífen
      .replace(/(^-+|-+$)/, '') // Remove hífens extras do final ou do inicio da string
  }

  async function getCampaigns() {
    await axios
      .get('/api/Lists/subcategory')
      .then((response) => {
        response.map((item) => {
          getRewards(item.id, item.subCategory[0]?.categoryId)
        })
      })
      .catch((error) => {
        console.log('Erro: ', error)
      })
  }

  async function getRewards(id, categoryId) {
    await axios
      .get(`/api/Lists/rewards?CategoryId=${id}`)
      .then((response) => {
        response.map((item, i) => {
          if (item.id === process.env.REACT_APP_ID_LOCALIZA) {
            arrayDesc.unshift({
              id: id,
              img:item.mainImage,
              txtInfo: item.title,
              color: '#FFF',
              url: '/localiza',
              desc: true,
            })
          } else {
            arrayDesc.unshift({
              id: id,
              img:item.mainImage,
              //img: item.urlLogo != null ? item.urlLogo : item.images ? item.images : item.img,
              // img: item.mainImage != null || item.mainImage != undefined ? item.mainImage : SemImagem,
              txtInfo: item.title,
              color: '#FFF',
              url: `/detalhe-da-oferta/${replaceSpecialChars(
                item.title
              ).toLowerCase()}?id=${item.id}&categoria=${categoryId}`,
              desc: true,
            })
          }
        })
        let newArray = []
        let contadorDesc = []
        for (var i = 0; i < arrayDesc.length; i++) {
          let valor = Math.floor(Math.random() * arrayDesc.length)
          if (!contadorDesc.includes(valor)) {
            newArray.push(arrayDesc[valor])
            contadorDesc.push(valor)
          }
          if (newArray.length === 4) {
            return setDesconto1(newArray)
          }
        }
      })
      .catch((error) => {
        console.log('Erro: ', error)
      })
  }


  return (
    <ConnectedRouter history={history}>
      <CarouselServicoPorto itens={ServicoPortocarousel}/>
      <Vitrine
        Categoria={TroquePontos}
        color={'#A8DEFE'}
        txt={
          <p>
            <strong>Troque pontos</strong> pelos melhores produtos.
          </p>
        }
        txtColor={'#41484F'}
      />
      <VitrineViagem
        Categoria={OfertaViagem}
        color={'#DBF1FF'}
        txt={
          <span>
            <strong>Conheça</strong> nossos parceiros de Viagens.
          </span>
        }
        txtColor={'#41484F'}
      />
      {/* <CardViagem/> */}
      <VitrineMkp
        Categoria={desconto1}
        color={'#FFF'}
        txt={
          <span style={{ color: '#009AF8' }}>
            Economize com <strong>descontos exclusivos.</strong>
          </span>
        }
      />
    </ConnectedRouter>
  )
}

export default VitrineGroup
