import styled from 'styled-components'

export const Wrap = styled('div')((props) => ({
  position: 'fixed',
  zIndex: 19,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '2em',
  width: '100%',
  maxWidth: props.width || '100%',
  overflow: 'auto',
  maxHeight: '100%',
  '.header': {
    margin: '-20px -20px 0px',
    textAlign: 'right',
  },
}))

export const Button = styled.button`
  outline: none;
  border: 0;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  margin: 50px;
`

export const Title = styled.div`
  background: white;
  padding: 60px 20px 40px;
  text-align: center;
  font-family: 'PortoRoobert';
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  border-radius: 8px 8px 0 0;
`

export const TitleType = styled('div')((props) => ({
  color: props.type === 'erro' ? '#f00' : 'inherit',
}))