import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Styled } from './StaticBanner.styled'

import BannerMobile from 'assets/images/productsporto/img/BannerMobile.jpg'
import BannerTablet from 'assets/images/productsporto/img/BannerTablet.jpg'
import BannerDesk from 'assets/images/productsporto/img/BannerDesk.jpg'

const StaticBanner = (props) => {
  const [state, setState] = React.useState({
    Banners: []
  })

  React.useEffect(() => {
    getBanner()
  }, [])

  function getBanner() {
    const windowWidth = window.innerWidth;
    let Banners = [
      {
        link:'',
        target:'',
        title:'',
        image: windowWidth > 1024 ? BannerDesk : windowWidth <= 1024 && windowWidth >= 768 ? BannerTablet : BannerMobile
      }
    ];
    setState({ ...state, Banners })
  }
  
  return (
    <Styled.Wrap>
      <Carousel
        showStatus={false}
        showThumbs={false}
        autoPlay={false}
        infiniteLoop={false}
        showIndicators={false}
      >
        {state.Banners.map((item, i) => (
          <div key={i}>
            <a href={item.link} target={item.target} title={item.title}>
              <img src={item.image} alt={item.title} />
            </a>
          </div>
        ))}
      </Carousel>
    </Styled.Wrap>
  )
}

export default StaticBanner
