import React from 'react'
import { BuildingIcon } from 'components/Icons/Icons'
import { Boy } from 'components/NewIcons/NewIcons'

import { UI } from 'components/UI/UI.styled'
import { Styled } from './Footer.styled'
import NavItemsBlack from 'components/NavItems/NavItemsBack'
// import FooterLogo from 'assets/images/logoPorto/logo-footer.png'
import FooterLogo from 'assets/images/LogoPortoNew/newLogo.png'
import FooterLogoWebp from 'assets/images/LogoPortoNew/newLogo.png'
import FooterItem from './FooterItem'
import BackToTop from 'assets/images/ico/back-to-top.png'
import { Link } from 'react-router-dom'
import LoginBox from 'containers/LoginBox/LoginBox'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import { connect } from 'react-redux'
import pushDataLayerGTM from 'util/pushDataLayerGTM'
import { isWebpSupported } from 'react-image-webp/dist/utils'

import LogoPortoPlusBranca from '../../assets/images/FooterLogo/LogoPortoBranca.png'
import LogoPortoBranca from '../../assets/images/FooterLogo/PortoHolding-RGB-H.png'

//import { ScrollUpIcon } from 'components/Icons/Icons'
//import { BrowserRouter as Router, Switch } from 'react-router-dom'
//import { Modal, ModalContent } from 'components/SimpleModal/SimpleModal'
//import 'components/SimpleModal/SimpleModal.css'

const Footer = ({ showModal, props }) => {
  /* const [isOpen, setIsopen] = useState(false)
  const showModal = () => setIsopen(true) */

  const openModal = (msg) =>
    showModal({
      show: true,
      content: <LoginBox />,
      title: msg,
      width: '554px',
    })

  const handleScrollTop = () => {
    document.getElementById('root').scrollTop = 0
  }

  const sendEventGTM = (valueEvent) => {
    let category = 'geral'
    let action = 'clique:footer'
    let label = valueEvent
    pushDataLayerGTM(category, action, label)
  }

  return (
    <Styled.Relative>
      <Styled.ScrollUp onClick={handleScrollTop}>
        <img src={BackToTop} alt="Back To Top" />
      </Styled.ScrollUp>
      <UI.Container>
        <Styled.Wrap>
          <Styled.FooterColumn>
            <Link
              to="//www.portoseguro.com.br/fale-conosco/contatos/encontre-um-corretor?utm_source=Portoplus&utm_medium=Site&utm_campaign=Rodape"
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => sendEventGTM('Encontre um Corretor')}
            >
              <FooterItem
                icon={<Boy />}
                label="Encontre um Corretor"
                info="Fale com um dos nossos Corretores."
              />
            </Link>
            <Link
              to="//www.portoseguro.com.br/institucional/a-porto-seguro?utm_source=Portoplus&utm_medium=Site&utm_campaign=Rodape"
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => sendEventGTM('Conheça a Porto Seguro')}
            >
              <FooterItem
                icon={<BuildingIcon />}
                label="Conheça a Porto"
                info="Saiba mais"
              />
            </Link>
          </Styled.FooterColumn>
          <Styled.FooterColumn2>
            <NavItemsBlack position="footer">
              <li>
                <Link
                  className="teste"
                  to="//cliente.portoseguro.com.br/portaldecliente/?utm_source=Portoplus&utm_medium=Site&utm_campaign=Rodape"
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={() => sendEventGTM('Portal do Cliente')}
                >
                  Área do Cliente
                </Link>
              </li>
              <li>
                <Link
                  className="teste"
                  to="//corretor.portoseguro.com.br/corretoronline/"
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={() => sendEventGTM('Corretor Online')}
                >
                  Corretor Online
                </Link>
              </li>
            </NavItemsBlack>
          </Styled.FooterColumn2>
          <Styled.FooterColumnImg>
            {isWebpSupported() ? (
              <Styled.FooterLogo>
                <div className="logoHeader">
                  <img
                    width='65%'
                    height={'60px'}
                    src={LogoPortoPlusBranca}
                    alt="Porto"
                  />
                  <span className="bordaLogoBranca"></span>
                  <img
                    width={'50%'}
                    height={'65'}
                    src={LogoPortoBranca}
                    alt="Porto"
                  />
                </div>
              </Styled.FooterLogo>
            ) : (
              <Styled.FooterLogo>
               <div className="logoHeader">
                  <img
                    width='120px'
                    height={'60px'}
                    src={LogoPortoPlusBranca}
                    alt="Porto"
                  />
                  <span className="bordaLogoBranca"></span>
                  <img
                    width={'100'}
                    height={'65'}
                    src={LogoPortoBranca}
                    alt="Porto"
                  />
                </div>
              </Styled.FooterLogo>
            )}
            <UI.HideDesktopSmall>
              <UI.Spacing>
                <UI.small footerText>
                  © 2020 Porto Seguro - Todos os direitos reservados.
                </UI.small>
              </UI.Spacing>
            </UI.HideDesktopSmall>
          </Styled.FooterColumnImg>
        </Styled.Wrap>
      </UI.Container>
      <Styled.OuterFooter>
        <UI.Container>
          <UI.Flex>
            {/* <Modal onOpen={showModal}>
              <span className="titlePolitica"> Politica Privacidade</span>
            </Modal>
            {isOpen && (
              <ModalContent onClose={() => setIsopen(false)}>
                <div className="modal">
                  <h1 className="titlePoliticaModal">
                    Política de Privacidade
                  </h1>
                  <br />
                  <p className="subtitlePolitica">
                    Saiba como a Porto Seguro utiliza os dados dos usuários que
                    acessam os Sites do Grupo.{' '}
                  </p>
                  <br />
                  <p className="textoConteudoPolitica">
                    {' '}
                    Esta Política de Privacidade tem como objetivo informar você
                    (doravante “Usuário”), que acessa as páginas da Porto Seguro
                    na Internet, quais informações são coletadas, como elas são
                    tratadas, bem como as formas de compartilhamento e de
                    exclusão desses dados.{' '}
                  </p>
                  <br />
                  <p className="textoConteudoPolitica">
                    A presente Política de Privacidade é aplicável ao presente
                    portal e/ou aplicativo e a todos os demais sites e/ou
                    aplicativos das empresas do Grupo Porto Seguro - Porto
                    Seguro Cia. de Seguros Gerais - Av. Rio Branco 1.489, Rua
                    Guaianases 1.238, Campos Elíseos - CEP 01205-001 -
                    (“Sites”).{' '}
                  </p>
                  <br />
                  <p className="textoConteudoPolitica">
                    Com a aceitação desta Política de Privacidade pelo Usuário,
                    este concorda e permite o acesso as suas informações a
                    partir do seu primeiro acesso ou utilização de qualquer um
                    dos Sites.{' '}
                  </p>
                  <br />
                  <p className="textoConteudoPolitica">
                    Se o Usuário não concordar com a política de tratamento de
                    dados descrita neste documento deve se abster de utilizar os
                    Sites da Porto Seguro.{' '}
                  </p>
                  <br />
                  <p className="textoConteudoPolitica">
                    {' '}
                    Caso reste alguma dúvida, após a leitura desta Política de
                    Privacidade, entre em contato conosco, pelo e-mail
                    relacionamento.cliente@portoseguro.com.br.
                  </p>
                </div>
              </ModalContent>
            )} */}
            <Link
              className="white"
              to="//www.portoseguro.com.br/politica-de-privacidade?utm_source=Portoplus&utm_medium=Site&utm_campaign=Rodape"
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => sendEventGTM('Políticas de privacidade')}
            >
              Políticas de privacidade
            </Link>
          </UI.Flex>
        </UI.Container>
      </Styled.OuterFooter>
    </Styled.Relative>
  )
}

// export default Footer

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(Footer)
