import { ModalTypes } from './constants'

const INITIAL_STATE = {
  title: '',
  content: null,
  width: '',
  show: false,
  showCloseButton: true,
  type: '',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModalTypes.SHOW:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
