import styled from 'styled-components'
import { respondToMin } from 'shared/theme'
import { Link } from 'react-router-dom'

const Wrap = styled.div`
  flex: 2;
  position: relative;
  padding-bottom: 100px;
  background: #1f1f1f !important;

  ${respondToMin.desktopSmall`
    padding-bottom: 0px;
  `}
`

const primeiroNivel = styled.ul`
  display: flex;
  color: initial;
  flex-direction: column;
`

const itemPrimeiroNivel = styled.li`
  margin-top: 12px;
  margin-right: 20px;
  position: relative;
  padding: 0px !important;
  background: #1f1f1f !important;
  color: #fff;
  &:last-child {
    margin: 0;
  }

  ${respondToMin.desktopSmall`
    &:hover {
      color: #575756;
      background: transparent !important;

      .iconeSeta {
        transform: rotate(180deg);
        padding: 0px 10px 0px 0px;
        
        svg path {
          fill: ##575756;
        }
      }
      .submenu {
        display: block;
      }
    }
  `}
`

const itemSegundoNivel = styled.li`
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  white-space: nowrap !important;
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
  color: #fff;

  ${respondToMin.desktopSmall`
    margin: 0px 0px 20px 0px !important;
  `}

  &:last-child {
    margin: 0 !important;
  }

  span {
    font-size: 14px;
  }
`
const subMenuMobile = styled.div`
  display: none;

  @media (max-width: 440px) {
    display: initial;
  }
`
const linkMenu = styled(Link)`
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  margin-bottom: 12px;

  &:hover {
    color: #575756 !important;
  }

  ${respondToMin.desktopSmall`
    padding: 0px;
    width: auto;
    background: transparent !important;
  `}

  ${({ ativo }) =>
    ativo &&
    `
    background: #DDD;
  `}
`

const wrapLink = styled.div`
  a {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    width: 100%;

    ${respondToMin.desktopSmall`
      padding: 0px;
      width: auto;
      background: transparent !important;
    `}

    ${({ ativo }) =>
      ativo &&
      `
      background: #DDD;
    `}
  }
`

const iconeSeta = styled.span`
  padding: 0px 0px 0px 10px;
  svg {
    display: none;

    ${respondToMin.desktopSmall`
      width: 10px;
      height: 10px;
      transform: rotate(90deg);
      display: block;
      path {
        fill: #FFF;
      }
    `}
  }
  img {
    display: block;
    max-width: 10px;
    transform: rotate(90deg);

    ${respondToMin.desktopSmall`
      display: none;
    `}
  }

  ${({ ativo }) =>
    ativo &&
    `
    img {
      transform: rotate(270deg) !important;
    }
  `}
`

const iconeMenu = styled.span`
  padding: 0px 5px 0px 0px;

  ${respondToMin.desktopSmall`
    svg path {
      fill: #FFF !important;
    }
    `}
`

const Submenu = styled.div`
  display: none;
  padding: 10px 0px;
  z-index: 1;

  ${({ ativo }) =>
    ativo &&
    `
    display:block;
  `}

  ${respondToMin.desktopSmall`
    position: absolute;
    top: 15px;
    padding-top: 35px;
    z-index: 1;
  `}

  ul {
    ${respondToMin.desktopSmall`
      flex-direction: column;
      padding: 15px;
      background: #1F1F1F;
      border: 1px solid #D1DBDD;
      box-sizing: border-box;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 0px 0px 4px 4px;
    `}
  }
`

const Sair = styled.div`
  display: block;
  position: absolute;
  bottom: 50px;
  left: 20px;

  a {
    color: #dbf1ff !important;
    font-weight: 600;
    line-height: 25px;
    font-size: 14px !important;
    width: 100%;
    display: flex;
    align-items: center;

    span {
      padding: 0px 0px 0px 10px !important;
    }

    svg {
      transform: rotate(0deg) !important;

      path {
        fill: #575756 !important;
      }
    }
  }

  ${respondToMin.desktopSmall`
    display: none;
  `}
`

const icoCheckDesk = styled.img`
  display: block !important;

  ${respondToMin.desktopSmall`
    display: none !important;
  `}
`
const icoCheckMobile = styled.img`
  display: none !important;

  ${respondToMin.desktopSmall`
    display: block !important;
  `}
`
const subMenu = styled.div`
  background: #ddd;
`

const ButtonLink = styled.button`
  padding: 16px 12px;
  border: none;
  background: none;
  text-align: left;
  color: #191c1f;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;

  strong {
    margin-left: 5px !important;
  }

  &.active {
    padding: 10px 8px;
    display: flex;
    align-items: center;
    margin-top: 14px;
    border: 1px solid;
    border-radius: 10px;
    height: 10px;
    background: #fff;
  }
`
export const Styled = {
  Wrap,
  primeiroNivel,
  itemPrimeiroNivel,
  itemSegundoNivel,
  wrapLink,
  linkMenu,
  iconeSeta,
  iconeMenu,
  Submenu,
  Sair,
  icoCheckDesk,
  icoCheckMobile,
  subMenu,
  subMenuMobile,
  ButtonLink,
}
