import React from 'react'
import { Styled } from './LoginBox.styled'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import { connect } from 'react-redux'
import pushDataLayerGTM from 'util/pushDataLayerGTM'

const FooterLogin = ({ showModal }) => {
  const handleRedirectPortoSeguro = () => {
    let category = 'login'
    let action = 'clique:link'
    let label = 'procure-o-corretor-mais-proximo'
    pushDataLayerGTM(category, action, label)

    window.open(
      'https://www.portoseguro.com.br/fale-conosco/contatos/encontre-um-corretor'
    )
  }

  return (
    <Styled.Footer>
      <Styled.FooterTitle>Não é cliente Porto?</Styled.FooterTitle>
      <Styled.FooterSubTitle>
        Contate seu corretor ou{' '}
        <Styled.LinkSecondary onClick={handleRedirectPortoSeguro}>
          procure o mais próximo.
        </Styled.LinkSecondary>
      </Styled.FooterSubTitle>
    </Styled.Footer>
  )
}

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(FooterLogin)
