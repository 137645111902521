import React, { useState, useEffect } from 'react'
import axios from 'shared/http'
import { Styled } from '../../containers/VitrineCashback/styled'
import VitrineLista from '../../containers/Vitrine/VitrineListaCashBack/VitrineListaCashBack'
import Carousel from 'components/Carousel/Carousel'
import { LoadIcon } from '../../components/Icons/Icons'
import OfferCardCashBack from '../../components/OfferCardCashBack/OfferCardCashBack'
import NewVitrine from './NewVitrineCasback/NewVitrine'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
const VitrineCashBack = () => {
  const [loadingVitrine, setLoadingVitrine] = useState(false)
  const [MostrarVerMais, setMostrarVerMais] = useState(true)
  const [itemsVitrineCarousel, setItemsVitrineCarousel] = useState([])
  const [vitrineOriginal, setVitrineOriginal] = useState([])
  const [vitrine, setVitrine] = useState([{}])

  useEffect(() => {
    itensCarousel()
  }, [vitrine])

  useEffect(() => {
    setLoadingVitrine(true)
    getShoppingShowCase()
  }, [])

  const getShoppingShowCase = () => {
    axios
      .get('shoppingShowCase/v1/GetShopping')
      .then((response) => {
        setVitrine(response)
      })
      .finally(() => setLoadingVitrine(false))
  }

  const itensCarousel = () => {
    let itemsVitrineCarousel = []
    vitrine?.map((item, i) => {
      let novoComponente = {
        component: <OfferCardCashBack key={i} list={item} />,
      }
      itemsVitrineCarousel.push(novoComponente)
    })

    setItemsVitrineCarousel({ itemsVitrineCarousel })
  }

  const verMais = () => {
    let vitrine = []
    vitrineOriginal.map((item, i) => {
      if (i <= quantidadeItens - 1) {
        vitrine.push(item)
      }
    })

    const isShowMore = vitrine.length + 4 >= vitrine.length ? false : true
    setMostrarVerMais(isShowMore)
    setVitrine(vitrine)
  }
  return (
    <>
      <Styled.CarouselWrap>
        <Styled.Load active={loadingVitrine}>
          <LoadIcon />
        </Styled.Load>

        <Container>
          <Row>
            <Col>
                <NewVitrine list={vitrine} />
            </Col>
          </Row>
        </Container>
      </Styled.CarouselWrap>
    </>
  )
}

export default VitrineCashBack
