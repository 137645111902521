const pushDataLayerGTM = (category, action, label) => {
  let param = {
    event: 'event',
    eventCategory: `porto-plus:${category}`, // Geral, Login, Home, Detalhes da oferta, FAQ, Para Mim, Meus Pontos
    eventAction: '',
    eventLabel: '',
  }

  if (category == 'geral' || category == 'mais-porto-seguro') {
    let pathname =
      window.location.pathname == '/' ? 'home' : window.location.pathname
    let nomeSecao = replaceSpecialChars(pathname).toLowerCase()
    let nomeItem = replaceSpecialChars(label).toLowerCase()

    param.eventAction = `${action}` // clique:[[local-item]]
    param.eventLabel = `${nomeSecao}:${nomeItem}`

    sendEventGTM(param)
  } else {
    param.eventAction = `${action}`
    param.eventLabel = `${label}`

    let index = label.indexOf(':')
    if (
      (action == 'clique:resgatar-meus-pontos' && index >= 0) ||
      (action == 'clique:menu-opcoes' && index >= 0) ||
      (action == 'clique:card' && index >= 0)
    ) {
      param.eventLabel = `${replaceSpecialChars(
        label.slice(0, index)
      ).toLowerCase()}:${replaceSpecialChars(label.slice(index)).toLowerCase()}`
    }

    if (category == '/faq/cartao' || category == '/faq/corretor') {
      param.eventCategory = `porto-plus:${replaceSpecialChars(
        category
      ).toLowerCase()}`
      if (index >= 0) {
        param.eventLabel = `${replaceSpecialChars(
          label.slice(0, index)
        ).toLowerCase()}:${replaceSpecialChars(
          label.slice(index)
        ).toLowerCase()}`
      }
    }

    sendEventGTM(param)
  }

  function replaceSpecialChars(str) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove acentos
      .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
      .replace(/\-\-+/g, '-') // Substitui multiplos hífens por um único hífen
      .replace(/(^-+|-+$)/, '') // Remove hífens extras do final ou do inicio da string
  }

  function sendEventGTM(param) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(param)
    // console.log(param)
  }
}

export default pushDataLayerGTM
