import styled from 'styled-components'

const VitrineContent = styled.div`
  background: ${(props) => props?.colorBg};
  opacity: 1;
  width: 100%;
  min-height: 424px;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: -3px !important;
`
const CardVitrine = styled.div`
  min-height: 500px;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  
  @media (max-width: 1440px) {
    min-height: auto;    
  }
  @media (max-width: 720px) {
    min-height: 500px;    
  }

  &:hover {
    border: 1px solid #006fd6;
  }
`
const VitrineTxt = styled.h1`
  width: 100%;
  padding: 0 8px;
  font-size: 26px;
  /* UI Properties */
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  color: ${(props) => (props.txtColor ? props.txtColor : '#000')};
  margin-bottom: 30px;

  @media (min-width: 1500px) {
    padding-top: 50px;
  }
  @media (max-width: 1499px) {
    padding-top: 50px;
  }
`
const Card = styled.div`
  display: flex;

  @media (max-width: 992px) {
    overflow: auto;
  }
`

const CardImgProd = styled.div`
  background: ${(props) => props?.color};
  border-radius: 12px;
  opacity: 1;
  margin: 0 auto;
  display: flex;
`
const CardImg = styled.div`
  min-width: 210px;
  max-width: 211px;
  height: 152px;
  /* UI Properties */
  background: ${(props) => props?.color};
  border-radius: 12px;
  opacity: 1;
  margin: 0 auto;
  display: flex;
  margin-top: 24px;

  @media (min-width: 1440px) {
    min-width: 260px;
  }
`
const ImgContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid #e9ecf4;
  border-radius: 12px;
  justify-content: center;
`
const ImgContentAux = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 12px;
  justify-content: center;
`
const Img = styled.img`
  width: 63.33%;
`
const ImgMKP = styled.img`
  border: 1px solid #cbf1ec;
  border-radius: 10px;

  @media (max-width: 1440px) {
    width: 100%;
  }
`

const TxtInfo = styled.h2`
  padding: 8px 25px;
  text-align: left;
  font-size: 1em !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #41484f;
  opacity: 1;
  `
const TxtDisc = styled.h2`
  padding: 8px 25px;
  font-size: 1em !important;
  text-align: left;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #41484f;
  opacity: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const VitrineTxtMkp = styled.h1`
  font-size: 28px !important;
  /* UI Properties */
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  color: ${(props) => (props.txtColor ? props.txtColor : '#000')};
  margin-bottom: 30px;

  @media (min-width: 1500px) {
    padding-top: 50px;
  }
  @media (max-width: 1499px) {
    padding-top: 50px;
  }
`
const TxtInfoVitrine = styled.h2`
  font-size: 0.6em;
  margin-bottom: 50px;
`
const Info = styled.h2`
  margin-left: 25px;
  margin-top: 20px;
  max-width: 80%;
  text-align: left;
  margin-bottom: 20px;
  color: #41484f;
  font-size: 1em;
  font-family:'PortoRoobert-SemiBold';
  overflow: hidden; // Removendo barra de rolagem
  text-overflow: ellipsis; // Adicionando "..." ao final
  display: -webkit-box;
  -webkit-line-clamp: 2; // Quantidade de linhas
  -webkit-box-orient: vertical;
`
export const Styled = {
  VitrineContent,
  CardVitrine,
  VitrineTxt,
  Card,
  CardImg,
  Img,
  TxtInfo,
  ImgContent,
  TxtDisc,
  VitrineTxtMkp,
  TxtInfoVitrine,
  Info,
  ImgContentAux,
  CardImgProd,
  ImgMKP,
}
