import React, { useState, useEffect } from 'react'
import { Styled } from './Brandcrumb.styled'
import icoHome from 'assets/images/ico/ico-home.png'
import { useHistory } from 'react-router-dom'
import pushDataLayerGTM from 'util/pushDataLayerGTM'

const Brandcrumb = (props) => {
  const [state, setState] = useState({
    listaUrls: [],
    pathname: '',
  })

  let history = useHistory()

  useEffect(() => {
    if (history.location.pathname !== state.pathname) {
      getBrandcrumb()
    }
  })

  function renderSwitch(param) {
    switch (param) {
      case 'sobre o portoplus':
        return 'Sobre o PortoPlus'
      case 'cartao':
        return 'Cartão'
      case 'historico de resgates':
        return 'Histórico de Resgates'
      case 'mais porto':
        return '+ Porto'
      case 'para imoveis':
        return 'Para Imóveis'
      case 'para veiculos':
        return 'Para Veículos'
      case 'transferencia de pontos':
        return 'Transferência de Pontos'
      default:
        return param
    }
  }

  const getBrandcrumb = () => {
    const pathname = history.location.pathname
    const hash = history.location.hash
    const search = history.location.search
    const url = pathname + hash
    const urls = url.replace('/', '').split('/')

    let listaUrls = []

    if (url.indexOf('/detalhe-da-oferta') == 0) {
      if (props?.category?.name) {
        let category = {}
        category.nome = props.category.name
        category.url = `/comprar-com-desconto/categoria-${props.category.id}`
        category.status = 'visited'
        listaUrls.push(category)
      }

      let details = {}
      details.nome = 'Detalhe da Oferta'
      details.url = url + search
      details.status = 'visited'
      listaUrls.push(details)

      let breadcrumb = {}
      breadcrumb.nome = props.title ? props.title : breadcrumb.nome
      breadcrumb.url = url + search
      breadcrumb.status = 'active'
      listaUrls.push(breadcrumb)
    } else {
      urls.map((item, i) => {
        let breadcrumb = {}
        breadcrumb.nome = renderSwitch(item.replace('#', '').replace(/-/g, ' '))
        breadcrumb.url = '/' + item
        breadcrumb.status = 'visited'

        if (urls.length - 1 === i) {
          breadcrumb.nome = props.title ? props.title : breadcrumb.nome
          breadcrumb.status = 'active'
          breadcrumb.url = history.location.pathname
        }

        listaUrls.push(breadcrumb)
      })
    }
    setState({ ...state, listaUrls, pathname })
  }

  const sendEventGTM = (valueEvent) => {
    let category = 'geral'
    let action = 'clique:menu'
    let label = valueEvent
    pushDataLayerGTM(category, action, label)
  }

  return (
    <Styled.BrandcrumbBox>
      <Styled.Wrap>
        <Styled.Brandcrumb
          to="/"
          state="visited"
          onClick={() => sendEventGTM('home')}
        >
          Home
        </Styled.Brandcrumb>
        {state.listaUrls.map((item, i) => (
          <Styled.Brandcrumb
            key={i}
            to={item.url}
            state={item.status}
            onClick={() => sendEventGTM(item.nome)}
          >
            {item.nome}
          </Styled.Brandcrumb>
        ))}
      </Styled.Wrap>
    </Styled.BrandcrumbBox>
  )
}

export default Brandcrumb
