import React from 'react'
import { Styled } from './Conteudo.styled'
import axios from 'shared/http'
import OfferCard from 'components/OfferCard/OfferCard'
import VitrineLista from '../VitrineLista/VitrineLista'
import VitrineCarousel from '../VitrineCarousel/VitrineCarousel'
import { LoadIcon } from 'components/Icons/Icons'
import ParternsCarousel from 'containers/PartnersCarousel/PartnersCarousel'
import VitrineCategoria from '../VitrineCategoria/VitrineCategoria'
class Conteudo extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoggedIn: localStorage.getItem('auth-token'),
      MostrarVerMais: true,
      vitrine: [],
      itemsVitrineCarousel: [],
      vitrineOriginal: [],
      msg: '',
      msgType: '',
      loadingVitrine: false,
    }
    this.verMais = this.verMais.bind(this)
    this.getShowcase = this.getShowcase.bind(this)
    this.itensCarousel = this.itensCarousel.bind(this)
  }

  componentDidMount() {
    this.getShowcase()
  }

  getShowcase() {
    this.setState({ loadingVitrine: true })
    const url = `/showcases/v1/${
      this.state.isLoggedIn ? 'logado' : 'deslogado'
    }`
    axios
    .get(url)
    .then((response) => {
      let vitrineOriginal = []
        response.map((item, i) => {

          if (!this.state.isLoggedIn) {
            item = {
              ...item,
              redirectoCatalog: `${window.location.href}${item.url}`,
            }
            item.url = item.url
            item.target = '_blank'
          } else {
            item.url = item.url.replace('https://', '//')
            item.target = '_blank'
          }
          if (i < 20) {
            vitrineOriginal.push(item)
          }
        })

        this.setState({
          loadingVitrine: false,
          vitrineOriginal,
        })
        this.itensCarousel()
        this.verMais()
      })
      .catch((error) => {
        this.setState({ loadingVitrine: false })
        this.setState({
          msg: 'Desculpe não encontramos produtos para seu participante',
          msgType: 'erro',
        })
        console.log(error)
      })
  }

  verMais() {
    let quantidadeItens = this.state.vitrine.length + 4
    let vitrine = []

    this.state.vitrineOriginal.map((item, i) => {
      if (i <= quantidadeItens - 1) {
        vitrine.push(item)
      }
    })

    const MostrarVerMais =
      quantidadeItens >= this.state.vitrineOriginal.length ? false : true
    this.setState({ MostrarVerMais })
    this.setState({ vitrine })
  }

  itensCarousel() {
    let itemsVitrineCarousel = []

    this.state.vitrineOriginal.map((item, i) => {
      let novoComponente = {
        component: <OfferCard key={i} list={item} />,
      }
      itemsVitrineCarousel.push(novoComponente)
    })

    this.setState({ itemsVitrineCarousel })
  }

  render() {
    return (
      <>
        <Styled.Wrap>
          <Styled.Load active={this.state.loadingVitrine}>
            <LoadIcon />
          </Styled.Load>

          <Styled.Msg active={this.state.msg !== ''} type={this.state.msgType}>
            {this.state.msg}
          </Styled.Msg>

          <Styled.vitrineMobile>
            <VitrineCarousel list={this.state.itemsVitrineCarousel} />
            <VitrineCategoria />
          </Styled.vitrineMobile>

          <Styled.vitrineDesk>
            <VitrineCarousel list={this.state.itemsVitrineCarousel} />
            <VitrineCategoria />
          </Styled.vitrineDesk>
        </Styled.Wrap>
        
        <Styled.vitrineMobileFull>
          <ParternsCarousel></ParternsCarousel>
        </Styled.vitrineMobileFull>
        <Styled.vitrineDesk>
          <ParternsCarousel></ParternsCarousel>
        </Styled.vitrineDesk>
      </>
    )
  }
}

export default Conteudo
