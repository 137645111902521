import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { clarity } from 'react-microsoft-clarity';

const App = (props) => {
  const { REACT_APP_CLARITY_KEY } = process.env;
  if (process.env.NODE_ENV === 'production') {
    clarity.init({REACT_APP_CLARITY_KEY});
  }
  return props.children
}

export default App
