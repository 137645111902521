import React, { useState, useEffect } from 'react'
import { Styled } from './PartnersCarousel.styled'
import axios from 'shared/http'
import { LoadIcon } from 'components/Icons/Icons'
import Carousel from 'components/Carousel/Carousel'
import LinkToShowCase from 'components/LinkToShowCase'
import { ArrowRight } from 'components/Icons/Icons'
import pushDataLayerGTM from 'util/pushDataLayerGTM'
import LoginBox from 'containers/LoginBox/LoginBox'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import { useHistory } from 'react-router-dom'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function PartnersCarousel({ showModal }) {
  const history = useHistory()
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem('auth-token')
  )
  const [itemsVitrineCarousel, setItemsVitrineCrousel] = useState([])
  const [msgPartners, setMsgPartners] = useState('')
  const [msgPartnersType, setMsgPartnersType] = useState('')
  const [loadingVitrine, setLoadingVitrine] = useState(false)

  useEffect(() => {
    getShowcase()
  }, [])

  function getShowcase() {
    setLoadingVitrine(true)
    const status = isLoggedIn ? 'logado' : 'deslogado'
    const url = `/stores/v1/${status}`

    axios
      .get(url)
      .then((response) => {
        let itemsVitrineCarousel = []
        let listaComFiltro = response.filter((item) => {
          if (
            item.id !== 273735 && //Remove parceiro Loja Avon
            item.id !== 273682 && //Remove parceiro Latam DSHOP - Físico
            item.id !== 285370 && //Remove parceiro LATAM Pass
            item.id !== 285371 && //Remove parceiro LATAM AIRLINES BRASIL
            item.id !== 285366 && //Remove parceiro Smiles LTM
            item.id !== 285491 && //Remove parceiro TAP
            item.id !== 285497 //Remove parceiro Tudo Azul
          ) {
            return item
          }
        })

        listaComFiltro.map((item, i) => {
          if (i < 18) {
            let novoComponente = {
              component: (
                <Styled.ItemWrap key={i}>
                  <img
                    src={item.logoUrl}
                    width={5}
                    alt={item.name}
                    id={item.id}
                    onClick={(e) => getVendorUrl(e, item)}
                  />
                </Styled.ItemWrap>
              ),
            }
            itemsVitrineCarousel.push(novoComponente)
          }
        })

        setLoadingVitrine(false)
        setItemsVitrineCrousel(itemsVitrineCarousel)
        setMsgPartners('')
        setMsgPartnersType('sucesso')
      })
      .catch((error) => {
        setLoadingVitrine(false)
        setMsgPartners('Nenhum parceiro cadastrado')
        setMsgPartnersType('erro')
        console.log(error)
      })
  }

  function sendEventGTM(valueEvent) {
    const category = 'home'
    const action = 'clique:link'
    let label = ''
    if (valueEvent == 'undefined') {
      label = 'regate-com-pontos:ver-todos'
    } else {
      label = valueEvent
    }

    pushDataLayerGTM(category, action, label)
  }

  async function getVendorUrl(event, item) {
    event.preventDefault()
    const url = `/catalog/v1/vendor/${item.id}`
    const urlCatalog = `/catalogo/vendor/${item.id}`

    if (isLoggedIn) {
      axios
        .get(url)
        .then((response) => {
          const isOpen = window.open(response.uri, '_blank')
          if (!isOpen) {
            showModal({
              show: true,
              showCloseButton: false,
              content: (
                <Styled.Wrap>
                  <Styled.Form style={{ paddingBottom: '0.025rem' }}>
                    <Styled.FormGroup>
                      {
                        <Styled.Button
                          type="button"
                          value=""
                          disabled={false}
                          onClick={() => {
                            setState({ ...state, loadingLogin: true })
                            window.location = '/home'
                          }}
                        >
                          Ir para página inicial
                          <Styled.Load active={state.loadingLogin}>
                            <LoadIcon />
                          </Styled.Load>
                        </Styled.Button>
                      }
                    </Styled.FormGroup>
                  </Styled.Form>
                  <Styled.Footer></Styled.Footer>
                </Styled.Wrap>
              ),
              title:
                'Por favor, desabilite o boqueio de Pop-up para ser redirecionado',
              width: '754px',
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      history.push('/Login', { id: item.id })
      // showModal({
      //   show: true,
      //   content: <LoginBox redirectoCatalog={urlCatalog} />,
      //   title:
      //     'Você pode fazer o login com seu CPF/CNPJPreencha o CPF/CNPJ, a senha e faça a validação de segurança para realizar o login.',
      //   width: '554px',
      // })
    }
  }

  return (
    <React.Fragment>
      <Styled.Container>
        <Container>
          <Row>
            <Col>
              <Styled.TitleWrap>
                <Styled.Title>
                  São dezenas de parceiros esperando por você.
                </Styled.Title>

                <LinkToShowCase>
                  <Styled.DeskLink
                    showLink={isLoggedIn}
                    onClick={() => sendEventGTM()}
                  >
                    <span>Ver todos</span>
                    <ArrowRight />
                  </Styled.DeskLink>
                </LinkToShowCase>
              </Styled.TitleWrap>
              <Styled.CarouselWrap>
                <Styled.Load active={loadingVitrine}>
                  <LoadIcon />
                </Styled.Load>

                <Styled.MsgPartners activ={msgPartners} type={msgPartnersType}>
                  {msgPartners}
                </Styled.MsgPartners>

                <Carousel
                  displayQtd={6}
                  slidesPerRow={1}
                  rows={3}
                  padding={'0'}
                  minHeight={'116px'}
                  marginBottom={'35px'}
                  bgSize={'90%'}
                  type="component"
                  items={itemsVitrineCarousel}
                />
              </Styled.CarouselWrap>
              <LinkToShowCase>
                <Styled.verMais
                  showLink={isLoggedIn}
                  onClick={() => this.sendEventGTM()}
                >
                  Ver Todos
                </Styled.verMais>
              </LinkToShowCase>
            </Col>
          </Row>
        </Container>
      </Styled.Container>
    </React.Fragment>
  )
}
const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(PartnersCarousel)
