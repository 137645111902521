import React from 'react'
import styled, { css } from 'styled-components'
import theme, { BreakPoints, respondToMin } from 'shared/theme'
import { ArrowRight } from 'components/Icons/Icons'
import { Link } from 'react-router-dom'

const Container = styled.div`
  margin: 0 auto;
  
  @media (max-width: ${BreakPoints.mobile}) {
    ${(props) => {
      if (props.mobileFull) {
        return css`
          padding: 0;
        `
      }
    }}
  }
`

const h1 = styled.h1`
  font-size: 26px;
  line-height: 41px;
  font-weight: 600;
  color: ${(props) => getColor(props)};
  letter-spacing: -0.02em;
  text-align: ${({ align }) => (align ? align : 'left')};

  @media (min-width: ${BreakPoints.mobile}) {
    font-size: 48px;
    line-height: 61px;

    ${(props) => {
      if (props.subTitle) {
        return css`
          font-size: 20px;
          font-weight: normal;
          line-height: 28px;
          color: ${(props) => props.theme.colors.textLight};
        `
      }
    }}
  }

  ${(props) => {
    if (props.subTitle) {
      return css`
        font-size: 20px;
        font-weight: normal;
        line-height: 28px;
        color: ${(props) => props.theme.colors.textLight};
      `
    }
  }}
`

const h2 = styled.h2`
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: ${(props) => getColor(props)};
  letter-spacing: -0.02em;
  text-align: ${({ align }) => (align ? align : 'left')};

  ${(props) => {
    if (props.subTitle) {
      return css`
        font-size: 16px;
        line-height: 28px;
        font-weight: normal;
        color: ${(props) => props.theme.colors.textLight};
      `
    }
  }}
`

const h3 = styled.h3`
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: ${(props) => getColor(props)};
  letter-spacing: -0.02em;
  text-align: ${({ align }) => (align ? align : 'left')};
`

const h4 = styled.h4`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: ${(props) => getColor(props)};
  letter-spacing: -0.02em;
  text-align: ${({ align }) => (align ? align : 'left')};
`

const h5 = styled.h5`
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: ${(props) => getColor(props)};
  letter-spacing: -0.02em;
  text-align: ${({ align }) => (align ? align : 'left')};
`

const p = styled.p`
  font-size: 15px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.paragraph};
  letter-spacing: -0.015em;
  margin: 0;
  text-align: ${({ align }) => (align ? align : 'left')};
`

const small = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.paragraph};
  letter-spacing: -0.015em;
  text-align: ${({ align }) => (align ? align : 'left')};
`

const extraSmall = styled.p`
  margin: 0;
  font-size: 10px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.paragraphLight};
  letter-spacing: -0.015em;
  text-align: ${({ align }) => (align ? align : 'left')};
`

const Spacing = styled.div`
  margin: 20px 0;
`

const SectionTitle = styled(h1)`
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;

  ${respondToMin.mobile`
    font-size: 24px;
    line-height: 33px;
  `};
`

const TitleWithActionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TitleWithActionLabel = styled(Link)`
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme: { colors } }) => colors.primary};
  display: flex;
  align-items: center;
  display: none;

  > span {
    display: inline-block;
    margin-right: 8px;
  }

  ${respondToMin.mobile`
    display: flex;
  `}
`

const TitleWithAction = ({ title, action }) => {
  return (
    <TitleWithActionWrap>
      <SectionTitle>{title}</SectionTitle>
      <TitleWithActionLabel>
        <span>{action.label}</span>
        <ArrowRight />
      </TitleWithActionLabel>
    </TitleWithActionWrap>
  )
}

const HideDesktopSmall = styled.div`
  ${respondToMin.desktopSmall`
    display: none;
  `}
`

const Flex = styled.div`
  display: flex;
`

const Relative = styled.div`
  position: relative;
`

export const UI = {
  Container,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  small,
  extraSmall,
  Spacing,
  SectionTitle,
  HideDesktopSmall,
  Flex,
  Relative,
  TitleWithAction,
}

function getColor(keys) {
  const props = Object.keys(keys)

  if (props.includes('primary')) return theme.colors.primary
  if (props.includes('secondary')) return theme.colors.secondary
  if (props.includes('textLight')) return theme.colors.textLight
  if (props.includes('paragraph')) return theme.colors.paragraph
  if (props.includes('white')) return theme.colors.white
  if (props.includes('footerText')) return theme.colors.footerText

  return theme.colors.text
}
