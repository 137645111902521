import React, { Fragment, useEffect } from 'react'
import {
  Tabs,
  TabItem,
  TabItemWrap,
  TabBadge,
  InfoText,
  ImgIcons,
  TxtBtn,
  BtnContent,
  TabsTxt,
  TabItemSec,
} from './Tab.styled'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import axios from 'shared/http'
import verifyIsLoginActive from 'util/verifyIsLoginActive'
import openExternalLink from 'util/LinkToShowCase'
import pushDataLayerGTM from 'util/pushDataLayerGTM'

import { useCount } from 'util/context/Count'
import { Container, Row, Col } from 'react-bootstrap'
export const TabContext = React.createContext({
  tabItems: [],
})
const TabProvider = ({ children, tabItems }) => {
  let history = useHistory()

  const { setCount, tabList, setTabList, bannerAux } = useCount()
  const [state, setState] = React.useState([...tabItems])
  const getUrlverTodos = () => {
    let isLoginActive = verifyIsLoginActive()

    if (Boolean(isLoginActive)) {
      let url = '/showcases/v1/show-more'

      axios
        .get(url)
        .then((response) => {
          openExternalLink(response.uri)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const handleChangeTab = (index) => {
    if (window.location.pathname != '/') {
      history.push('/')
    }
    if (state.length === 0) {
      setCount(index)
      setTabList([
        ...tabList.map((e, i) => {
          if (i === index && e.redirectTO !== '') {
            if (e.redirectTO === 'catalogo') {
              getUrlverTodos()
            } else {
              history.push(e.redirectTO)
            }
          }
          if (i === index) {
            return { ...e, active: true }
          } else {
            return { ...e, active: false }
          }
        }),
      ])
    } else {
      setCount(index)

      setTabList([
        ...tabList.map((e, i) => {
          if (i === index && e.redirectTO !== '') {
            if (e.redirectTO === 'catalogo') {
              getUrlverTodos()
            } else {
              history.push(e.redirectTO)
            }
          }
          if (i === index) {
            return { ...e, active: true }
          } else {
            return { ...e, active: false }
          }
        }),
      ])
    }
  }
  const handleChangeTabSec = (index) => {
    setState([
      ...state.map((e, i) => {
        if (i === index && e.redirectTO !== '') {
          if (e.redirectTO === 'catalogo') {
            getUrlverTodos()
          } else {
            history.push(e.redirectTO)
          }
        }
        if (i === index) {
          return { ...e, active: true }
        } else {
          return { ...e, active: false }
        }
      }),
    ])
  }

  return (
    <TabContext.Provider
      value={{
        tabs: state.length > 2 ? tabItems : state,
        tabsSec: state,
        handleChangeTab,
        handleChangeTabSec,
        bannerAux,
      }}
    >
      {children}
    </TabContext.Provider>
  )
}

const Tab = ({ colorTheme = 'primary', itemQtd, ...props }) => {
  const sendEventGTM = (valueEvent) => {
    // let page = replaceSpecialChars(window.location.pathname).toLowerCase()
    // let action = 'clique:aba'
    // let category = valueEvent.redirectTO || page
    // let label = replaceSpecialChars(valueEvent.name).toLowerCase()
    // category = replaceSpecialChars(category).toLowerCase()
    // pushDataLayerGTM(category, action, label)
  }

  const replaceSpecialChars = (str) => {
    //   return str
    //     .normalize('NFD')
    //     .replace(/[\u0300-\u036f]/g, '') // Remove acentos
    //     .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
    //     .replace(/\-\-+/g, '-') // Substitui multiplos hífens por um único hífen
    //     .replace(/(^-+|-+$)/, '') // Remove hífens extras do final ou do inicio da string
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <TabContext.Consumer>
            {({
              tabs,
              handleChangeTab,
              handleChangeTabSec,
              tabsSec,
              bannerAux,
            }) => (
              <Fragment>
                {(window.location.pathname === '/' ||
                  window.location.pathname === '/home') &&
                bannerAux != [] ? (
                  <Fragment>
                    {tabs && (
                      <Col>
                        <TabsTxt>
                          <InfoText>
                            <strong>
                              Aqui o seu relacionamento com a Porto multiplica
                              vantagens pra você.
                            </strong>
                          </InfoText>
                        </TabsTxt>
                        <Tabs key={tabs.length}>
                          <TabItemWrap>
                            {tabs?.map((tab, i) => {
                              if (i == 0) {
                                return <></>
                              } else {
                                return (
                                  <Col>
                                    <TabItem
                                      key={i}
                                      disabled={tab.disabled}
                                      onClick={() => {
                                        handleChangeTab(i), sendEventGTM(tab)
                                      }}
                                      active={tab.active}
                                      colorTheme={colorTheme}
                                      name={tab.name}
                                      mobileWidth={itemQtd}
                                      colorDefaut={tab.colorDefaut}
                                      colorActive={tab.colorActive}
                                      colorDisabled={tab.colorDisabled}
                                      colorBorderDefaut={tab.colorBorderDefaut}
                                      colorBorderActive={tab.colorBorderActive}
                                      colorBorderDisabled={
                                        tab.colorBorderDisabled
                                      }
                                    >
                                      <BtnContent>
                                        <ImgIcons>{tab.icon}</ImgIcons>
                                        <TxtBtn>{tab.name}</TxtBtn>
                                        {tab.badge && (
                                          <TabBadge>{tab.badge}</TabBadge>
                                        )}
                                      </BtnContent>
                                    </TabItem>
                                  </Col>
                                )
                              }
                            })}
                          </TabItemWrap>
                        </Tabs>
                      </Col>
                    )}
                  </Fragment>
                ) : (
                  <>
                    <Tabs>
                      <TabItemWrap>
                        {tabsSec?.map((tab, i) => {
                          if (tabsSec.length === 1) {
                            return <></>
                          } else {
                            return (
                              <TabItemSec
                                key={i}
                                className={`${tab.active ? 'active' : ''}`}
                                disabled={tab.disabled}
                                onClick={() => {
                                  handleChangeTabSec(i), sendEventGTM(tab)
                                }}
                                active={tab.active}
                                name={tab.name}
                                mobileWidth={itemQtd}
                                colorBorderActive={tab.active}
                              >
                                {tab.name}
                              </TabItemSec>
                            )
                          }
                        })}
                      </TabItemWrap>
                    </Tabs>
                  </>
                )}
              </Fragment>
            )}
          </TabContext.Consumer>
        </Col>
      </Row>
    </Container>
  )
}

TabProvider.propTypes = {
  tabItems: PropTypes.array.isRequired,
}

export default Tab
export { TabProvider }
