import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { themeReducer } from 'shared/theme'
import backdrop from './backdrop'
import modal from './modal'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    themeReducer,
    modal,
    backdrop,
  })
