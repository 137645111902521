import verifyIsLoginActive from 'util/verifyIsLoginActive'
import axios from 'shared/http'

const openExternalLink = (url) => {

  const handleSso = (url) => {
    // Cria um elemento <iframe> oculto
    const iframe = document.createElement('iframe');
    iframe.src = url; // URL do SSO
    iframe.style.display = 'none'; // Oculta o iframe

    // Adiciona o iframe ao DOM
    document.body.appendChild(iframe);

    // Adiciona um evento de load ao iframe para detectar quando o conteúdo é carregado
    iframe.onload = () => {
      localStorage.setItem('iframeStatus', 'loaded');
      // Remove o iframe após um breve atraso para garantir que o status foi salvo
      setTimeout(() => {
        document.body.removeChild(iframe);
        // Redireciona para a URL desejada
        window.open('https://portoplus.webpremios.digital/');
      }, 100); // Ajuste o atraso conforme necessário
    };

    // Adiciona um evento de erro para lidar com falhas no carregamento
    iframe.onerror = () => {
      localStorage.setItem('iframeStatus', 'error');
      // Remove o iframe após um breve atraso para garantir que o status foi salvo
      setTimeout(() => {
        console.log('error')
      }, 100); // Ajuste o atraso conforme necessário
    };
  };


  const getUrlverTodos = () => {
        let url = '/showcases/v1/show-more'
        axios
            .get(url)
                .then((response) => {
                  handleSso(response.uri)
                })
                .catch((error) => {
                    console.log(error)
                })
    }

    let isLoginActive = verifyIsLoginActive()
    
    if(Boolean(isLoginActive)){
        return url ? handleSso(url) : getUrlverTodos();
    }
}

export default openExternalLink;



