import React, { useState }from 'react'
import { UI } from 'components/UI/UI.styled'
import Brandcrumb from 'containers/Brandcrumb/Brandcrumb'
import StaticBanner from 'containers/StaticBanner/StaticBanner'
import Accordion from 'components/Accordion'
import SeguroViagem from '../../containers/Faq/seguroviagem.json'

import BannerPorto from '../../assets/images/SeguroViagem/banner-porto.png'


function createMarkup(answer) {
  return { __html: answer }
}
const SegViagem = () => {

  const [open, setOpen] = useState('');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <UI.Container>
      <Brandcrumb />
      <StaticBanner />
      
      <h2>Duvidas Frequente</h2>
      <hr />
      {SeguroViagem.map(
              ({ question, answer, showHelped, isOpen }, i) => (
                <Accordion
                  key={i}
                  title={question}
                  description={
                    <section dangerouslySetInnerHTML={createMarkup(answer)} />
                  }
                  showHelped={showHelped}
                  isOpen={isOpen}
                />
              )
            )}
    </UI.Container>
  )
}

export default SegViagem
