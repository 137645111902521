import React from 'react'
import { Styled } from './LoginBox.styled'
import Login from './Login'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import { connect } from 'react-redux'

const LoginBox = ({
  showModal,
  redirectoCatalog,
  target,
  errorValidationMessage,
  getUrl
}) => {
  return (
    <Styled.Wrap>
      <Login
        getUrl={getUrl}
        redirectoCatalog={redirectoCatalog}
        target={target}
        errorValidationMessage={errorValidationMessage}
      />
    </Styled.Wrap>
  )
}

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(LoginBox)
