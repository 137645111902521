import styled from 'styled-components'
import { fonts } from 'shared/theme'
import { respondToMin } from 'shared/theme'
import { respondTo } from 'shared/theme'

const Wrap = styled.div`
  border: 1px solid #dee7ee;
  border-radius: 8px;
  padding: 20px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  ${respondToMin.tablet`
    flex-direction: column;
    margin-bottom: 20px;
    min-height: 30px;
  `}
  ${respondTo.mobile`
    flex-direction: column;
    min-height: 30px; 
  `}
  ${respondTo.mobile`
    flex-direction: column;
    min-height: 30px; 
  `}
`

const Image = styled.div`
  margin-right: 10px;
  flex: 0 0 190px;

  img {
    max-width: 100%;
    margin: 0 auto ;
  }

  ${respondToMin.tablet`
    display: flex;
    margin-right: 0px;
    flex: 0 0 auto;
    min-height: 165px;
    align-items: center;
    img {
      max-width: 160px;
    }
  `}

`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`

const headerDescription = styled.div`
  flex: 2;
`

const Title = styled.h1`
  font-size: 15px;
  font-weight: normal;
  line-height: 142.5%;
  font-family: ${fonts.secondary};
`

const Store = styled.h1`
  font-size: 15px;
  font-weight: normal;
  color: ${(props) => props.theme.colors.textMuted};
  line-height: 142.5%;
  font-family: ${fonts.secondary};
`

const Discount = styled.h2`
  font-family: ${fonts.secondary};
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;

  span {
    text-decoration: line-through;
  }
`

const Points = styled.h1`
  font-family: ${fonts.secondary};
  font-size: 24px;
  line-height: 32px;
  font-weight: normal;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary};
`

const PointsInfo = styled.div`
  ${respondToMin.tablet`
    margin-top: 30px;
  `}
`

const Info = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${(props) => props.theme.colors.darkBlue};
  display: flex;

  span {
    display: inline-block;
    margin-right: 5px;
  }
`

export const Styled = {
  Wrap,
  Image,
  Description,
  headerDescription,
  Title,
  Store,
  Discount,
  Info,
  PointsInfo,
  Points,
}
