import React, {useContext} from 'react'
import { LogoIcon } from 'components/Icons/Icons'
// import {Logo_Topo} from '../../assets/images/logoPorto/logo_topo'
import { Link } from 'react-router-dom'
import pushDataLayerGTM from 'util/pushDataLayerGTM'
import { useCount } from 'util/context/Count'
import { TabContext } from 'components/Tab/Tab'



const Logo = () => {
  const { setCount } = useCount()
  const { handleChangeTab } = useContext(TabContext)
  
  const sendEventGTM = (valueEvent) => {
    let category = 'geral'
    let action = 'clique:header'
    let label = valueEvent
    pushDataLayerGTM(category, action, label)
    setCount(0)
    handleChangeTab(0)
  }

  return (
    <Link to={'/'} onClick={() => sendEventGTM('logo')}>
      <LogoIcon />
    </Link>
  )
}

export default Logo
