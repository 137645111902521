import React, { useState } from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { history } from 'store'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { PrivateRoute } from './PrivateRoute'
import Footer from 'components/Footer/Footer'
import Navbar from 'components/Navbar/Navbar'
import BackDrop from 'components/BackDrop/BackDrop'
import Modal from 'components/Modal/Modal'
import { Helmet } from 'react-helmet-async'
import CountProvider from '../util/context/Count'
import { TabProvider } from 'components/Tab/Tab'

import { AuthProvider } from 'util/context/Auth'
import SegViagem from 'pages/SegViagem/SegViagem'
const Manutencao = React.lazy(() => import('pages/Manutencao'))
const MeuPrimeiroAcesso = React.lazy(() => import('pages/MeuPrimeiroAcesso'))
const Catalogo = React.lazy(() => import('pages/Catalogo/Catalogo'))
const Welcome = React.lazy(() => import('pages/Welcome/Welcome'))
const Vendor = React.lazy(() => import('pages/Marketplace/Vendor'))
const ShowCase = React.lazy(() => import('pages/Marketplace/Showcase'))

const CatalogoMarketplace = React.lazy(() =>
  import('pages/Marketplace/Catalog')
)
const RedirectExternalUrl = React.lazy(() =>
  import('pages/Marketplace/RedirectExternalUrl')
)
const RedirectExternalUrlShopHub = React.lazy(() =>
  import('pages/Marketplace/RedirectShophub')
)

const Sku = React.lazy(() => import('pages/Marketplace/Sku'))
const Produtos = React.lazy(() => import('pages/Produtos/Produtos'))
const meuExtrato = React.lazy(() => import('pages/meuExtrato/meuExtrato'))
const MaisPortoSeguro = React.lazy(() => import('pages/MaisPortoSeguro'))
const DetalheOferta = React.lazy(() => import('pages/DetalheOferta'))
const DetalheViagem = React.lazy(() => import('containers/DetalheOfertaViagem'))
const Logout = React.lazy(() => import('containers/LoginBox/Logout'))
const HistoricoDeResgates = React.lazy(() =>
  import('pages/HistoricoDeResgates/HistoricoDeResgates')
)
const ProgramaAnterior = React.lazy(() =>
  import('pages/ProgramaAnterior/ProgramaAnterior')
)
const TransferenciaDePontos = React.lazy(() =>
  import('pages/TransferenciaDePontos/TransferenciaDePontos')
)

const PortoPlus = React.lazy(() => import('pages/PortoPlus/PortoPlus'))
const Relacionamentos = React.lazy(() =>
  import('pages/Relacionamentos/Relacionamentos')
)
const Faq = React.lazy(() => import('pages/Faq/Faq'))
const Login = React.lazy(() => import('pages/Login/Login'))
const FlowCheck = React.lazy(() => import('pages/Login/Flow2Fa/FlowCheck'))
const Blocked = React.lazy(() => import('pages/Login/InfoError/Blocked'))
const Recover = React.lazy(() => import('pages/Login/InfoError/Recover'))
const Validate = React.lazy(() =>
  import('pages/Login/Flow2Fa/ValidateVerifcation')
)

const Vouchers = React.lazy(() => import('pages/Vouchers'))

const LocalizaHidden = React.lazy(() => import('pages/Desabilita-Localiza'))

const Localiza = React.lazy(() => import('pages/Localiza'))

const ComponentsUI = React.lazy(() => import('./../__mock__/'))

// Ativa a tela de manutenção geral no site
const hasMaintenance = false

function Routes({ router, theme, modal }) {
  const [active, setActive] = useState(0)
  let title = 'PortoPlus'
  let description =
    'PortoPlus | Unificamos nessa plataforma todos os programas de relacionamento da Porto para nossos Clientes, Corretores e Prestadores de Serviço.'
  let keywords = 'PortoPlus'
  let seo = JSON.parse(localStorage.getItem('seo'))

  if (
    seo !== null &&
    seo.length > 0 &&
    window.location.pathname.indexOf('/detalhe-da-oferta') == 0
  ) {
    const idOffer = window.location.search.replace('?id=', '')
    let seoInfo =
      seo == null || seo.length == 0
        ? []
        : seo.filter((item) => item.code == idOffer)
    title = seoInfo.length == 1 ? seoInfo[0].title : 'PortoPlus'
    description =
      seoInfo.length == 1
        ? seoInfo[0].description
        : 'PortoPlus | Unificamos nessa plataforma todos os programas de relacionamento da Porto para nossos Clientes, Corretores e Prestadores de Serviço.'
    keywords = seoInfo.length == 1 ? seoInfo[0].keywords : 'PortoPlus'
  }

  history.listen(async (location, action) => {
    document.querySelector('#root').scrollTop = 0

    let newSeo = JSON.parse(localStorage.getItem('seo'))
    if (
      newSeo !== null &&
      newSeo.length > 0 &&
      location.pathname.indexOf('/detalhe-da-oferta') == 0
    ) {
      const idOffer = location.search.replace('?id=', '')
      let newSeoInfo =
        newSeo == null || newSeo.length == 0
          ? []
          : newSeo.filter((item) => item.code == idOffer)
      title = newSeoInfo.length == 1 ? newSeoInfo[0].title : 'PortoPlus'
      description =
        newSeoInfo.length == 1
          ? newSeoInfo[0].description
          : 'PortoPlus | Unificamos nessa plataforma todos os programas de relacionamento da Porto para nossos Clientes, Corretores e Prestadores de Serviço.'
      keywords = newSeoInfo.length == 1 ? newSeoInfo[0].keywords : 'PortoPlus'
    }
  })

  let physics = []

  return (
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        {hasMaintenance ? (
          <CountProvider>
            <TabProvider tabItems={[]}>
              <React.Suspense fallback={''}>
                <Helmet>
                  <title>{title}</title>
                  <meta name="description" content={description} />
                  <meta name="keywords" content={keywords} />
                </Helmet>
                <Switch>
                  <Route component={Manutencao} exact={true} path="/manutencao" />
                  <Route component={Manutencao} />
                </Switch>
              </React.Suspense>
            </TabProvider>
          </CountProvider>
        ) : (
          <>
            {/* Verificação da url para uso de página de manutenção nos deploys */}
            {window.location.pathname == '/Login' ||
            window.location.pathname == '/Login/FlowCheck' ||
            window.location.pathname == '/Login/Validate' ||
            window.location.pathname == '/Login/blocked' ||
            window.location.pathname == '/Login/recover' ? (
              <>
                <CountProvider>
                  <TabProvider tabItems={physics}>
                    <React.Suspense fallback={<></>}>
                      {/* <Navbar /> */}
                    </React.Suspense>
                    <React.Suspense fallback={''}>
                      <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={description} />
                        <meta name="keywords" content={keywords} />
                      </Helmet>
                      <Switch>
                        <AuthProvider>
                          <Route component={Welcome} exact={true} path="/" />
                          <Route component={Welcome} path="/home" />
                          <Route component={Welcome} path="/comprar-com-desconto" />
                          {/* Novo Login rotas comentadas  */}
                          <Route component={Login} exact={true} path="/Login" />
                          <Route
                            component={FlowCheck}
                            exact={true}
                            path="/Login/FlowCheck"
                          />
                          <Route
                            component={Validate}
                            exact={true}
                            path="/Login/Validate"
                          />
                          <Route
                            component={Recover}
                            exact={true}
                            path="/Login/recover"
                          />
                          <Route
                            component={Blocked}
                            exact={true}
                            path="/Login/blocked"
                          />
                        </AuthProvider>
                      </Switch>
                    </React.Suspense>
                    <React.Suspense fallback={<></>}>
                      {/* <Footer /> */}
                    </React.Suspense>
                  </TabProvider>
                </CountProvider>
              </>
            ) : (
              <>
                <CountProvider>
                  <TabProvider tabItems={[]}>
                    <React.Suspense fallback={<></>}>
                      <Navbar />
                    </React.Suspense>
                    <React.Suspense fallback={''}>
                      <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={description} />
                        <meta name="keywords" content={keywords} />
                      </Helmet>
                      <Switch>
                        {/* <Route component={Welcome} exact={true} path="/homologacao" /> */}
                        {/* <Route component={Manutencao} exact={true} path="/manutencao" /> */}
                        <Route component={Welcome} exact={true} path="/" />
                        <Route component={Welcome} exact={true} path="/home" />
                        <Route component={Welcome} path="/comprar-com-desconto" />
                        <Route component={ComponentsUI} path="/components" />
                        <Route
                          component={PortoPlus}
                          exact={true}
                          path="/sobre-o-portoplus"
                        />
                        <Route component={Faq} exact={true} path="/Faq" />
                        <Route component={Faq} exact={true} path="/Faq/cartao" />
                        <Route component={Faq} exact={true} path="/Faq/corretor" />
                        <Route component={Faq} exact={true} path="/Faq/descontos" />
                        <Route component={Faq} exact={true} path="/Faq/categoria" />
                        <Route component={Faq} exact={true} path="/Faq/resultado" />
                        <Route
                          component={MaisPortoSeguro}
                          exact={true}
                          path="/mais-porto-seguro"
                        />
                        <Route
                          component={MaisPortoSeguro}
                          exact={true}
                          path="/mais-porto-seguro/para-mim"
                        />
                        <Route
                          component={MaisPortoSeguro}
                          exact={true}
                          path="/mais-porto-seguro/para-imoveis"
                        />
                        <Route
                          component={MaisPortoSeguro}
                          exact={true}
                          path="/mais-porto-seguro/para-veiculos"
                        />
                        <Route
                          component={MaisPortoSeguro}
                          exact={true}
                          path="/mais-porto-seguro/para-empresa"
                        />
                        <Route
                          component={DetalheOferta}
                          exact={true}
                          path="/detalhe-da-oferta/:param"
                        />
                        <Route
                          component={DetalheViagem}
                          exact={true}
                          path="/detalhe-viagem"
                        />
                        <Route component={Localiza} exact={true} path="/localiza" />
    
                        <Route
                          component={LocalizaHidden}
                          exact={true}
                          path="/localiza-AA65E23F"
                        />
    
                        <Route component={Logout} exact={true} path="/logout" />
    
                        <Route component={Welcome} path="/home" />
    
                        <Route
                          component={MeuPrimeiroAcesso}
                          exact={true}
                          path="/onboarding"
                        />
    
                        <Route
                          component={Vendor}
                          exact={true}
                          path="/catalogo/vendor/:vendorid"
                        />
    
                        <Route
                          component={ShowCase}
                          exact={true}
                          path="/catalogo/showcase/:identifier"
                        />
    
                        <Route
                          component={CatalogoMarketplace}
                          exact={true}
                          path="/catalogo/webpremios"
                        />
                        <Route
                          component={RedirectExternalUrl}
                          exact={true}
                          path="/catalog/redirect"
                        />
                        <Route
                          component={RedirectExternalUrl}
                          exact={true}
                          path="/catalogo/redireciona"
                        />
                        <Route
                          component={RedirectExternalUrlShopHub}
                          exact={true}
                          path="/shopping"
                        />
    
                        <Route
                          component={Sku}
                          exact={true}
                          path="/catalogo/sku/:skucode"
                        />
                        <Route
                          component={SegViagem}
                          exact={true}
                          path="/seguro-viagem"
                        />
    
                        <PrivateRoute
                          component={Catalogo}
                          exact={true}
                          path="/catalogo"
                        />
    
                        <PrivateRoute
                          component={Vouchers}
                          exact={true}
                          path="/meus-vouchers"
                        />
    
                        <PrivateRoute
                          component={Relacionamentos}
                          exact={true}
                          path="/meus-relacionamentos"
                        />
    
                        <PrivateRoute
                          component={meuExtrato}
                          exact={true}
                          path="/meu-extrato"
                        />
    
                        <PrivateRoute
                          component={HistoricoDeResgates}
                          exact={true}
                          path="/historico-de-resgates"
                        />
                        <PrivateRoute
                          component={ProgramaAnterior}
                          exact={true}
                          path="/programa-anterior"
                        />
                        <PrivateRoute
                          component={TransferenciaDePontos}
                          exact={true}
                          path="/transferencia-de-pontos"
                        />
                        <PrivateRoute
                          component={Produtos}
                          exact={true}
                          path="/produtos-porto-seguro"
                        />
                        <PrivateRoute
                          component={Produtos}
                          exact={true}
                          path="/produtos-porto-seguro/porto-seguro-auto"
                        />
                        <PrivateRoute
                          component={Produtos}
                          exact={true}
                          path="/produtos-porto-seguro/instituto-porto-seguro"
                        />
                        <PrivateRoute
                          component={Produtos}
                          exact={true}
                          path="/produtos-porto-seguro/seguro-residencial"
                        />
                        <PrivateRoute
                          component={Produtos}
                          exact={true}
                          path="/produtos-porto-seguro/centro-automotivo"
                        />
                        <PrivateRoute
                          component={Produtos}
                          exact={true}
                          path="/produtos-porto-seguro/azul-seguro-auto"
                        />
                        <PrivateRoute
                          component={Produtos}
                          exact={true}
                          path="/produtos-porto-seguro/financiamento"
                        />
                        <PrivateRoute
                          component={Produtos}
                          exact={true}
                          path="/produtos-porto-seguro/porto-faz"
                        />
                        <Route
                          component={() => (
                            <div style={{ textAlign: 'center', padding: '20px' }}>
                              Página não encontrada
                            </div>
                          )}
                        />
                      </Switch>
                    </React.Suspense>
                    <React.Suspense fallback={<></>}>
                      <Footer />
                    </React.Suspense>
                  </TabProvider>
                </CountProvider>
              </>
            )}
          </>
        )}

        <Modal
          width={modal.width}
          title={modal.title}
          show={modal.show}
          type={modal.type}
        >
          {modal.content}
        </Modal>
        <BackDrop />
      </ConnectedRouter>
    </ThemeProvider>
  )
}

const mapState = (state) => ({
  router: state.router,
  modal: state.modal,
  theme: state.themeReducer,
})

export default connect(mapState, null)(Routes)
