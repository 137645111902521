import React from 'react'
import { Styled } from './MenuMobile.styled'
import NavItems from 'components/NavItems/NavItems'
import NavItemsPrivate from 'components/NavItems/NavItemsPrivate'
import NavbarProfile from 'components/NavbarProfile/NavbarProfile'
import NavbarProfilePrivate from 'components/NavbarProfile/NavbarProfilePrivate'
import arrowMobile from 'assets/images/ico/close.png'

const isLoggedIn = () => {
  return localStorage.getItem('auth-token')
}

const MenuMobile = ({ open, setMenuOpen, ...props }) => {
  return (
    <Styled.Wrap open={open}>
      <Styled.CloseMenu onClick={setMenuOpen}>
        <img src={arrowMobile} alt="" width={'32px'} />
      </Styled.CloseMenu>
      <hr />
      <Styled.Pad>
        {isLoggedIn() ? (
          <NavbarProfilePrivate
            infoUsuario={props.infoUsuario}
            loadInfo={props.loadInfo}
          />
        ) : (
          <NavbarProfile mobile />
        )}
      </Styled.Pad>

      {isLoggedIn() ? (
        <NavItemsPrivate CloseMenu={setMenuOpen} isPerson={props.isPerson} />
      ) : (
        <>
          <hr />
          <NavItems CloseMenu={setMenuOpen} />
        </>
      )}
    </Styled.Wrap>
  )
}

export default MenuMobile
