import React from 'react'
import { Styled } from './Footer.styled'
import { ArrowRight } from 'components/Icons/Icons'

const FooterItem = (props) => (
  <Styled.FooterItem>
    <div>
      {props.icon}
      <Styled.FooterLink>
        <span>{props.label}</span>{' '}
      </Styled.FooterLink>
    </div>
    <Styled.FooterLinkInfo>{props.info}</Styled.FooterLinkInfo>
  </Styled.FooterItem>
)

export default FooterItem
